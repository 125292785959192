<app-breadcrumb [title]="'Detalle campaña'" [items]="['Campañas', 'Detalle']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="form-group">
                            <label for="">Nombre de campaña</label>
                            <input class="form-control" type="text" value="HONDA 4W - HONDA REMARKETING BBDD 1 14.09.23"
                                disabled>
                        </div>
                        <div class="form-group">
                            <label for="">Audiencia <b>(10456)</b></label>
                            <div class="buttons">
                                <button class="btn btn-primary d-inline">Ver audiencia</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="">Fecha de envío</label>
                                    <input type="datetime-local" value="2023-01-01 12:15:00" class="form-control"
                                        disabled>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="">Fecha de creación</label>
                                    <input type="datetime-local" value="2022-12-30 12:15:00" class="form-control"
                                        disabled>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="">Plantilla</label>
                            <iframe
                                src="https://autos.honda.com.pe/mailing/rmk-extra/remarketing-extraordinario-julio19.html"
                                frameborder="0"></iframe>
                            <small>*Algunas imágenes puede que no se visualicen por ser material dinámico.</small>
                        </div>
                        <div class="form-group">
                            <label for="">Estado:</label>
                            <span class="badge badge-success">Envíado</span>
                        </div>

                        <div class="buttons">
                            <button class="btn btn-primary"><span><i data-feather="arrow-left-circle"></i>
                                    Regresar</span> </button>
                            <button class="btn btn-success"><span>Generar Reporte</span> <i
                                    data-feather="file-text"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>