import { Component, OnInit } from '@angular/core';
import { TemplateAddModalComponent } from '../template-add-modal/template-add-modal.component';
import { TemplateEditModalComponent } from '../template-edit-modal/template-edit-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from '../shared/services/template.service';


@Component({
  selector: 'app-loyalty-campaigns-config-plantillas',
  templateUrl: './loyalty-campaigns-config-plantillas.component.html',
  styleUrls: ['./loyalty-campaigns-config-plantillas.component.scss']
})
export class LoyaltyCampaignsConfigPlantillasComponent implements OnInit {

  templates = [
  ]

  constructor(
    private modalService: NgbModal,
    private templateService: TemplateService
  ) { }

  ngOnInit(): void {
    this.load_templates()
  }

  async load_templates() {
    const res = <any>await this.templateService.get_all_templates()
    if (res.success) {
      this.templates = res.templates
    }
  }

  ver_template(template_id) {
    const dialogRef = this.modalService.open(TemplateEditModalComponent, {
      size: 'xl'
    });

    dialogRef.componentInstance.template_id = template_id

    dialogRef.result.then(
      (data) => {
        console.log(data)
      },
      (reason) => {
        console.log('Modal cerrado con motivo:', reason);
      }
    );
  }

  subir_plantilla() {
    const dialogRef = this.modalService.open(TemplateAddModalComponent, {
      size: 'xl'
    });

    dialogRef.componentInstance.data = {
      nombres: 'ricardo',
      apellidos: 'garcia'
    };

    dialogRef.result.then(
      (data) => {
        if (data.success) {
          this.load_templates()
        }
      },
      (reason) => {
        console.log('Modal cerrado con motivo:', reason);
      }
    );
  }

}
