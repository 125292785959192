<app-breadcrumb [title]="'Plantillas - Meta'" [items]="['Campañas', 'Configuración','Plantillas - Meta']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>En este módulo, se podrán visualizar las plantillas que actualmente están aprobadas en Facebook Meta</small>
                    </div>
                    <div class="media-body text-end">

                        <!-- <a (click)="subir_plantilla()" class="btn btn-primary me-2">Subir plantilla +</a> -->
                    </div>
                </div>
                <div class="card-body">
                    <h3 *ngIf="templates.length <= 0">
                        No se encontraron plantillas subidas.
                    </h3>
                    <table class="table table-concesionarios" *ngIf="templates.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Plantilla</th>
                                
                                <th class="text-center" scope="col">Cantidad de campañas enviadas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of templates">
                                <td>{{t.name}}</td>
                                <td class="text-center">
                                    {{t.sents}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>