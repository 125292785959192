import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-templates-meta',
  templateUrl: './templates-meta.component.html',
  styleUrls: ['./templates-meta.component.scss']
})
export class TemplatesMetaComponent implements OnInit {

  constructor() { }

  audiences = []

  templates = [

  ]

  ngOnInit(): void {
  }

}
