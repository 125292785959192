<div class="notification-box" (click)="openBookMark()">
  <app-feather-icons [icon]="'home'"></app-feather-icons>
</div>
<div class="onhover-show-div bookmark-flip" [class.active]="bookmarkFlip || bookmark">
  <div class="flip-card">
    <div class="flip-card-inner" [class.flipped]="bookmarkFlip">
      <div class="front">
        <ul class="droplet-dropdown bookmark-dropdown">
          <li class="gradient-primary">
            <app-feather-icons [icon]="'home'"></app-feather-icons>
            <h6 class="f-18 mb-0">Tiendas</h6>
          </li>
          <li class="custom-scrollbar">
            <div class="row">
              <div class="col-sm-12" *ngFor="let tienda of tiendas">
                <label class="card select-tienda" for="tienda{{tienda.id}}_menu">
                  <div class="media p-20">
                    <div class="radio radio-primary me-3 ms-2">
                      <input id="tienda{{tienda.id}}_menu" name="tienda_menu[]" type="radio"
                        [(ngModel)]="tienda_selected_menu" (change)="pickTiendaMenu()" value="{{tienda.id}}">
                      <label for="tienda{{tienda.id}}_menu"></label>
                    </div>
                    <div class="media-body">
                      <h6 class="mt-0 mega-title-badge">{{tienda.name}}<span
                          class="badge badge-primary pull-right digits">{{tienda.cantidad_leads}}
                          leads</span></h6>
                      <p>{{tienda.provincia_id}}</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>