import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-preview-template-loyalty',
  templateUrl: './modal-preview-template-loyalty.component.html',
  styleUrls: ['./modal-preview-template-loyalty.component.scss']
})
export class ModalPreviewTemplateLoyaltyComponent implements OnInit {
  @Input() public html_sent

  html

  constructor() {
  }

  ngOnInit() {
    this.html = this.html_sent
    console.log(this.html)
  }

}
