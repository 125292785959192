import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-usuario-cantidades-lead',
  templateUrl: './dashboard-usuario-cantidades-lead.component.html',
  styleUrls: ['./dashboard-usuario-cantidades-lead.component.scss']
})
export class DashboardUsuarioCantidadesLeadComponent implements OnInit {
  @Input() data: any = {
    'atendidos': 0,
    'porcentaje_atendidos': 0
  }
  constructor() { }

  ngOnInit(): void {
  }

}
