import { Input, Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-dashboard-modelos-cotizados-detalle-modelo',
  templateUrl: './dashboard-modelos-cotizados-detalle-modelo.component.html',
  styleUrls: ['./dashboard-modelos-cotizados-detalle-modelo.component.scss']
})
export class DashboardModelosCotizadosDetalleModeloComponent implements OnInit {

  @Input() private jwt
  @Input() private modelo

  modelo_selected = {
    'name': '',
    'imagen': ''
  }

  single = [
  ];

  cantidad_leads = 0
  leads = []


  view: any[] = null

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'top';

  colorScheme = {
    domain: ["#04a1f4", "#343a40", "#e4b007", "#ed1c25", '#28a745', '#7a5fa9']
  };



  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) { }

  async ngOnInit() {
    console.log(this.jwt)
    console.log(this.modelo)
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('modelo', this.modelo)
    data.append('user_type_id', JSON.parse(localStorage.user).user_type_id)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_detalle_dashboard_modelo_by_modelo`, data))
    if (response.success) {
      this.modelo_selected = response.data.modelo
      this.leads = this.cantidad_leads = response.data.leads
      this.cantidad_leads = response.data.leads.length
      this.single = response.data.data
    }
  }

  async loadNewLeads(estado_cliente) {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('modelo', this.modelo)
    data.append('estado_cliente', estado_cliente)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_detalle_dashboard_modelo_by_modelo__selectStatus`, data))
    if (response.success) {
      this.leads = response.data.leads
      this.cantidad_leads = response.data.leads.length
    }
  }


  onSelect(data): void {
    this.loadNewLeads(JSON.parse(JSON.stringify(data)).name)

  }

  closeModal() {
    this.activeModalService.close({
      success: false
    });
    console.log('cerrar?')
  }


}
