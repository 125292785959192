
<div class="pantalla">



    <div class="col-12 whatsapp-bubble me">

        <p [innerHTML]="html" style="white-space: pre-wrap;" >
           
        </p>
        <p class="hora">4:20 PM</p>
    </div>

    <div   class="col-12 bubble me" [innerHTML]="html_b" ></div> 


</div>

