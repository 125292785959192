import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rol-choise',
  templateUrl: './rol-choise.component.html',
  styleUrls: ['./rol-choise.component.scss']
})
export class RolChoiseComponent implements OnInit {

  roles: any[] = [];
  selectedRole: any;
  user_type: string;
  user_type_id: string;

  constructor() { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('user'));
    this.roles = user ? user.usuario_roles : [];
    if (user.user_type_id) {
      window.location.href = "/";
    }

   //  window.location.href = "/";

  }

  selectRole(selectedRoleId: string) {
    // Buscar el rol completo a partir del id seleccionado
    const selectedRole = this.roles.find(role => role.id === selectedRoleId);

    if (selectedRole) {
      let userData = JSON.parse(localStorage.getItem("user")) || {};

      // Verificar si el rol seleccionado es diferente al actual
      if (userData.user_type_id !== selectedRole.user_type_rol) {
        // Actualizar solo si es diferente
        userData.user_type = selectedRole.tipo_usuario;
        userData.user_type_id = selectedRole.user_type_rol;

        // Guardar los cambios en localStorage
        localStorage.setItem("user", JSON.stringify(userData));

        // Actualizar las variables locales
        this.user_type = userData.user_type;
        this.user_type_id = userData.user_type_id;

        // Redirigir solo si hubo un cambio
        window.location.href = "/";
      } else {
        console.log("No se hizo ningún cambio");
      }
    } else {
      console.error('Rol no encontrado');
    }
  }
}
