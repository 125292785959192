<div class="container">
    <div class="form-group">
        <h2>Calendario:</h2>
    </div>
    <div class="row">
        <div>
            <full-calendar [options]="calendarOptions"></full-calendar>
        </div>
    </div>
    <div class="row">
        <div class="col-12 filtros">
            <div class="btn-group btn-option" data-bs-toggle="buttons">
                <div class="container">
                    <h5><u>Filtros:</u></h5>
                    <h6>Estados:</h6>
                    <div class="row">
                        <div *ngFor="let filtro of filtros" class="btn col-lg-4 col-md-6" [ngClass]="filtro.btn">
                            <div class="checkbox checkbox-primary">
                                <input (ngModelChange)="actualizar(filtro)" [id]="filtro.id_checkbox" type="checkbox"
                                    [(ngModel)]="filtro.checked">
                                <label class="label-pulpa" [for]="filtro.id_checkbox">{{ filtro.name }}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <h6>Motivo:</h6>
                    <div class="row">
                        <div *ngFor="let filtro of motivos" class="btn col-lg-4 col-md-6" [ngClass]="filtro.btn">
                            <div class="checkbox checkbox-primary">
                                <input (ngModelChange)="actualizar(filtro)" [id]="filtro.id_checkbox" type="checkbox"
                                    [(ngModel)]="filtro.checked">
                                <label class="label-pulpa" [for]="filtro.id_checkbox">
                                    <i class="description-icon icofont {{filtro.icon}}"></i>
                                    {{ filtro.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>