import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';

import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-loyalty-black-list',
  templateUrl: './loyalty-black-list.component.html',
  styleUrls: ['./loyalty-black-list.component.scss']
})
export class LoyaltyBlackListComponent implements OnInit {

  protected persons = []

  // ARRAY CON FILTROS
  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private BlackListService: BlackListService,
    private http: HttpClient,
  ) { 

    this.filtros = {}

    if (localStorage.getItem("persons_filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("persons_filtros"))

      this.filtros.email = true;
      this.filtros.whatsapp = false;
      this.filtros.sms = false;
      this.filtros.none_canal = false;

    }
  
    this.default_values = {}


  }

  

  detallado_person = {
    correo: 0,
    celular: 0,
    total: 0,
  }
  
  async ngOnInit() {
    this.load_persons()
    this.load_values()
  }

  rerender(): void {


    localStorage.setItem("persons_filtros", JSON.stringify(this.filtros))

    this.buttonFiltrarTexto = `Cargando...`
    this.load_persons();

    this.buttonFiltrarTexto = "Filtrar"

  }


  async load_values() {

    this.buttonFiltrarTexto = "Filtrar"


  }

  selectedPersons = [];

  eliminar_seleccionados(){
    if(this.selectedPersons.length != 0){

      var message = 'Se eliminarán las ' + this.selectedPersons.length + ' personas completamente';

      if(this.selectedPersons.length == 1){
         message = 'Se eliminará completamente';
      }  

      Swal.fire({
        title: '¿Estás seguro?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {

        if (result.isConfirmed) {

          const response = await this.BlackListService.eliminar_persons_seleccionados(this.selectedPersons);
  
          if (response.success) {

            this.selectedPersons = [];
  
            Swal.fire(
              'Eliminados!',
              'Las Personas han sido eliminadas del Black List',
              'success'
            );
  
            this.load_persons();
  
          }

        }


      });

    } else {
      Swal.fire({
        title: "No hay elementos seleccionados",
        text: "Selecciona al menos una persona",
        icon: "warning"
      });
    }
  }

  updateSelection(person) {
    if (person.selected) {
      this.selectedPersons.push(person);
    } else {
      this.selectedPersons = this.selectedPersons.filter(p => p.id !== person.id);
    }
    console.log(this.selectedPersons)

  }

  selectAll(event) {
    const isChecked = event.target.checked;
    this.selectedPersons = isChecked ? [...this.persons] : [];
    this.persons.forEach(person => person.selected = isChecked);
    console.log(this.selectedPersons)

  }


  validarCorreo(correo: string): boolean {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (correo) {
      return cadena.test(correo);
    } else {
      return false;
    }
  }

  validarCelular(celular: string): boolean {
    if (celular) {
      if (this.isNumber(celular)) {
        if (celular.length === 9) {
          return true;
        }
      }
    }
    return false;
  }

  private isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }



  openFormModal() {
    Swal.fire({
      title: 'Agregar Persona',
      html: `
        <input type="text" id="correo" class="swal2-input swal2-custom-input" placeholder="Correo electrónico">
        <input type="text" id="celular" class="swal2-input swal2-custom-input" placeholder="Celular">
        <textarea type="text" id="razon" class="swal2-input swal2-custom-input" placeholder="Razón de bloqueo de persona..."></textarea>
        <div style="text-align: left; margin-top: 20px; display: flex; flex-direction: column; align-items: center;">
          <label style="font-size: 18px; font-weight: bold; margin-bottom: 10px;"><strong>Bloquear en canales:</strong></label>
          <div style="display: flex; flex-direction: column; align-items: flex-start; width: 100%;">
            <label style="margin-bottom: 5px;">
              <input type="checkbox" id="block-whatsapp" style="margin-right: 10px;"> WhatsApp
            </label>
            <label style="margin-bottom: 5px;">
              <input type="checkbox" id="block-email" style="margin-right: 10px;"> Email
            </label>
            <label>
              <input type="checkbox" id="block-sms" style="margin-right: 10px;"> SMS
            </label>
          </div>
        </div>

      `,
      showCancelButton: true,
      customClass: {
        popup: 'swal2-custom-width' // Aplica la clase personalizada al modal
      },
      confirmButtonText: 'Agregar',
      preConfirm: () => {
        const correo = (Swal.getPopup().querySelector('#correo') as HTMLInputElement).value;
        const celular = (Swal.getPopup().querySelector('#celular') as HTMLInputElement).value;
        const razon = (Swal.getPopup().querySelector('#razon') as HTMLInputElement).value;

        const blockWhatsApp = (Swal.getPopup().querySelector('#block-whatsapp') as HTMLInputElement).checked;
        const blockEmail = (Swal.getPopup().querySelector('#block-email') as HTMLInputElement).checked;
        const blockSms = (Swal.getPopup().querySelector('#block-sms') as HTMLInputElement).checked;

        if (!correo && !celular) {
          Swal.showValidationMessage('Por favor ingrese al menos un valor campos');
          return null;
        }
  
        if (correo && !this.validarCorreo(correo)) {
          Swal.showValidationMessage('Por favor ingrese un correo válido');
          return null;
        }
  
        if (celular && !this.validarCelular(celular)) {
          Swal.showValidationMessage('Por favor ingrese un celular válido');
          return null;
        }

        if (!blockWhatsApp && !blockEmail && !blockSms) {
          Swal.showValidationMessage('Selecciona al menos un canal');
          return null;
        }
  
        return { correo, celular, razon, blockWhatsApp, blockEmail, blockSms};
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newPerson = {
          correo_electronico: result.value.correo,
          celular: result.value.celular,
          razon: result.value.razon,

          whatsapp: result.value.blockWhatsApp,
          email: result.value.blockEmail,
          sms: result.value.blockSms,

        };
  
        const res = await this.crear_black_person(newPerson);
  
        if (res.success) {


          if(res.title == "Correcto"){
            this.load_persons();
            Swal.fire(
              res.title,
              res.message,
              'success'
            );
          } else {
            Swal.fire(
              res.title,
              res.message,
              'info'
            );
          }
        } else {
          Swal.fire(
            'Error',
            'No se pudo agregar a la persona a la lista negra.',
            'error'
          );
        }
      }
    });
  }
  
  async crear_black_person(newPerson) {
    const res = await this.BlackListService.new_persons_person_black_list(newPerson);
    return res;
  }
  
  async editar_black_person(newPerson) {
    const res = await this.BlackListService.edit_person_black_list(newPerson);
    return res;
  }
  

  async openDeleteConfirmationModal(id: string) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará completamente',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const response = await this.BlackListService.eliminar_person(id);

        if (response.success) {

          Swal.fire(
            'Eliminado!',
            'La encuesta ha sido eliminada.',
            'success'
          );

          this.load_persons();

        }



      }
    });
    
  }

  async openEditonfirmationModal(id: string) {
    const res = await this.BlackListService.get_person(id);
  
    if (res.success) {
      var person = res.person;
    }
  
    if (person) {
      Swal.fire({
        title: 'Editar Persona',
        html: `
          <input value="${person.id}" type="hidden" id="person_id" >
          <input value="${person.correo_electronico}" type="text" id="correo" class="swal2-input swal2-custom-input" placeholder="Correo electrónico">
          <input value="${person.celular}" type="text" id="celular" class="swal2-input swal2-custom-input" placeholder="Celular">
          <textarea id="razon" class="swal2-input swal2-custom-input" placeholder="Razón de bloqueo de persona...">${person.razon}</textarea>
          <div style="text-align: left; margin-top: 20px; display: flex; flex-direction: column; align-items: center;">
            <label style="font-size: 18px; font-weight: bold; margin-bottom: 10px;"><strong>Bloquear en canales:</strong></label>
            <div style="display: flex; flex-direction: column; align-items: flex-start; width: 100%;">
              <label style="margin-bottom: 5px;">
                <input type="checkbox" id="block-whatsapp" style="margin-right: 10px;" ${person.whatsapp == "1" ? 'checked' : ''}> WhatsApp
              </label>
              <label style="margin-bottom: 5px;">
                <input type="checkbox" id="block-email" style="margin-right: 10px;" ${person.email == "1" ? 'checked' : ''}> Email
              </label>
              <label>
                <input type="checkbox" id="block-sms" style="margin-right: 10px;" ${person.sms == "1" ? 'checked' : ''}> SMS
              </label>
            </div>
          </div>
        `,
        showCancelButton: true,
        customClass: {
          popup: 'swal2-custom-width' // Aplica la clase personalizada al modal
        },
        confirmButtonText: 'Editar',
        preConfirm: () => {
          const person_id = (Swal.getPopup().querySelector('#person_id') as HTMLInputElement).value;
          const correo = (Swal.getPopup().querySelector('#correo') as HTMLInputElement).value;
          const celular = (Swal.getPopup().querySelector('#celular') as HTMLInputElement).value;
          const razon = (Swal.getPopup().querySelector('#razon') as HTMLTextAreaElement).value;
  
          const blockWhatsApp = (Swal.getPopup().querySelector('#block-whatsapp') as HTMLInputElement).checked;
          const blockEmail = (Swal.getPopup().querySelector('#block-email') as HTMLInputElement).checked;
          const blockSms = (Swal.getPopup().querySelector('#block-sms') as HTMLInputElement).checked;
  
          if (!correo && !celular) {
            Swal.showValidationMessage('Por favor ingrese al menos un valor campos');
            return null;
          }
  
          if (correo && !this.validarCorreo(correo)) {
            Swal.showValidationMessage('Por favor ingrese un correo válido');
            return null;
          }
  
          if (celular && !this.validarCelular(celular)) {
            Swal.showValidationMessage('Por favor ingrese un celular válido');
            return null;
          }
  
          if (!blockWhatsApp && !blockEmail && !blockSms) {
            Swal.showValidationMessage('Selecciona al menos un canal');
            return null;
          }
          
          return { person_id, correo, celular, razon, blockWhatsApp, blockEmail, blockSms };
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const newPerson = {
            person_id: result.value.person_id,
            correo_electronico: result.value.correo,
            celular: result.value.celular,
            razon: result.value.razon,
            whatsapp: result.value.blockWhatsApp,
            email: result.value.blockEmail,
            sms: result.value.blockSms,
  
          };
    
          const res = await this.editar_black_person(newPerson);
    
          if (res.success) {

            if(res.title == "Correcto"){
              this.load_persons();
              Swal.fire(
                res.title,
                res.message,
                'success'
              );
            } else {
              Swal.fire(
                res.title,
                res.message,
                'info'
              );
            }
          } else {
            Swal.fire(
              'Error',
              'No se pudo agregar a la persona a la lista negra.',
              'error'
            );
          }
        }
      });
    }
  }
  

  mostrar = "email"; 
  
  mostrar_encuestas(opcion: string) {

      this.mostrar = opcion;

      this.filtros.whatsapp = false;
      this.filtros.email = false;
      this.filtros.sms = false;
      this.filtros.none_canal = false;

      if (opcion === 'whatsapp') {
          this.filtros.whatsapp = true;
      } else if (opcion === 'email') {
          this.filtros.email = true;
      } else if (opcion === 'sms') {
          this.filtros.sms = true;
      } else if (opcion === 'none') {
          this.filtros.none_canal = true;
      }

      this.load_persons();

  }
  

  async load_persons() {

    Swal.showLoading()

    const res = <any>await this.BlackListService.get_person_black_list_filter(this.filtros);

    if (res.success) {
      
      this.persons = res.persons;

      this.detallado_person = {
        correo: res.detallado_leads.correo  ? res.detallado_leads.correo  : 0 ,
        celular: res.detallado_leads.celular ? res.detallado_leads.celular  : 0 ,
        total: res.detallado_leads.total ? res.detallado_leads.total  : 0 ,
      }
    }
    Swal.close()
  }

}
