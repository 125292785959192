<div class="container">
    <div class="form-group">
        <h2>Vincular usuario</h2>
    </div>
    <div class="form-group">
        <label for="usuarios">Seleccione el usuario que desee agregar</label>
        <ng-select class="js-example-basic-multiple" id="usuarios" class="mt-2" [items]="usuarios"
            bindLabel="nombre_to_show" bindValue="id" [(ngModel)]="data.usuarios" [multiple]="true"></ng-select>
    </div>

    <!-- <ng-select [items]="defaultBindingsList" class="js-example-basic-multiple" placeholder="Select"
                [(ngModel)]="multipleSelectedCity" [multiple]="true"></ng-select> -->


    <div class="form-group buttons">
        <button class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>

</div>