<app-breadcrumb [title]="'Plantillas'" [items]="['Campañas', 'Plantillas']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 item" [routerLink]="['/loyalty-comunication/campaigns/config/templates']">
                            <div class="card bg-primary">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5> Plantillas para Email</h5>
                                            <p>Aquí encontrarás las plantillas para campañas de Email</p>

                                        </div><i data-feather="book-open"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 item" [routerLink]="['/loyalty-comunication/campaigns/config/templates-whatsapp']">
                            <div class="card bg-success">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Plantillas para Whatsapp</h5>
                                            <p>Aquí encontrarás las plantillas para campañas de Whatsapp</p>

                                        </div><i data-feather="file-text"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
    </div>
</div>