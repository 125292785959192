<app-breadcrumb [title]="'Reporte'" [items]="['Inicio de sesión']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <div class="form-group m-t-20">
                        <label for="fecha">Ingrese la fecha a analizar</label>
                        <input id="fecha" type="date" class="form-control" [(ngModel)]="filtros.fecha">
                    </div>
                    <div class="form-group buttons-opciones">
                        <button (click)="buscar()" class="btn btn-primary">¡Buscar!</button>
                    </div>
                </div>
                <div class="card-body" *ngIf="usuarios.length > 0">
                    <h5 class="f-w-100">Inicios de sesión:</h5>
                    <div class="list-group">
                        <div class="list-group-item list-group-item-action flex-column align-items-start"
                            *ngFor="let usuario of usuarios">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">{{usuario.nombres}}</h5><small>{{usuario.fecha}}</small>
                            </div>
                            <p class="mb-1">{{usuario.rol}}</p>
                        </div>
                        <!-- 
                        <a class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Mis leads (filtro)</h5><small>08:14</small>
                            </div>
                            <p class="mb-1">El usuario realizó el filtro con estos criterios:</p>
                            <small>
                                <code><br>fecha_inicio : 2022-12-01<br>fecha_fin : 2022-12-15
                                </code>
                            </small>
                        </a>
                        <a class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Mis leads (detalle)</h5><small>08:20</small>
                            </div>
                            <p class="mb-1">El usuario ingresó al lead 8934</p>
                            <small>Cliente: Juan Perez</small>
                        </a>
                        <a class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Mis leads (detalle)</h5><small>08:26</small>
                            </div>
                            <p class="mb-1">El usuario llamó al cliente</p>
                            <small>Cliente: Juan Perez</small>
                        </a>
                        <a class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Mis leads (detalle)</h5><small>08:30</small>
                            </div>
                            <p class="mb-1">El usuario mandó un correo electrónico</p>
                            <small>Cliente: Juan Perez</small>
                        </a>
                        <a class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Mis leads (detalle)</h5><small>08:33</small>
                            </div>
                            <p class="mb-1">El usuario actualizó el lead</p>
                            <small>
                                <code>estado_cliente : Tibio</code>
                            </small>
                        </a>
                        <a class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Mis leads</h5><small>08:35</small>
                            </div>
                            <p class="mb-1">El usuario ingresó a la sección "Mis leads"</p><small></small>
                        </a> -->

                    </div>
                </div>
                <div class="card-body" *ngIf="usuarios.length == 0">
                    <h5 class="f-w-100">No se encontraron inicios de sesión en la fecha : {{filtros.fecha}}</h5>
                </div>
                <!-- <div class="card-body" *ngIf="acciones?.length == 0">
                    <h5 class="f-w-100">No se encontraron acciones para la fecha : {{date}}</h5>
                </div> -->

            </div>
        </div>
    </div>
</div>