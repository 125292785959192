<app-breadcrumb [title]="''" [items]="['Registros']" [active_item]="nombre_lead">
</app-breadcrumb>
<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h5>Registros</h5>
            </div>
            <div class="container">
                <div class="row detalle-leads">
                    <div class="col-12">
                        <div class="card mb-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h5><u>Filtros</u></h5>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Fecha de
                                                inicio</label>
                                            <div class="col-sm-9">
                                                <input class="form-control digits" [(ngModel)]="filtros.fecha_inicio"
                                                    id="example-datetime-local-input" type="date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Fecha de fin</label>
                                            <div class="col-sm-9">
                                                <input class="form-control digits" [(ngModel)]="filtros.fecha_fin"
                                                    id="example-datetime-local-input" type="date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <button id="make_filter" type="button" class="btn btn-primary"
                                            (click)="rerender()" aria-controls="collapseExample"
                                            [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                            <span>{{buttonFiltrarTexto}}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card">
                            <div class="b-r-4 card-body">
                                <div class="custom-datatable table-responsive">
                                    <table
                                        class="table-concesionarios row-border hover table table-responsive table-leads">
                                        <thead>
                                            <tr>
                                                <th *ngFor="let cabecera of cabeceras" scope="col">{{cabecera.nombre}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let registro of registros">
                                                <th *ngFor="let cabecera of cabeceras">
                                                    {{registro[cabecera.campo] ? registro[cabecera.campo] : '-'}}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>