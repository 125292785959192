import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-type-picker',
  templateUrl: './campaign-type-picker.component.html',
  styleUrls: ['./campaign-type-picker.component.scss']
})
export class CampaignTypePickerComponent implements OnInit {


  protected audiences = []

  constructor() { }

  ngOnInit(): void {
  }

}
