import { Component, OnInit, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';


const Swal = require('sweetalert2')


@Component({
  selector: 'app-modal-add-concesionario',
  templateUrl: './modal-add-concesionario.component.html',
  styleUrls: ['./modal-add-concesionario.component.scss']
})
export class ModalAddConcesionarioComponent implements OnInit {

  private jwt
  data = {
    'name': '',
    'departamento': '',
    'provincia': '',
    'distrito': '',
    'region': '',
    'big_bike': false
  }

  departamentos: []
  provincias: []
  distritos: []
  regiones = [
    {
      id: 'COSTA',
      name: 'COSTA'
    },
    {
      id: 'SELVA',
      name: 'SELVA'
    },
  ]

  constructor(
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt

  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/get_info_to_add_concesionario`, data))

    if (response.success) {
      this.departamentos = response.departamentos
      console.log(this.departamentos)
    }

  }

  @HostListener('document:keyup.escape') onClose() {
    this.activeModalService.close();
  }

  onCancel() {
    this.activeModalService.close();
  }

  onSubmit() {
    if (this.validar(this.data)) {
      this.add_concesionario(this.data)
    }
  }

  async add_concesionario(conce) {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('conce', JSON.stringify(conce))
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/add_concesionario`, data))
    if (response.success) {
      Swal.fire(response.message, '', 'success')
      this.activeModalService.close(response)
    } else {
      Swal.fire(response.message, '', 'error')
    }
  }


  validar(data) {

    if (!data.name) {
      Swal.fire('¡Ingrese nombre!', '', 'error')
      return false
    }

    if (!data.departamento) {
      Swal.fire('¡Seleccione departamento!', '', 'error')
      return false
    }

    if (!data.provincia) {
      Swal.fire('¡Seleccione provincia!', '', 'error')
      return false
    }

    if (!data.distrito) {
      Swal.fire('¡Seleccione distrito!', '', 'error')
      return false
    }

    if (!data.region) {
      Swal.fire('¡Seleccione región!', '', 'error')
      return false
    }

    return true
  }




  async buscar_provincia(el) {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('accion', 'get_provincias')
    data.append('id', this.data.departamento)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/get_info_to_add_concesionario`, data))
    if (response.success) {
      this.provincias = response.provincias
    }
  }

  async buscar_distritos(el) {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('accion', 'get_distritos')
    data.append('id', this.data.provincia)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/get_info_to_add_concesionario`, data))
    if (response.success) {
      this.distritos = response.distritos
    }
  }

}
