import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-users-descanso',
  templateUrl: './users-descanso.component.html',
  styleUrls: ['./users-descanso.component.scss']
})
export class UsersDescansoComponent implements OnInit {
  private routeSub: Subscription;

  usuario : any
  new_descanso
  tipo_descanso_disponible


  descansos

  constructor(private route: ActivatedRoute, private http: HttpClient) {


    this.new_descanso = {
      tipo_descanso: 1,
      fecha_inicio: '',
      fecha_fin: '',
      comentarios: ''
    }

    this.descansos = []

    this.routeSub = this.route.params.subscribe(parametros => {
      this.new_descanso.user_id = parametros.usuario_id
    })




    // this.tipo_descanso_disponible = [
    //   {
    //     'id': 1,
    //     'name': 'Médico'
    //   },
    //   {
    //     'id': 2,
    //     'name': 'Vacaciones'
    //   },
    //   {
    //     'id': 3,
    //     'name': 'Otro'
    //   }
    // ]
  }

  ngOnInit(): void {
    this.cargar_datos()
  }

  async cargar_datos() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    data.append('user_id', this.new_descanso.user_id)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DescansoAPI/init`, data))
    if (response.success) {
      this.new_descanso.fecha_inicio = response.now
      this.new_descanso.fecha_fin = response.now
      this.new_descanso.comentarios = ''
      this.tipo_descanso_disponible = response.tipo_descansos
      this.new_descanso.tipo_descanso = 0
      this.descansos = response.descansos
      this.usuario = response.user
    }
  }

  async agregar() {
    if (this.validate_new_descanso()) {

      const data = new FormData()
      data.append('descanso', JSON.stringify(this.new_descanso))
      data.append('jwt', JSON.parse(localStorage.user).jwt)
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DescansoAPI/insert_descanso_medico`, data))
      if (response.success) {
        this.cargar_datos()
        Swal.fire(response.message, '', 'success')
      } else {
        Swal.fire(response.message, '', 'error')
      }
    }
  }

  validate_new_descanso(): boolean {

    if (this.new_descanso.tipo_descanso <= 0) {
      Swal.fire('¡Seleccione tipo de descanso!', '', 'error')
      return false
    }

    if (!this.new_descanso.fecha_inicio) {
      Swal.fire('Ingrese fecha de inicio', '', 'error')
      return false
    }
    if (!this.new_descanso.fecha_fin) {
      Swal.fire('Ingrese fecha de fin', '', 'error')
      return false
    }

    if (!moment(this.new_descanso.fecha_inicio)) {
      Swal.fire('Ingrese fecha de inicio correcta', '', 'error')
      return false
    }
    if (!moment(this.new_descanso.fecha_fin)) {
      Swal.fire('Ingrese fecha de fin correcta', '', 'error')
      return false
    }

    if (moment(this.new_descanso.fecha_fin).diff(moment(this.new_descanso.fecha_inicio)) < 0) {
      Swal.fire('Fecha de inicio no puede ser mayor que fecha final', '', 'error')
      return false
    }

    return true
  }
}
