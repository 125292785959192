<div class="container">
    <div class="row">
        <h2>Modelo : {{modelo_selected.name}} <img src="{{modelo_selected.imagen}}"
                alt="Imagen de {{modelo_selected.name}}"></h2>

        <div *ngIf="cantidad_leads > 0">
            <h5>Últimos {{cantidad_leads}} leads</h5>
            <div class="table-responsive">
                <table class="table table-concesionarios">
                    <thead>
                        <tr>
                            <th>Nombres</th>
                            <th>Fecha de cotización</th>
                            <th>Estado</th>
                            <th>Ver detalle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lead of leads">
                            <td>{{lead.nombres}}</td>
                            <td>{{lead.created_time}}</td>
                            <td>
                                <span class="badge {{lead.bg_class}}">{{lead.estado_cliente}}</span>
                            </td>
                            <td>
                                <a (click)="closeModal()"
                                    [routerLink]="['/leads/tienda', lead.concesionario_id,lead.id]"><i
                                        class="fa fa-arrow-circle-right lead-option"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="cantidad_leads == 0">
            <h3>No se encontraron leads</h3>
        </div>

        <ngx-charts-pie-chart [view]="view" [scheme]="colorScheme" [results]="single" [gradient]="gradient"
            [legend]="showLegend" [legendTitle]="'Estados'" [legendPosition]="legendPosition" [labels]="showLabels"
            [doughnut]="isDoughnut" (select)="onSelect($event)">
        </ngx-charts-pie-chart>



    </div>
</div>