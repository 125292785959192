<div 
  class="role-selector" 
  (mouseenter)="openNotification = true" 
  (mouseleave)="openNotification = false"
  (click)="toggleNotificationMobile()">
    <app-feather-icons [icon]="'users'"></app-feather-icons> Rol: {{user_type}} 
</div>

<ul 
  class="notification-dropdown onhover-show-div" 
  [class.active]="openNotification">
  
  <li>
    <app-feather-icons [icon]="'users'"></app-feather-icons>
    <h6 class="f-18 mb-0">Roles Disponibles </h6>
  </li>

  <li class="selector" *ngFor="let rol of roles" (click)="selectRole(rol)">
    <p style="text-align: left;">
      <i class="fa fa-circle-o me-3" [ngClass]="{'font-success': rol.user_type_rol == user_type_id}"></i>
      {{ rol.tipo_usuario }}
    </p>
  </li>
</ul>
