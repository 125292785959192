<div class="notification-box" (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span class="badge rounded-pill badge-secondary">{{cantNotifNews}}</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
  <li>
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">Notifications</h6>
  </li>
  <li *ngFor="let notification of notifications">
    <p>
      <i class="fa fa-circle-o me-3 {{notification.circle}}"></i>{{notification.name}} <span class="pull-right"> {{notification.date}}</span>
    </p>
  </li>
  <li>
    <a class="btn btn-primary" routerLink="notificaciones">Ver notificaciones</a>
  </li>
</ul>