<div class="container">
    <div class="form-group">
        <h2>Ver plantilla</h2>
    </div>
    <div class="row" *ngIf="is_loading">
        <h4>Cargando template...</h4>
    </div>
    <div class="row" *ngIf="!is_loading">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="template">Nombre de la plantilla</label>
                <input type="text" class="form-control" id="template" [(ngModel)]="template.name" disabled>
            </div>
        </div>

        <div class="col-12 col-md-6">
            <div class="form-group">
                <label class="col-form-label m-r-10">¿Es dinámica?</label>
                <div class="media-body text-end icon-state">
                    <label class="switch">
                        <input disabled [(ngModel)]="template.es_dinamica" type="checkbox"><span
                            class="switch-state"></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="m-t-10 col-12" *ngIf="mergeVariables.length > 0">
            <h6>Se encontró {{mergeVariables.length}} campo(s) dinámico(s) en este template.</h6>
            <ul>
                <li *ngFor="let merge of mergeVariables">{{merge}}</li>
            </ul>
        </div>



        <div class="col-12">
            <iframe [srcdoc]="template.html" width="100%" height="600px"></iframe>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onCancel()">Cerrar</button>
    </div>
</div>