import { Input,Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-preview-template-loyalty-sms',
  templateUrl: './modal-preview-template-loyalty-sms.component.html',
  styleUrls: ['./modal-preview-template-loyalty-sms.component.scss']
})
export class ModalPreviewTemplateLoyaltySMSComponent implements OnInit {

  @Input() public html_sent

  html

  constructor() {
  }

  ngOnInit() {
    this.html = this.html_sent
  }

}
