<div class="container">
    <div class="form-group">
        <h2>Plantilla</h2>
    </div>
    <div class="row" *ngIf="is_loading">
        <h4>Cargando template...</h4>
    </div>
    <div class="row" *ngIf="!is_loading">
        <div class="col-12 col-md-12">
            <div class="form-group">
                <label for="template">Nombre de la plantilla</label>
                <input type="text" class="form-control" id="template" [(ngModel)]="template.name" disabled>
            </div>
        </div>

        <div class="m-t-10 col-12" *ngIf="mergeVariables.length > 0">
            <h6>Se encontró {{mergeVariables.length}} campo(s) dinámico(s) en este template.</h6>
            <ul>
                <li class="merge" *ngFor="let merge of mergeVariables">{{merge}}</li>
            </ul>
        </div>

        <div class="pantalla">
            <div class="col-12 whatsapp-bubble">
                 <p [innerHTML]="htmlContentValor" style="white-space: pre-wrap;" ></p>
                <p class="hora">4:20 PM</p>
            </div>
        </div>

    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" id="cerrar" class="btn btn-primary" (click)="onCancel()">Cerrar</button>
    </div>
</div>