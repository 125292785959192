<app-breadcrumb [title]="'Configuración de campañas'" [items]="['Campañas', 'Configuración']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 item" [routerLink]="'templates'">
                            <div class="card bg-primary">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Plantillas</h5>
                                            <p>En este módulo podrá gestionar las planillas.</p>
                                        </div><i data-feather="book-open"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 item" [routerLink]="'campaign-type'">
                            <div class="card bg-secondary">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Tipos de campañas</h5>
                                            <p>En este módulo podrá gestionar los tipos de campañas.</p>
                                        </div><i data-feather="users"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 item" [routerLink]="'reportes'">
                            <div class="card bg-success">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Reportes</h5>
                                            <p>En este módulo podrá gestionar los reportes.</p>
                                        </div><i data-feather="file-text"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>