import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportes-antiguos',
  templateUrl: './reportes-antiguos.component.html',
  styleUrls: ['./reportes-antiguos.component.scss']
})
export class ReportesAntiguosComponent implements OnInit {

  protected reportes_antiguos = [
    {
      id: 1,
      name: '2023 - REPORTES JULIO',
      reportes: [
        {
          name: 'reporte-remarketing-20072023.csv',
          link: 'https://drive.google.com/file/d/1UHESY4lQMrFbyKRKsoNR0RUf-Ut2OyCd/view?usp=drive_link'
        },
        {
          name: 'reporte-hrv-recompra.csv',
          link: 'https://drive.google.com/file/d/1wHjoxz2jWlWiB0hQ9she2fUb8sRUs6vQ/view?usp=drive_link',
        },
        {
          name: 'remarketing-modelos.csv',
          link: 'https://drive.google.com/file/d/1HdwIN9NuM6lbqaOj79HsLh1jdJn8Sx6Y/view?usp=drive_link'
        },
        {
          name: 'remarketing-extraordinario-reporte-2023-07-11.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/159EJyR2B2GHnK0CAZjRzQexOMVT3m6Tg/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true',

        },
        {
          name: 'recompra.csv',
          link: 'https://drive.google.com/file/d/1NXoCWaOH3S5ULlGHcvB7kYjkJZHWvJGu/view?usp=drive_link'
        }
      ]
    },
    {
      id: 2,
      name: '2023 - REPORTES AGOSTO',
      reportes: [
        {
          name: 'remarketing-29-08-23.csv',
          link: 'https://drive.google.com/file/d/1D5fIJ94savViEOk85vweQKADB1TsWKyo/view?usp=drive_link'
        },
        {
          name: 'remarketing-24-08-23.csv',
          link: 'https://drive.google.com/file/d/1m9PfwGSFDIgYVRIKTujNz6aLGo4TD48Q/view?usp=drive_link'
        },
        {
          name: 'remarketing-24-08-23_fe-de-erratas-HRV-29-08-23.csv',
          link: 'https://drive.google.com/file/d/1uS33lGBH7TcJRezQw7vJm2E1krClAyy0/view?usp=drive_link'
        },
        {
          name: 'remarketing-18-08-23.csv',
          link: 'https://drive.google.com/file/d/1x7lNoFLJZBc_Cx9a1qAwGdp0jkKZBaaP/view?usp=drive_link'
        },
        {
          name: 'referidos-29-08-23.csv',
          link: 'https://drive.google.com/file/d/19kAQC4OJOyawnw4SJAGIaopCZ1rdDzsC/view?usp=drive_link'
        },
        {
          name: 'referidos-16-08-23.csv',
          link: 'https://drive.google.com/file/d/1lMnpCb0GqiEkjCsCdXhLMbm0qPFN4nzs/view?usp=drive_link'
        },
        {
          name: 'recompra-hrv-29-08-23.csv',
          link: 'https://drive.google.com/file/d/17o23E7z5NkRjpQTDU6zTHhyhQYxU6cRF/view?usp=drive_link'
        }, {
          name: 'recompra-29-08-23.csv',
          link: 'https://drive.google.com/file/d/16sT5GmxixGTbnNafAtf9_bCh5vpaS_MG/view?usp=drive_link'
        }, {
          name: 'recompra-24-08-23.csv',
          link: 'https://drive.google.com/file/d/1H303URLqJCVc5L0eRezMRisZzfgqWkIf/view?usp=drive_link'
        }, {
          name: 'recompra-24-08-23_fe-de-erratas-HRV-29-08-23.csv',
          link: 'https://drive.google.com/file/d/1mCWI_h-UO7MMncjQF5eKQSMnC6pKx6hS/view?usp=drive_link'
        }, {
          name: 'recompra-17-08-23.csv',
          link: 'https://drive.google.com/file/d/1USbA9MGrJsjoN-Ax9M360BZoKuefizTw/view?usp=drive_link'
        }, {
          name: 'recompra-09-08-23.csv',
          link: 'https://drive.google.com/file/d/1wNKbFMjzKxR4YOhhU7JI4ncMUJNO5hlW/view?usp=drive_link'
        },

      ]
    },
    {
      id: 3,
      name: '2023 - REPORTES SEPTIEMBRE',
      reportes: [
        {
          name: 'reporte-bienvenida_08-09-2023.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/1N-eXuqjiGNvpiwsh6jUSM8q_Ai8M5sdF/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true'
        },
        {
          name: 'reporte-bienvenida_05-09-2023.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/1_tkd4OFAmrarUylqXUGFMwUOu13gUXi6/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true'
        },
        {
          name: 'REPORTE ESPECIAL - REFERIDOS.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/16PsaPcdF83VVwo5ubjf49RLDhWzxr_gw/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true'
        },
        {
          name: 'HONDA 4W - HONDA REMARKETING  14.09.23.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/1nsyEhyTQRQJhoH-Im3_3g0cy6cEar7oK/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true'
        },
        {
          name: 'HONDA 4W - HONDA REFERIDOS 14.09.23.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/1hiOawiXAT-NCt1MQrWyRkEsvgdqMtCUb/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true'
        },
        {
          name: 'HONDA 4W - HONDA RECOMPRA 14.09.23.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/1ys_kb7YmiTff05LWjrLHQaSy0lQm4Xbr/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true'
        },
        {
          name: 'HONDA 4W - HONDA BIENVENIDA 12.09.23.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/1t8BjsNowxuMt-cIABRHiX-s3U-txKCeb/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true'
        }, {
          name: 'remarketing-semana3-09-2023.csv',
          link: 'https://drive.google.com/file/d/1VBI4yFZukKiQWnLttUe1GlbR_ZFLHw71/view?usp=drive_link'
        }, {
          name: 'referidos-semana3-09-2023.csv',
          link: 'https://drive.google.com/file/d/1apvozS3cfMGiAsPPDblaZyYeQImo9fOt/view?usp=drive_link'
        }, {
          name: 'recompra-semana3-09-2023.xlsx',
          link: 'https://docs.google.com/spreadsheets/d/1ILy7MFaaBo1-UQCA6exh0AXVNpRnq64X/edit?usp=drive_link&ouid=115725907053146957129&rtpof=true&sd=true'
        }, {
          name: 'recompra-semana3-09-2023.csv',
          link: 'https://drive.google.com/file/d/11-K5dJpYH7xtlAiMgLmI0bevOZ5oMUDp/view?usp=drive_link'
        }, {
          name: 'bienvenida-semana4-09-2023.csv',
          link: 'https://drive.google.com/file/d/1KPuCxL3AWtX4Y3R8YxzQRA-ClC3cLrMn/view?usp=drive_link'
        },
        {
          name: 'bienvenida-semana3-09-2023.csv',
          link: 'https://drive.google.com/file/d/1ACdWQb1qyL7ti4VH-OSz-wvTxarC9lDc/view?usp=drive_link'
        },
        {
          name: 'semana4-septiembre-remarketing.csv',
          link: 'https://drive.google.com/file/d/1QWNqtA-q_KCk5l99_jusv_eat-d1ZPhM/view?usp=drive_link'
        },
        {
          name: 'semana4-septiembre-referidos.csv',
          link: 'https://drive.google.com/file/d/1rX8qHCuhyNPqFlKzqOaAZRUS_cKI-QkN/view?usp=drive_link'
        },
        {
          name: 'semana4-septiembre-recompra (1).csv',
          link: 'https://drive.google.com/file/d/1YAsA-zOrVmo8bSBN9vY5KfPB1VOOIGlE/view?usp=drive_link'
        }, {
          name: 'semana4-septiembre-bienvenida2.csv',
          link: 'https://drive.google.com/file/d/1tcydaCOYF7qhT1IzNB-Fb-WYe6NdKTFk/view?usp=drive_link'
        }, {
          name: 'semana4-septiembre-bienvenida1.csv',
          link: 'https://drive.google.com/file/d/1ClCmrD0wYtnCfIfdpmrtHqArhjCErZLN/view?usp=drive_link'
        }
      ]
    },
    {
      id: 4,
      name: '2023 - REPORTES OCTUBRE',
      reportes: [
        {
          name: 'recompra-04-10-2023.csv',
          link: 'https://drive.google.com/file/d/12WvYUBXaAiwgiFQNVkf-V-qF4VNOh8lk/view?usp=drive_link',
        }, {
          name: 'bienvenida-03-10-2023.csv',
          link: 'https://drive.google.com/file/d/12IJZ-24e58zJ8opVeDM-u3bvMXwRTT1t/view?usp=drive_link',
        }, {
          name: 'remarketing-14-10-2023.csv',
          link: 'https://drive.google.com/file/d/1Mk-fm-DGFwY0ysys0079K2fYeBnomvgs/view?usp=drive_link',
        }, {
          name: 'bienvenida-10-10-2023.csv',
          link: 'https://drive.google.com/file/d/133EehTceoKZsEShO8lK95hsNYIrh0dPA/view?usp=drive_link',
        }, {
          name: 'reporte-remarketing-zrv-20-10-2023.xlsx',
          link: 'https://drive.google.com/open?id=12g6FxAQZULBWW0HwhXe6l2Sql587aB5x&usp=drive_copy',
        }, {
          name: 'reporte-remarketing-19-10-2023.xlsx',
          link: 'https://drive.google.com/open?id=12IeVaaaT9Gjfr9jiUYgiT1VNdgHpecdq&usp=drive_copy',
        }, {
          name: 'reporte-referido-19-10-2023.csv',
          link: 'https://drive.google.com/open?id=1X0DT6URi_-heGo0h-yi3O1kUUUr2gUBM&usp=drive_copy',
        }, {
          name: 'reporte-recompra-zrv-20-10-2023.xlsx',
          link: 'https://drive.google.com/open?id=1pI6gdSxlHPlmfvn64hAbDwXr5jo_yrlO&usp=drive_copy',
        }, {
          name: 'reporte-recompra-19-10-2023.csv',
          link: 'https://drive.google.com/open?id=10Jt5qcBTBg-D2XsjIL0XVcZzwN8ve8R1&usp=drive_copy',
        }, {
          name: 'reporte-bienvenida-20-10-2023.csv',
          link: 'https://drive.google.com/open?id=1O3hd-6ZJZqerRni55DJv92mVHFnMJEK2&usp=drive_copy',
        }, {
          name: 'reporte-bienvenida-17-10-2023.csv',
          link: 'https://drive.google.com/open?id=1YVBQFErNfkhJAy2f-6VDk1qGy0KsDLdn&usp=drive_copy',
        }, {
          name: 'remarketing-reporte-26_10_23.xlsx',
          link: 'https://drive.google.com/open?id=1VICtuYguZ21zEyDLzlnAnRkcHKJuJ6nL&usp=drive_copy',
        }, {
          name: 'remarketing-reporte-26_10_23.csv',
          link: 'https://drive.google.com/open?id=1nvSe8yhMo-fnL1N2C6YxtI0jxooC6qPt&usp=drive_copy',
        }, {
          name: 'referidos-reporte-26_10_23.xlsx',
          link: 'https://drive.google.com/open?id=18t5c2r49Bn2EmeiL2ErChC1PAh49s39v&usp=drive_copy',
        }, {
          name: 'recompra-reporte-26_10_23.csv',
          link: 'https://drive.google.com/open?id=13WQ34DtuasCyv-wgdsVw-C0z6dA921eF&usp=drive_copy',
        }, {
          name: 'bienvenida-reporte-27_10_23.csv',
          link: 'https://drive.google.com/open?id=1BUOtvmbHC7y-QBxnXQ2kgf8ZJE1xMZg_&usp=drive_copy',
        }, {
          name: 'bienvenida-reporte-24_10_23.csv',
          link: 'https://drive.google.com/open?id=1F2GNkX5nnMDVSf-cYV7tWi5dkq4WlHtb&usp=drive_copy',
        }, {
          name: 'referidos-zrv-reporte-31_10_23.ods',
          link: 'https://drive.google.com/open?id=1tQBdwZvDTKrTf7ejb8Km4J8LBzdznMa8&usp=drive_copy',
        }, {
          name: 'encuesta-ssi-reporte-31-10-2023.xlsx',
          link: 'https://drive.google.com/open?id=1jOE1x6yn1TGnOfXVBS0BAEI9kaFE30gN&usp=drive_copy',
        }, {
          name: 'encuesta-ssi-reporte-31-10-2023.csv',
          link: 'https://drive.google.com/open?id=1ssm5u8wXvzV-T6s946rPCV_aLwoLWYPX&usp=drive_copy',
        },
        {
          name: 'encuesta-entregas-reporte-31-10-2023.xlsx',
          link: 'https://drive.google.com/open?id=1OQdVrUgAjX3KCcbPXvK6A5-JSaWvOdlb&usp=drive_copy',
        }, {
          name: 'encuesta-entregas-reporte-31-10-2023.csv',
          link: 'https://drive.google.com/open?id=1HYG7gxHBrhnDewxQUAojMuUqzMSWxkLS&usp=drive_copy',
        }, {
          name: 'encuesta-cotizaciones-reporte-31-10-2023.xlsx',
          link: 'https://drive.google.com/open?id=1TFobOmwRn6zRRB5Ru3XRR2LD6BF9h3Cc&usp=drive_copy',
        }, {
          name: 'encuesta-cotizaciones-reporte-31-10-2023.csv',
          link: 'https://drive.google.com/open?id=1QelaqEMD2vxNsvSrOQ9X2fcpzDuxOIR2&usp=drive_copy',
        }, {
          name: 'bienvenida-reporte-31_10_23.csv',
          link: 'https://drive.google.com/open?id=1MzkvWq4pZrZSbLojjmp_YxtyqorarfaR&usp=drive_copy',
        }
      ]
    },
    {
      id: 5,
      name: '2023 - REPORTES NOVIEMBRE',
      reportes: [
        {
          name: 'Remarketing-reporte-2023-11-02.xlsx',
          link: 'https://drive.google.com/open?id=1q3pRF__uc-qKZoAHg1ZxT836cHt7UlUI&usp=drive_copy'
        },
        {
          name: 'Recompra-zrv-reporte-2023-11-03.xlsx',
          link: 'https://drive.google.com/open?id=1lViBNrTmSfkpSOAP2W2GK4vZZLcuK-RW&usp=drive_copy'
        },
        {
          name: 'Recompra-reporte-2023-11-03.xlsx',
          link: 'https://drive.google.com/open?id=1MJKruzLc8Ff9bAEFQ4E__lPSl1PReltw&usp=drive_copy'
        }, {
          name: 'bienvenida-reporte-2023-11-03.csv',
          link: 'https://drive.google.com/open?id=1HCvsSGyCGEs1yVzqitDM0XVXrpj9Jtq4&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-zrv-09-11-23.xlsx',
          link: 'https://drive.google.com/open?id=1_STAtTA1og0DQix_E42gIP4uMQokBoEn&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-zrv-09-11-23.csv',
          link: 'https://drive.google.com/open?id=1hYycbKmzPuZ8VVF9coj7V8jhLFRueoCF&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-09-11-23.xlsx',
          link: 'https://drive.google.com/open?id=1V-84xq7fmUogXdUvajnOumSYU-6ml25y&usp=drive_copy'
        }, {
          name: 'reporte-referidos-zrv-09-11-23.xlsx',
          link: 'https://drive.google.com/open?id=1KE-Am625i9zjDF2dLCO24gw2BLh3390_&usp=drive_copy'
        }, {
          name: 'reporte-referidos-hrv-09-11-23.xlsx',
          link: 'https://drive.google.com/open?id=18Ucydao84jISY0U2QqFTpDTNn_-nN4p0&usp=drive_copy'
        }, {
          name: 'reporte-recompra-09-11-23.xlsx',
          link: 'https://drive.google.com/open?id=1IwQdah3vnnkW4h2ohKWcdQ6826tr3bU0&usp=drive_copy'
        }, {
          name: 'reporte-recompra-06-11-23.xlsx',
          link: 'https://drive.google.com/open?id=1YrdKvqL2Yl4NyFyA900cVvYMVSiUvAtP&usp=drive_copy'
        }, {
          name: 'reporte-encuesta-ssi-10-11-2023.csv',
          link: 'https://drive.google.com/open?id=16H8-menbdpRhi-Pamyqaa6sEt2diY6bW&usp=drive_copy'
        }, {
          name: 'reporte-encuesta-ssi-07-11-2023.csv',
          link: 'https://drive.google.com/open?id=1p6raJaUcUXJ7wKKlcemwynGtko0vim8a&usp=drive_copy'
        }, {
          name: 'reporte-encuesta_nps_entregas-07-11-2023.csv',
          link: 'https://drive.google.com/open?id=1X_fkNdyTuvjkw6TVEBYJuoO09VxR-5RE&usp=drive_copy'
        }, {
          name: 'reporte-encuesta_nps_cotizaciones-10-11-2023.xlsx',
          link: 'https://drive.google.com/open?id=1HHD0ckVF3qI30QXF5UBAnvS48oAJ1nRl&usp=drive_copy'
        }, {
          name: 'reporte-encuesta_nps_cotizaciones-07-11-2023.xlsx',
          link: 'https://drive.google.com/open?id=1NkY2UODdQqU-18l7H2qm0bu9wz9yhUWk&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-07-11-23.csv',
          link: 'https://drive.google.com/open?id=1i1xIYG5T_iWh-mJB8MdZYPxlTzbkM8AP&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-07-10-23.csv',
          link: 'https://drive.google.com/open?id=1QUVHaXbksm6kMLk92-85AhXOZK6OMeRk&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-23-24NOV.xlsx',
          link: 'https://drive.google.com/open?id=1ehc6b6OyQBFBueCn2pH_LlXkZl4Q6Y4n&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-23-24NOV.csv',
          link: 'https://drive.google.com/open?id=1vrja8vpG4xEjbtcqhoYidK-p-uQOKibQ&usp=drive_copy'
        }, {
          name: 'reporte-referidos-23NOV_ZRV.csv',
          link: 'https://drive.google.com/open?id=1P5T_9HYRnoanQeds6zjvhaejB-ptGE18&usp=drive_copy'
        }, {
          name: 'reporte-referidos-23NOV_HRV.csv',
          link: 'https://drive.google.com/open?id=17MUr08bnpb1crxQ5rmbRstJdggodD3be&usp=drive_copy'
        }, {
          name: 'reporte-recompra-23NOV.csv',
          link: 'https://drive.google.com/open?id=1hd_Tt8h_yUv-W_eWKrrfIttHWOTnUgY-&usp=drive_copy'
        }, {
          name: 'reporte-nps_ssi-24NOV.csv',
          link: 'https://drive.google.com/open?id=1XRUWxOvE_3lv27j4VuQpdl5BOHieb0EM&usp=drive_copy'
        },
        {
          name: 'reporte-nps_ssi-21NOV.csv',
          link: 'https://drive.google.com/open?id=1eUzNX6C4uSJ8fW9ucE5gMgCXoXOh1Nq4&usp=drive_copy'
        }, {
          name: 'reporte-nps_entregas-28NOV.csv',
          link: 'https://drive.google.com/open?id=1bLRldd6G3nxyMxnQvNtue_KjTfORrz5w&usp=drive_copy'
        }, {
          name: 'reporte-nps_entregas-24NOV.csv',
          link: 'https://drive.google.com/open?id=1y4EAF5t2NheKoyTx6ZNngt_yboBd5KvF&usp=drive_copy'
        }, {
          name: 'reporte-nps_entregas-21NOV.csv',
          link: 'https://drive.google.com/open?id=1aKJOOzyo-GoDw5q3TTK_q5pKhfoX2G93&usp=drive_copy'
        }, {
          name: 'reporte-nps_cotizaciones-24NOV.csv',
          link: 'https://drive.google.com/open?id=1b4DkgVkiYXRWBfIfiiOuW8z6P9KJi_ED&usp=drive_copy'
        }, {
          name: 'reporte-nps_cotizaciones-21NOV.csv',
          link: 'https://drive.google.com/open?id=1mJqhpeIc-_FM95srST3QU0YUDzKQ6eYE&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-24NOV.csv',
          link: 'https://drive.google.com/open?id=1Onhx3sov-XvMsR-fN591jvG_sU6LueOV&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-21NOV.csv',
          link: 'https://drive.google.com/open?id=1ctwA1PgUToIYzmiOflJVxvrkmAZILvum&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-Modelos-29NOV.xlsx',
          link: 'https://drive.google.com/open?id=1hTmGgGhy0EU22jFEtvTbIBke_37tJ34n&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-Modelos-29NOV.csv',
          link: 'https://drive.google.com/open?id=1CTbVTWOlRnoAUszsVTeFFQ6wzBxBAkvG&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-30NOV.xlsx',
          link: 'https://drive.google.com/open?id=1Aj3MV54Lrferb16GPwhwlLX7N2ysWVcl&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-30NOV.csv',
          link: 'https://drive.google.com/open?id=152Sn0DNenz54jEIdV9yAtl0eYdpjgnHg&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-28NOV.xlsx',
          link: 'https://drive.google.com/open?id=19vof1gLS2MC0TFgrgIKJwM7VmFbAjAb7&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-28NOV.csv',
          link: 'https://drive.google.com/open?id=1M8oOnnDeKFLfnyZjPCHgF3_Vencufu2C&usp=drive_copy'
        }, {
          name: 'reporte-referidos-ZRV30NOV.csv',
          link: 'https://drive.google.com/open?id=1GyIntSTekOAVX1EE29ZoZL4s1-6Ux5rm&usp=drive_copy'
        }, {
          name: 'reporte-referidos-HRV30NOV.csv',
          link: 'https://drive.google.com/open?id=1-RozSlCpPr965PBQTrGNzcHMtVPOtWDs&usp=drive_copy'
        }, {
          name: 'reporte-encuesta-ssi-28NOV.csv',
          link: 'https://drive.google.com/open?id=19hF5Qd6I-kDIM4WMEnJu0S2OF0msN6t6&usp=drive_copy'
        }, {
          name: 'reporte-encuesta-entregas-28NOV.csv',
          link: 'https://drive.google.com/open?id=1_kjqc8CDn6IF3SlL2VQobgP_GHwGH41f&usp=drive_copy'
        }, {
          name: 'reporte-encuesta-cotizaciones-28NOV.csv',
          link: 'https://drive.google.com/open?id=1CI47snXIx-Hq5pLRYa2WAFvD43hD0dAG&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-28NOV.csv',
          link: 'https://drive.google.com/open?id=1KoI0OlL38Q7jCt-UcuT9PNH6YTBqrBsz&usp=drive_copy'
        }
      ]
    },
    {
      id: 6,
      name: '2023 - REPORTES DICIEMBRE',
      reportes: [
        {
          name: 'reporte-remarketing-20231201.csv',
          link: 'https://drive.google.com/open?id=1kpL3TGouIV32OEoo8jQernBOhIwfnJT-&usp=drive_copy'
        },
        {
          name: 'reporte-recompra-20231201.csv',
          link: 'https://drive.google.com/open?id=1gnzIH4DTklSEzlQcn5gR7BB2qqPi9-yr&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-20231207.csv',
          link: 'https://drive.google.com/open?id=1t9f5RnkwYFLusLxeB5MHI8nuUral0B5L&usp=drive_copy'
        }, {
          name: 'reporte-referidos-ZRV-20231207.csv',
          link: 'https://drive.google.com/open?id=1N7srGus_CIz6YDoLXJS3Ho5uWEAnTuvp&usp=drive_copy'
        }, {
          name: 'reporte-referidos-hrv-20231207.csv',
          link: 'https://drive.google.com/open?id=1Pk2QnVHNqx8Q5PgsU79-dLcLF8Ap7Fwo&usp=drive_copy'
        }, {
          name: 'reporte-recompra-20231208.csv',
          link: 'https://drive.google.com/open?id=1WTI8DHs901fQ9KqbexmKAIwloPPD3DdS&usp=drive_copy'
        }, {
          name: 'reporte-nps-cotizaciones-20231207.csv',
          link: 'https://drive.google.com/open?id=1GIJkYV7UGWE_8A0oEYYFVm1wI0asv3FU&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-20231205.csv',
          link: 'https://drive.google.com/open?id=1h1_VfN4Qldtpwmvijs2O8gH4bsDpXgdp&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-part3-20231116.csv',
          link: 'https://drive.google.com/open?id=1yTCQNC_XHgsqa4yXKhN9gdl1qW2SQLan&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-activacion-20231215.csv',
          link: 'https://drive.google.com/open?id=1gCatWMU019OouTlRTdbJw0pbjdlcUHCf&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-20231214.csv',
          link: 'https://drive.google.com/open?id=1UtN0xcia93dqRcqpoWXm4ZAbCydidqHV&usp=drive_copy'
        }, {
          name: 'reporte-referidos-zrv-20231214.csv',
          link: 'https://drive.google.com/open?id=1UbkoT847DcXbtnaJdfjvYewFAtmorOF7&usp=drive_copy'
        }, {
          name: 'reporte-referidos-hrv-20231214.csv',
          link: 'https://drive.google.com/open?id=1FnkNdTrum3MA6o4v4I47hXzP1tycgotC&usp=drive_copy'
        }, {
          name: 'reporte-recompra-20231215.csv',
          link: 'https://drive.google.com/open?id=1lqtbtkBYdZb4-eppflUQwgwNREPxBbY5&usp=drive_copy'
        }, {
          name: 'reporte-nps-ssi-20231215 (1).xlsx',
          link: 'https://drive.google.com/open?id=1aBnMm-naRHh8di7YL5_6KMCOXu2HlPl8&usp=drive_copy'
        }, {
          name: 'reporte-nps-cotizaciones-20231215.csv',
          link: 'https://drive.google.com/open?id=1YcvpkEI6_vufRet5JqXDcV0gNqo0ku57&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-20231212.csv',
          link: 'https://drive.google.com/open?id=1NA52xmK6aWY3BAaAJHlVM2lWpRBR4Sg5&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-zrv-20231219.csv',
          link: 'https://drive.google.com/open?id=1bAeEMyy9IyAZALfGJn-VObYy4Dks1LQy&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-activacion-20231222.csv',
          link: 'https://drive.google.com/open?id=1pZyCwXstJhYYV8IhwnBqEPpXkd9JPEeH&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-20231222.xlsx',
          link: 'https://drive.google.com/open?id=1oG1bDaTcn7WfXdaqqrRxRSnJPMT5mD0c&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-20231222.csv',
          link: 'https://drive.google.com/open?id=1Y0JybqyljDSBOwFudNsmPHUmWmwirb6G&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-20231221.xlsx',
          link: 'https://drive.google.com/open?id=1SNFLO59ehdzdaHN7eYWN8ytn8tFE7mjr&usp=drive_copy'
        }, {
          name: 'reporte-remarketing-20231221.csv',
          link: 'https://drive.google.com/open?id=1_tORv8G5NOuOEmYB2ff_Zkv8EWVrqKJE&usp=drive_copy'
        }, {
          name: 'reporte-referidos-zrv-20231221.csv',
          link: 'https://drive.google.com/open?id=1plj9ATxas1h289wGhD_XdJhSuTlHjWbi&usp=drive_copy'
        },
        {
          name: 'reporte-referidos-hrv-20231221.csv',
          link: 'https://drive.google.com/open?id=1mYzTArZ6FoG1w1lNonQav-fUsMl26vNK&usp=drive_copy'
        }, {
          name: 'reporte-recompra-20231222.csv',
          link: 'https://drive.google.com/open?id=17HoHdAAVVzP6f4Mx67VLFQHceBxdwqB6&usp=drive_copy'
        }, {
          name: 'reporte-nps-cotizaciones-20231226.csv',
          link: 'https://drive.google.com/open?id=1rhLjCWsj188-cIVFrsfogwuwv45w7zx0&usp=drive_copy'
        }, {
          name: 'reporte-nps-cotizaciones-20231222.csv',
          link: 'https://drive.google.com/open?id=1ixmS0GmDYbJZFG5NRfVKMCQC6wk3Y1tk&usp=drive_copy'
        }, {
          name: 'reporte-nps-cotizaciones-20231219.csv',
          link: 'https://drive.google.com/open?id=1E4daI-FLa5e-cSHWG7rgj15EUg51YLdV&usp=drive_copy'
        }, {
          name: 'reporte-nps_ssi-20231226.csv',
          link: 'https://drive.google.com/open?id=1NAUH0zKh_EPM9M-MkzMU3WBFQHssTPm6&usp=drive_copy'
        }, {
          name: 'reporte-nps_ssi-20231222.csv',
          link: 'https://drive.google.com/open?id=1bX-NqzxJ9vwco_-ckuptBa0VA2PySC2w&usp=drive_copy'
        }, {
          name: 'reporte-nps_ssi-20231219.csv',
          link: 'https://drive.google.com/open?id=1Cu417ulMzPUSPstmnnecjI0CzB1w4BQm&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-20231226.csv',
          link: 'https://drive.google.com/open?id=1LGE_7o2lPyq-MEckkTG64LpyP17fZs2-&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-20231219.csv',
          link: 'https://drive.google.com/open?id=1ZZBndfQ0pFIpwWLTTh9f7VNyboAzRNVc&usp=drive_copy'
        }
      ]
    },
    {
      id: 7,
      name: '2024 - REPORTES ENERO',
      reportes: [
        {
          name: 'reporte-remarketing-activaciones-20240103.csv',
          link: 'https://drive.google.com/open?id=1EMUMl8Cr8lbXremFzjVdDbTbyczJypFn&usp=drive_copy'
        },
        {
          name: 'reporte-remarketing-20240104.csv',
          link: 'https://drive.google.com/open?id=1MERJhmBiGdm5jVXPq_M87yNGL3XHOGFj&usp=drive_copy'
        }, {
          name: 'reporte-referidos-ZRV-20240104.csv',
          link: 'https://drive.google.com/open?id=1RjlvuJH1drD1WIRg-yJdHTkjf1CeaoRs&usp=drive_copy'
        }, {
          name: 'reporte-referidos-hrv-20240104.csv',
          link: 'https://drive.google.com/open?id=1xNziP4VGOHnFZW1U8o9Y7lKGwtdgze8S&usp=drive_copy'
        }, {
          name: 'reporte-recompra-20240103.csv',
          link: 'https://drive.google.com/open?id=1e3dGPIVILZQvrYQopzsuavIzq3B_V3mT&usp=drive_copy'
        }, {
          name: 'reporte-nps-ssi-20240102.csv',
          link: 'https://drive.google.com/open?id=1ZlswN6wAtgkuklkXoMKYDiXKT6hHhT6j&usp=drive_copy'
        }, {
          name: 'reporte-nps_cotizaciones-20240102.xlsx',
          link: 'https://drive.google.com/open?id=1niHv1H7-2JCm_FtwV3QAdSC4lZ5-MOFy&usp=drive_copy'
        }, {
          name: 'reporte-nps_cotizaciones-20240102.csv',
          link: 'https://drive.google.com/open?id=1ogAhJje2LLgp0YYTdnGCYcQesYjUi7nJ&usp=drive_copy'
        }, {
          name: 'reporte-bienvenida-20240102.csv',
          link: 'https://drive.google.com/open?id=1PiXT_ZlR9hq51i9Z2IAaHlcr7hOt8XM9&usp=drive_copy'
        }, {
          name: 'Reporte_RMKT_2024-01-31_10-46-49.xlsx',
          link: 'https://drive.google.com/open?id=1jg7_cVxtU42ztNwh3heBqjZXu0A4odbS&usp=drive_copy'
        }, {
          name: 'Reporte_RMKT_2024-01-31_10-18-23.xlsx',
          link: 'https://drive.google.com/open?id=1_Fb06JxtG9KiTeMlLuLCrWK_JB85PBLM&usp=drive_copy'
        }, {
          name: 'Reporte_Referidos_ZRV_2024-01-31_10-05-54.xlsx',
          link: 'https://drive.google.com/open?id=1vJiASOa2Ydr6M8MPEvkYwHiuGU3U4Eef&usp=drive_copy'
        }, {
          name: 'Reporte_Referidos_HRV_2024-01-31_10-01-40.xlsx',
          link: 'https://drive.google.com/open?id=1SyTErB6quKelOWtVZu8uaDVsNiOjyEh4&usp=drive_copy'
        }, {
          name: 'Reporte_Recompra_2024-01-31_10-10-08.xlsx',
          link: 'https://drive.google.com/open?id=1posYmZLk7BvfFKgNPbullzqMLvkxmFTg&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA REMARKETING ACTIVACION 17.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1pHRY11MtDGzqV2hERI6a-7plkMiy5YJs&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA REMARKETING 23.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1z39LstZKQw_De5Z_ic89ERzTCBuBiYnI&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA REMARKETING 18.01.24 BBDD1.xlsx',
          link: 'https://drive.google.com/open?id=1WkLWkzDGPOYblBK8-SBRVpC5czpQQtAa&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA REMARKETING 18.01.24 BBDD 2.xlsx',
          link: 'https://drive.google.com/open?id=1W-Zk09xC9XwucAKKWm2Y62tF0TtPo0fV&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA REFERIDOS ZRV 24.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1XeE6VL2Fn7JkmCE0MBOsbTF_yJnxYswv&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA REFERIDOS ZRV 18.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1ygrISdhCNoVXJ3_iRJsfmDFGZaftlGAT&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA REFERIDOS HRV 24.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1nk0l4ar3w3_F8g5uuHzWH_hStfrsp6cJ&usp=drive_copy'
        },
        {
          name: 'HONDA 4W - HONDA REFERIDOS HRV 18.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1at2uM2dda6p2jUxb0dfCmxeIIZk2FerA&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA RECOMPRA 24.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1v6eZiZvZ0ZpQq9unjzt5QPQz0EeyK8VL&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA RECOMPRA 17.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1n-3FQ-ZDrR-eUmYI1PAVlgRIpNb2RrtB&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA NPS_SSI 24.01.24.xlsx',
          link: 'https://drive.google.com/open?id=14PNwdBIN009c397EjxKlBE21IBjBZJYj&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA NPS_SSI 19.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1gWRvUYR0fjnUEyywyuskEn0pgILw8Pjd&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA NPS COTIZACIONES 24.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1X8OGPch4NOOrN8yssIVMJSYTfeztrVVa&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA NPS COTIZACIONES 19.01.23.xlsx',
          link: 'https://drive.google.com/open?id=1yes80ow8tNwA9ZEsWfbaIZgvrz5gOPSk&usp=drive_copy'
        }, {
          name: 'HONDA 4W - HONDA BIENVENIDA 23.01.24.xlsx',
          link: 'https://drive.google.com/open?id=1CNlePD1RdzKaxtFLruHnX4jCfvH0-KD7&usp=drive_copy'
        },
      ]
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
