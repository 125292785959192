import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient) { }

  async get_template_by_id(template_id) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('template_id', JSON.stringify(template_id))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}TemplateAPI/get_template_by_id`, data));
    return response
  }

  async get_template_whatsapp_by_id(template_id) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('template_id', JSON.stringify(template_id))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}TemplateAPI/get_template__whatsapp_by_id`, data));
    return response
  }

  async insertTemplateWhatsapp(template) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('template', JSON.stringify(template))

    const response = await lastValueFrom(this.http.post(`${environment.url_api}TemplateAPI/insert_whatsapp`, data));
    return response
  }

  async insertTemplate(template) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('template', JSON.stringify(template))

    const response = await lastValueFrom(this.http.post(`${environment.url_api}TemplateAPI/insert`, data));
    return response
  }

  async get_all_templates() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    const response = await lastValueFrom(this.http.post(`${environment.url_api}TemplateAPI/get_all_templates`, data));
    return response
  }

  async get_all_templates_whatsapp() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    const response = await lastValueFrom(this.http.post(`${environment.url_api}TemplateAPI/get_all_templates_whatsapp`, data));
    return response
  }


}
