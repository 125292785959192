<app-breadcrumb [title]="'Plantillas'" [items]="['Campañas', 'Configuración','Plantillas']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 item" [routerLink]="'add-from-crm'">
                            <div class="card bg-primary">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Plantillas de Email Marketing</h5>
                                            <p>Aquí encontrarás todas las plantillas para envios de Email Marketing y tambien podrás subir tus propias plantillas</p>
                                        </div><i data-feather="mail"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 item" [routerLink]="'add-from-excel'">
                            <div class="card bg-success">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Plantillas de mensajería Whatsapp</h5>
                                            <p>Aquí podrás visualizar las plantillas de Whatsapp</p>
                                            <p></p>
                                        </div><i data-feather="message-square"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="audiences.length > 0">
            <div class="card">
                <div class="card-header">
                    <h4>Listado de audiencias disponibles <i data-feather="users"></i></h4>

                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th class="text-center">Nombre de audiencia</th>
                                    <th class="text-center">Cantidad de personas</th>
                                    <th class="text-center">Tipo de audiencia</th>
                                    <th class="text-center">Fecha de creación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let audience of audiences">
                                    <td>{{audience.original_name}}</td>
                                    <td class="text-center">{{audience.cantidad}}</td>
                                    <td class="text-center">{{audience.type_audience}}</td>
                                    <td class="text-center">{{audience.created_time}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>