import { Input, Component, OnInit, AfterViewInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import Swal from 'sweetalert2';
import { ModalProgramarLlamadaHourpickerComponent } from '../modal-programar-llamada-hourpicker/modal-programar-llamada-hourpicker.component';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-modal-programar-llamada-semi-nuevo',
  templateUrl: './modal-programar-llamada-semi-nuevo.component.html',
  styleUrls: ['./modal-programar-llamada-semi-nuevo.component.scss']
})
export class ModalProgramarLLamadaSemiNuevoComponent implements AfterViewInit {

  @Input() private lead_id
  @Input() private jwt
  @Input() private concesionario_id


  filtros = []
  motivos = []


  calendarOptions: CalendarOptions;
  selectedDate: string;





  constructor(
    private modalService: NgbModal,
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {

    this.calendarOptions = {
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin, interactionPlugin],
      selectable: true,
      events: [],
      eventClick: this.handleEventClick.bind(this),
      dateClick: this.handleDateClick.bind(this),
      editable: true,
      eventDrop: this.handleEventDrop.bind(this)
    };



    this.motivos = [

      {
        btn: 'btn-caliente',
        icon: 'icofont-mobile-phone',
        id_checkbox: 'motivo-2',
        name: 'Llamada',
        checked: true
      },
      {
        btn: 'btn-caliente',
        id_checkbox: 'motivo-3',
        icon: 'icofont-user-suited',
        name: 'Visita',
        checked: true
      }
    ]

    this.filtros = [
      {
        btn: 'btn-pendiente',
        id_checkbox: 'checkbox-primary-1',
        name: 'Pendiente',
        checked: true
      },
      {
        btn: 'btn-frio',
        id_checkbox: 'checkbox-primary-2',
        name: 'Frio',
        checked: true
      },
      {
        btn: 'btn-tibio',
        id_checkbox: 'checkbox-primary-3',
        name: 'Tibio',
        checked: true
      },
      {
        btn: 'btn-caliente',
        id_checkbox: 'checkbox-primary-4',
        name: 'Caliente',
        checked: true
      },
      {
        btn: 'btn-reprogramado',
        id_checkbox: 'checkbox-primary-5',
        name: 'Reprogramado',
        checked: true
      },
      {
        btn: 'btn-venta',
        id_checkbox: 'checkbox-primary-6',
        name: 'Venta',
        checked: true
      }
    ];
  }


  actualizar(filtro: any) {
    const valorAnterior = filtro.checked;
    filtro.checked = !filtro.checked; // Actualizar el modelo

    if (filtro.checked !== valorAnterior) {
      this.filtrar(); // Llamar a la función filtrar() si el valor ha cambiado
    }
  }

  filtrar() {
    this.cargarCalendario()
  }

  async ngAfterViewInit() {
    this.calendarOptions.events = []
    this.cargarCalendario()

  }

  async cargarCalendario() {
    const data = new FormData()


    data.append('jwt', this.jwt)
    data.append('filtros', JSON.stringify(this.filtros))
    data.append('motivos', JSON.stringify(this.motivos))
    this.calendarOptions.events = []
    Swal.showLoading()
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPISemiNuevo/get_all_my_llamadas_reprogramadas`, data))
    if (response.success) {
      this.calendarOptions.events = response.leads
    }
    Swal.close()
  }

  async ngOnInit() {
  }

  loadCalendar() {

  }

  handleEventClick(arg) {
    const lead_id__selected = arg.event.id
    console.log(lead_id__selected)
    Swal.fire({
      title: '¿Deseas ir al lead?',
      text: "Serás redirigido al detalle del lead",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#51bb25 !important',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {

      
        this.activeModalService.close({
          success: true,
          lead_id: lead_id__selected
        })
      }
    })
  }
  handleDateClick(arg) {
    const date_selected = arg.dateStr

    const dialogRef = this.modalService.open(ModalProgramarLlamadaHourpickerComponent,
      {
        size: 'lg',
        centered: true
      })

    dialogRef.componentInstance.fecha = date_selected
    dialogRef.componentInstance.testride = false


    dialogRef.result.then((data) => {
      if (data.success) {
        console.log('Cargando calendario ...')

        this.reprogramarLead({
          date: data.data.fecha,
          motivo: data.data.motivo,
          lead_id: this.lead_id,
          jwt: this.jwt
        })

      }
    })
  }

  async reprogramarLead(item) {
    const data = new FormData()
    data.append('lead_id', item.lead_id)
    data.append('jwt', item.jwt)
    data.append('date', item.date)
    data.append('motivo', item.motivo)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPISemiNuevo/insert_reprogramacion_leads`, data))
    if (response.success) {
      this.cargarCalendario()
      Swal.fire(`Se registró  ${item.motivo} correctamente`, '', 'success')
    }
  }

  handleEventDrop(info) {
    // Aquí puedes acceder a la información del evento arrastrado y soltado
    console.log('esta es la info: ', info)

    const draggedEvent = info.event;

    const evento_id = draggedEvent.extendedProps.evento_id

    const newDate = draggedEvent.start;

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const hours = String(newDate.getHours()).padStart(2, '0');
    const minutes = String(newDate.getMinutes()).padStart(2, '0');
    const seconds = String(newDate.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    this.cambiarFecha(evento_id, formattedDate)
  }

  async cambiarFecha(evento_id,new_date){
    Swal.showLoading()
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('new_date', new_date)
    data.append('evento_id', evento_id)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPISemiNuevo/update_reprogramacion_leads`, data))
    if (response.success) {
      Swal.fire(response.message, '', 'success')
    }else{
      Swal.fire(response.message, '', 'error')
    }
  }
}