<div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div></div>
          <div class="login-main">
            <div>
              <a class="logo" routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/logo.svg" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo.svg" alt="looginpage">
              </a>
  
              <form class="theme-form d-flex flex-column justify-content-center align-items-center">
                <p>Ingrese el rol con el que desee iniciar sesión</p>
                
                <!-- Aquí se vincula el ngModel con selectedRole -->
                <select class="form-select form-select-lg m-auto" id="roles" [(ngModel)]="selectedRole">
                  <option value="" selected disabled>Selecciona un rol</option>
                  <option *ngFor="let role of roles" [value]="role.id">{{ role.tipo_usuario }}</option>
                </select>
  
                <!-- Al hacer clic, se pasa el objeto del rol seleccionado a la función selectRole -->
                <button type="button" class="btn btn-danger mt-3" (click)="selectRole(selectedRole)">Ingresar</button>
              </form>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  