import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalty-campaigns-config-campaigntype',
  templateUrl: './loyalty-campaigns-config-campaigntype.component.html',
  styleUrls: ['./loyalty-campaigns-config-campaigntype.component.scss']
})
export class LoyaltyCampaignsConfigCampaigntypeComponent implements OnInit {

  templates = [
    {
      id: 1,
      name: 'Remarketing',
      created_by: 'Administrador',
      created_time: '2023'
    },
    {
      id: 2,
      name: 'Recompra',
      created_by: 'Administrador',
      created_time: '2023'
    },
    {
      id: 3,
      name: 'Referidos',
      created_by: 'Administrador',
      created_time: '2023'
    },
    {
      id: 3,
      name: 'Encuestas',
      created_by: 'Administrador',
      created_time: '2023'
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
