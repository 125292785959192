import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-usuario-porcentajes',
  templateUrl: './dashboard-usuario-porcentajes.component.html',
  styleUrls: ['./dashboard-usuario-porcentajes.component.scss']
})
export class DashboardUsuarioPorcentajesComponent implements OnInit {
  @Input() data: any 
  constructor() { }

  ngOnInit(): void {
  }

}
