import { Input, Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { formatDate } from '@angular/common';

import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-modal-see-utms-history',
  templateUrl: './modal-see-utms-history.component.html',
  styleUrls: ['./modal-see-utms-history.component.scss']
})
export class ModalSeeUtmsHistoryComponent implements OnInit {


  @Input() private lead_id
  @Input() private jwt

  listado_acciones = []

  constructor(
    private http: HttpClient
  ) {
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_history_lead`, data))
    if (response.success) {
      this.listado_acciones = response.data
      console.log(this.listado_acciones)
    }
  }

  obtenerFecha(createdTime: string) {
    const fecha = new Date(createdTime);
    
    // Obtiene solo la fecha en formato 'dd/MM/yyyy'
    return formatDate(fecha, 'dd/MM/yyyy', 'en-US');

  }


  obtenerHora(createdTime: string) {
    const fecha = new Date(createdTime);
    
    // Obtiene solo la hora en formato 'dd/MM/yyyy'
    return formatDate(fecha, 'HH:mm:ss', 'en-US');

  }

  sindominio(url: string){
    

    let urlSinDominio = url.replace('https://autos.honda.com.pe/', '');

    if (urlSinDominio == '' ) {
       urlSinDominio = "/Home";
    }

    if (urlSinDominio == '/' ) {
      urlSinDominio = "/Home";
   }

    return urlSinDominio;


  }


}
