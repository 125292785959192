<div class="container">
    <div class="form-group">
        <h2>Agregar dato personal</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="correo">Nombre del dato personal</label>
                <input type="text" class="form-control" id="correo" [(ngModel)]="dato_personal.name">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label class="col-form-label m-r-10">¿Es requerido?</label>
                <div class="media-body text-end icon-state">
                    <label class="switch">
                        <input [(ngModel)]="dato_personal.required" type="checkbox"><span class="switch-state"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label class="col-form-label m-r-10">Tipo de pregunta</label>
                <ng-select [items]="tipo_pregunta" class="js-example-basic-multiple con_help" bindLabel="name"
                    (change)="onChangeTipoPregunta()" bindValue="id" [(ngModel)]="dato_personal.type"
                    (ngModelChange)="onTipoPreguntaChange()">
                </ng-select>
            </div>
        </div>
        <div *ngIf="[1,2].includes(dato_personal.type)">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="minimo">Mínimo</label>
                        <input type="number" minlength="0" class="form-control" id="minimo"
                            [(ngModel)]="dato_personal.minlength">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="maximo">Máximo</label>
                        <input type="number" minlength="0" class="form-control" id="maximo"
                            [(ngModel)]="dato_personal.maxlength">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="[3,4].includes(dato_personal.type)">
            <div class="row">
                <div class="col-sm-7">
                    <div class="row">
                        <div class="col-sm-7">
                            <input class="form-control digits" type="text" placeholder="Ingrese opción"
                                [(ngModel)]="nombre_opcion">
                        </div>
                        <div class="col-sm-5">
                            <button class="btn btn-success" (click)="agregarOpcion(i)">+</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <ul>
                        <li *ngFor="let opcion of dato_personal.options; let j = index">
                            <button class="btn btn-danger btn-sm" (click)="eliminarOpcion(j)">-</button>
                            <span>{{opcion.name}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>
</div>