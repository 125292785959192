import { Component, OnInit } from '@angular/core';
import { EncuestaService } from '../shared/services/encuesta.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'; // Importa el servicio NgbModal

const Swal = require('sweetalert2')


@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss']
})
export class NpsComponent implements OnInit {

  encuestas = []

  mostrar = "habiles"


  constructor(
    private encuestaService: EncuestaService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {

    this.view_encuestas();

    // const response = await this.encuestaService.get_encuestas();
    // this.encuestas = response.encuestas
  }

   mostrar_encuestas(opcion: string) {

    this.mostrar = opcion;

    this.view_encuestas();


    // Aquí puedes agregar lógica adicional si es necesario
  }

  async view_encuestas() {
    const filtro = {
      "status": "1"
    }

    if (this.mostrar == 'habiles') {
      filtro.status = "1"
    } else {
      filtro.status = "0"
    }

    const response = await this.encuestaService.get_encuestas_by_filtro(filtro);
    this.encuestas = response.encuestas

  }

  async openRecuperarConfirmationModal(id: string) {

    const response = await this.encuestaService.recuperar_encuesta(id);

    if (response.success) {

      Swal.fire(
        'Encuesta Recuperada',
        '',
        'success'
      );

      this.view_encuestas();

    }

  }

  async openDeleteConfirmationModal(id: string) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'La encuesta irá a papelera',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const response = await this.encuestaService.eliminar_encuesta(id);

        if (response.success) {

          Swal.fire(
            'Eliminado!',
            'La encuesta ha sido eliminada.',
            'success'
          );

          this.view_encuestas();

        }



      }
    });
    
  }

}
