import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UsersService } from '../../logueo/users.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  
  constructor(private fb: FormBuilder,public userService: UsersService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  
  // Simple Login
  login() {
    this.userService.login(this.loginForm.value['email'], this.loginForm.value['password']);
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
  }

}
