import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalty-campaigns-config-reportes',
  templateUrl: './loyalty-campaigns-config-reportes.component.html',
  styleUrls: ['./loyalty-campaigns-config-reportes.component.scss']
})
export class LoyaltyCampaignsConfigReportesComponent implements OnInit {

  templates = [
    {
      id: 1,
      name: 'Remarketing',
      created_by: 'Administrador',
      created_time: '2023-02-03 05:00:00'
    },
    {
      id: 2,
      name: 'Recompra',
      created_by: 'Administrador',
      created_time: '2023-02-02 05:00:00'
    },
    {
      id: 3,
      name: 'Referidos',
      created_by: 'Administrador',
      created_time: '2023-02-01 05:00:00'
    },
  ]

  
  constructor() { }

  ngOnInit(): void {
  }

}
