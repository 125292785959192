<app-breadcrumb [title]="'Plantillas'" [items]="['Campañas', 'Configuración','Plantillas']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>En este módulo, se podrá subir las plantillas</small>
                    </div>
                    <div class="media-body text-end">

                        <a (click)="subir_plantilla()" class="btn btn-primary me-2">Subir plantilla +</a>
                    </div>
                </div>
                <div class="card-body">
                    <h3 *ngIf="templates.length == 0">
                        No se encontraron plantillas subidas.
                    </h3>
                    <table class="table table-concesionarios" *ngIf="templates.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Nombre de la plantilla</th>
                                <th class="text-center" scope="col">Fecha de creación</th>
                                <th class="text-center" scope="col">Creado por</th>
                                <th class="text-center" scope="col">Ver</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of templates">
                                <td>{{t.name}}</td>
                                <td class="text-center">{{t.created_time}}</td>
                                <td class="text-center">
                                    <span class="badge badge-secondary">{{t.created_by}}</span>
                                </td>
                                <td class="text-center">
                                    <a (click)="ver_template(t.id)"><i class="fa fa-arrow-right"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>