<app-breadcrumb [title]="titulo" [items]="['Campañas', 'Audiencias','Generar']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="card mb-0">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5><u>Audiencia:</u></h5>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Nombre de la audiencia</label>
                            <div class="col-sm-9">
                                <input class="form-control" [(ngModel)]="audiencia.name" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tipo de audiencia</label>
                            <div class="col-sm-9">
                                <ng-select [items]="audienciaType" class="js-example-basic-multiple" placeholder=""
                                    [(ngModel)]="audiencia.type">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Seleccione tipo de envio</label>
                            <div class="col-sm-9">
                                <ng-select [items]="envioType" class="js-example-basic-multiple" placeholder=""
                                    [(ngModel)]="envio.type">
                                </ng-select>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="container-fluid">
    <div class="row">
        <div class="card mb-0">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5><u>Filtros</u></h5>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Fecha de
                                inicio</label>
                            <div class="col-sm-9">
                                <input class="form-control digits" [(ngModel)]="filtros.fecha_inicio" type="date">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Fecha de fin</label>
                            <div class="col-sm-9">
                                <input class="form-control digits" [(ngModel)]="filtros.fecha_fin" type="date">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">¿Respondió llamada?</div>
                            <ng-select [items]="default_values.respondio_llamada" class="js-example-basic-multiple"
                                placeholder="" [(ngModel)]="filtros.respondio_llamada" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Estado del cliente</div>
                            <ng-select [items]="default_values.estado_cliente" class="js-example-basic-multiple"
                                placeholder="" [(ngModel)]="filtros.estado_cliente" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Modelo</div>
                            <ng-select [items]="default_values.modelo" class="js-example-basic-multiple" placeholder=""
                                [(ngModel)]="filtros.modelo" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Concesionarios</div>
                            <ng-select [items]="default_values.tiendas" class="js-example-basic-multiple" bindValue="id"
                                bindLabel="name" [(ngModel)]="filtros.tiendas" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>

                    <!-- Cambio -->

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Seleccione plataforma</div>
                            <ng-select [items]="default_values.plataforma" [multiple]="true" bindLabel="name" [closeOnSelect]="false" bindValue="id" [(ngModel)]="filtros.plataforma">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                  <div>
                                    <input (change)="toggleSelectPlataforma(item, $event.target.checked)" id="item-{{ index }}" type="checkbox" [checked]="isItemSelected(item)" />
                                    {{ item }}
                                  </div>
                                </ng-template>
                              </ng-select>
                        </div>
                    </div>



                  
                  
                  
            
                    
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Seleccione formulario (Landing)
                            </div>

                            
                            <ng-select [items]="default_values.fuente" class="js-example-basic-multiple" placeholder=""
                                [(ngModel)]="filtros.fuente" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>
                    
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Tipo de filtro</div>
                            <ng-select [items]="default_values.tipo_filtro" class="js-example-basic-multiple"
                                placeholder="" [(ngModel)]="filtros.tipo_filtro" [multiple]="false">
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Respuesta genérica</div>
                            <ng-select [items]="default_values.respuestas_genericas" class="js-example-basic-multiple"
                                bindValue="id" bindLabel="name" [(ngModel)]="filtros.respuestas_genericas"
                                [multiple]="true">
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Solo documentos válidos</div>
                            <ng-select [items]="default_values.doc" class="js-example-basic-multiple"
                                bindValue="id" bindLabel="name" [(ngModel)]="filtros.doc"
                                [multiple]="true">
                            </ng-select>
                        </div>
                    </div>

                 
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">Excluir personas que hayan interactuado con campañas</div>
                            <ng-select [items]="default_values.campaign" bindValue="id" bindLabel="name" class="js-example-basic-multiple"
                                placeholder="" [(ngModel)]="filtros.campanasAperturadas" [multiple]="true">
                            </ng-select>
                        </div>        
                    </div>

                                
                    
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="form-group">
                                <div class="col-form-label">Tiempo desde el primer lead (en meses)</div>
                                <input class="form-control" type="number" [(ngModel)]="filtros.tiempoPrimerLead" placeholder="">
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="form-group">
                                <div class="col-form-label">Cantidad total de leads</div>
                                <input class="form-control" type="number" [(ngModel)]="filtros.cantidadLeads" placeholder="">
                            </div>
                        </div>
                    </div>
                  
                    <div class="col-12">
                        <h5><u>Filtros UTMS</u></h5>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">UTM Source</div>
                            <ng-select [items]="default_values.utm_source" class="js-example-basic-multiple"
                                placeholder="" [(ngModel)]="filtros.utm_source" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">UTM Medium</div>
                            <ng-select [items]="default_values.utm_medium" class="js-example-basic-multiple"
                                placeholder="" [(ngModel)]="filtros.utm_medium" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">UTM Campaign</div>
                            <ng-select [items]="default_values.utm_campaign" class="js-example-basic-multiple"
                                placeholder="" [(ngModel)]="filtros.utm_campaign" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="col-form-label">UTM Term/Content</div>
                            <ng-select [items]="default_values.utm_term" class="js-example-basic-multiple"
                                placeholder="" [(ngModel)]="filtros.utm_term" [multiple]="true">
                            </ng-select>
                        </div>
                    </div>


                    <div class="col-12">
                        <h5><u>Filtros adicionales</u></h5>

                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="media">
                                    <label class="col-form-label m-r-10">Quitar Leads de RMKT, Recompra y SMS de este mes</label>
                                    <div class="media-body text-end icon-state">
                                        <label class="switch">
                                            <input [value]="default_values.rmkt"
                                                [(ngModel)]="filtros.rmkt" type="checkbox"><span
                                                class="switch-state"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="media">
                                    <label class="col-form-label m-r-10">¿Es
                                        financiamiento?</label>
                                    <div class="media-body text-end icon-state">
                                        <label class="switch">
                                            <input [value]="default_values.financiamiento"
                                                [(ngModel)]="filtros.financiamiento" type="checkbox"><span
                                                class="switch-state"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Ternminos y condiciones -->

                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="media">
                                    <label class="col-form-label m-r-10">¿Aceptó Política de Privacidad de datos?</label>
                                    <div class="media-body text-end icon-state">
                                        <label class="switch">
                                            <input [value]="default_values.politica"
                                                [(ngModel)]="filtros.politica" type="checkbox"><span
                                                class="switch-state"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Datos -->

                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="media">
                                    <label class="col-form-label m-r-10">¿Aceptó Fines Adicionales?</label>
                                    <div class="media-body text-end icon-state">
                                        <label class="switch">
                                            <input [value]="default_values.datos"
                                                [(ngModel)]="filtros.datos" type="checkbox"><span
                                                class="switch-state"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="form-group">
                                    <div class="col-form-label">Por texto</div>
                                    <input class="form-control" type="text" [(ngModel)]="filtros.texto" placeholder="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 div-button_filter">
                        <button id="make_filter" type="button" class="btn btn-primary" (click)="rerender()"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
                            [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                            <span>{{buttonFiltrarTexto}}</span>
                        </button>
                    </div>
                </div>
                <div class="row" id="result" *ngIf="leads.length > 0">
                    <h5>En total existen: {{total_filtered}} registro(s) en el criterio filtrado.</h5>
                    <hr>
                    <br>
                    <h6>Data de muestra <small>(últimos 10 registros)</small>:</h6>
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Correo electrónico</th>
                                <th scope="col">Celular</th>

                                <th scope="col">Fecha del lead</th>
                                <th scope="col">Modelo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lead of leads">
                                <th scope="row">{{lead.nombres}}</th>
                                <td>{{lead.correo_electronico}}</td>
                                <td>{{lead.celular}}</td>
                                <td>{{lead.created_time}}</td>
                                <td>{{lead.modelo}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>

                    <div class="row" *ngIf="audiencia.type == 'Remarketing' || audiencia.type == 'Remarketing Preferencial' ">
                        <h6>Rangos de fechas:</h6>
                        <br>
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th>Rango</th>
                                    <th>Fecha de inicio</th>
                                    <th>Fecha de fin</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let rangoFecha of rangosFecha; let i = index;">
                                    <th scope="row">{{rangoFecha.name}}</th>
                                    <td><input class="form-control" type="date" [(ngModel)]="rangoFecha.start_date"
                                            [readonly]="true"></td>
                                    <td><input class="form-control" type="date" [(ngModel)]="rangoFecha.finish_date"
                                            [readonly]="!(rangoFecha.editable && i === 0)"
                                            (change)="onEndDateChange(i)"></td>
                                </tr>
                            </tbody>
                        </table>
                        <br><br>
     
                    </div>

                    <div class="row" *ngIf="audiencia.type == 'Remarketing' || audiencia.type == 'Remarketing Preferencial' || audiencia.type == 'Referidos'">

                        <h6 class="m-t-5">Modelos de comunicación</h6>
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th>Modelo Cotizado</th>
                                    <th>Modelo a comunicar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let modelo of modelosComunicacion; let i = index;">
                                    <td>{{modelo.modelo}}</td>
                                    <td>
                                        <ng-select [items]="posiblesModelos" class="js-example-basic-multiple"
                                            bindValue="name" bindLabel="name" placeholder=""
                                            [(ngModel)]="modelo.modelo_comunicar">
                                        </ng-select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>



                    </div>

                    <br>
                    <button (click)="generar_audiencia()" [attr.disabled]="buttonGenerarAudiencia ? '' : null"
                        class="btn btn-success">Generar audiencia
                        ({{total_filtered}}
                        registros)</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br><br>