<app-feather-icons [icon]="'message-square'" (click)="toggleMessageBox()"></app-feather-icons>
<ul class="chat-dropdown onhover-show-div" [class.active]="openMessageBox">
  <li>
    <app-feather-icons [icon]="'message-square'"></app-feather-icons>
    <h6 class="f-18 mb-0">Message Box</h6>
  </li>
  <li>
    <div class="media"><img class="img-fluid rounded-circle me-3" src="assets/images/user/1.jpg" alt="">
      <div class="status-circle online"></div>
      <div class="media-body"><span>Erica Hughes</span>
        <p>Lorem Ipsum is simply dummy...</p>
      </div>
      <p class="f-12 font-success">58 mins ago</p>
    </div>
  </li>
  <li>
    <div class="media"><img class="img-fluid rounded-circle me-3" src="assets/images/user/2.jpg" alt="">
      <div class="status-circle online"></div>
      <div class="media-body"><span>Kori Thomas</span>
        <p>Lorem Ipsum is simply dummy...</p>
      </div>
      <p class="f-12 font-success">1 hr ago</p>
    </div>
  </li>
  <li>
    <div class="media"><img class="img-fluid rounded-circle me-3" src="assets/images/user/4.jpg" alt="">
      <div class="status-circle offline"></div>
      <div class="media-body"><span>Ain Chavez</span>
        <p>Lorem Ipsum is simply dummy...</p>
      </div>
      <p class="f-12 font-danger">32 mins ago</p>
    </div>
  </li>
  <li class="text-center"> <a class="btn btn-primary" href="/chat">View All</a></li>
</ul>