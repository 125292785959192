import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-preview-template-loyalty-whatsapp',
  templateUrl: './modal-preview-template-loyalty-whatsapp.component.html',
  styleUrls: ['./modal-preview-template-loyalty-whatsapp.component.scss']
})
export class ModalPreviewTemplateLoyaltyWhatsappComponent implements OnInit {

  @Input() public html_sent
  @Input() public html_button

  html;
  html_b;
  constructor() {
  }


  ngOnInit() {

    
    this.html = this.html_sent;
    this.html_b = this.html_button;

    console.log(this.html)
  }

}

