<h3>Leads por concesionario</h3>
<!-- <button (click)="showdata()">showdata</button> -->
<div style="width:100% !important;height:500px" *ngIf="data.length > 0">
    <ngx-charts-bar-vertical style="width:100% !important" [view]="view" [scheme]="colorScheme" [results]="data" [gradient]="gradient"
        [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="true" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [legendTitle]="'Concesionarios'" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel" [legendPosition]="'below'" (select)="onSelect($event)">
    </ngx-charts-bar-vertical>
</div>
<div *ngIf="data.length == 0">
    <h6>No se encontró data</h6>
</div>