<app-breadcrumb [title]="'Reportes de campaña'" [items]="['Campañas', 'Configuración', 'Reportes']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small class="d-block">En este módulo, se podrá configurar los reportes de las campañas.</small>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Tipo de envío</th>
                                <th class="text-center" scope="col">Fecha de creación</th>
                                <th class="text-center" scope="col">Creado por</th>
                                <th class="text-center" scope="col">Configurar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of templates">
                                <td>{{t.name}}</td>
                                <td class="text-center">{{t.created_time}}</td>
                                <td class="text-center">
                                    <span class="badge badge-secondary">{{t.created_by}}</span>
                                </td>
                                <td class="text-center">
                                    <a [routerLink]="'detail/'+t.id"><i class="fa fa-arrow-right"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <small class="mt-5 d-block"><b>*Los reportes le llegarán vía correo electrónico a la persona que realizó la campaña</b></small>
                </div>
            </div>
        </div>
    </div>
</div>