<div class="container">
    <div class="form-group">
        <h2>Historial de comunicaciones</h2>
    </div>
    <div class="comu">
        <h4>Whatsapp</h4>
        <div class="comu-detalle">
            <div *ngIf="history.whatsapp.length > 0">
                <p>Se encontraron un total de {{history.whatsapp.length}} mensajes.</p>
                <ul>
                    <li *ngFor="let h of history.whatsapp">
                        <div class="messages-whatsapp {{h.align}}">
                            {{h.body}}
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="history.whatsapp.length == 0">
                <p>No se encontró comunicación por whatsapp</p>
            </div>
        </div>
    </div>
    <div class="comu">
        <h4>Llamadas</h4>
        <div class="comu-detalle">
            <div *ngIf="history.calls.length > 0">
                <p>Se encontraron un total de {{history.calls.length}} llamadas.</p>
                <ul>
                    <li *ngFor="let h of history.calls" class="detail-call">
                        <div>
                            <p class="no-margin">Llamada realizada el día: {{h.llamada_inicio}}</p>
                            <small>Tuvo una duración de <b>{{h.diferencia}}</b> segundos.</small>
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="history.calls.length == 0">
                <p>No se encontraron llamadas realizadas</p>
            </div>
        </div>
    </div>
    <div class="comu">
        <h4>Correos</h4>
        <div class="comu-detalle">
            <div *ngIf="history.mailing.length > 0">
                <p>Se encontraron un total de {{history.mailing.length}} correos electrónicos enviados.</p>
                <ul>
                    <li *ngFor="let h of history.mailing" class="detail-mail">
                        <div>
                            <p class="no-margin">Asunto: {{h.body.mailing_asunto}}</p>
                            <p class="no-margin">Tipo: {{h.mailing_type}}</p>
                            <small>Mensaje: <b>{{h.body.message}}</b></small>
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="history.mailing.length == 0">
                <p>No se encontraron correos electrónicos enviados.</p>
            </div>
        </div>
    </div>


    <div class="form-group buttons">
        <button class="btn btn-secondary" (click)="onCancel()">Cerrar</button>
    </div>

</div>