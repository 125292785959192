<app-breadcrumb [title]="'Mail'" [items]="['Registros']" [active_item]="'Mail'"></app-breadcrumb>
<div class="go-back">
    <a (click)="go_back()"><i class="fa fa-arrow-circle-left"></i></a>
</div>
<div class="card">
    <!-- <div class="card-header py-4">
        <h5>Correo electrónico</h5>
    </div> -->
    <div class="card-body">
        <div class="theme-form row">
            <div class="formulario-edit" method="post">
                <div class="form-group col-12">
                    <label for="">Asunto:</label>
                    <input class="form-control" type="text" placeholder="" [(ngModel)]="payloadForm.mailing_asunto">
                </div>
                <div class="form-group col-12">
                    <label for="">Mensaje</label>
                    <textarea class="form-control" name="" [(ngModel)]="payloadForm.message" rows="5"></textarea>
                </div>
                <div class="form-group col-12 m-b-15 botones">
                    <button class="btn btn-primary" (click)="sendMail()">{{buttonEnviarText}}</button>
                    <button class="btn btn-secondary" (click)="go_back()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12" *ngIf="history.length > 0">
    <div class="email-right-aside bookmark-tabcontent">
        <div class="card email-body radius-left">
            <div class="ps-0">
                <div class="tab-content">
                    <div class="tab-pane fade active show" id="pills-created" role="tabpanel"
                        aria-labelledby="pills-created-tab">
                        <div class="card mb-0">
                            <div class="card-header d-flex">
                                <h5 class="mb-0">Historial de mailings enviados</h5>
                            </div>

                            <!-- <div class="card card-temporal">
                                <div class="card-body megaoptions-border-space-sm">
                                    <div class="row">
                                        <div class="col-4">Mailing</div>
                                        <div class="col-8">
                                            <b>Este asunto es de prueba</b>
                                            <p>Envio simple</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Contenido</div>
                                        <div class="col-8">Este mensaje tambien es de prueba :)</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Fecha de envío</div>
                                        <div class="col-8">2022-12-01 11:35:10</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Veces abierto</div>
                                        <div class="col-8">4</div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4">Detalle</div>
                                        <div class="col-8">
                                            <button class="btn btn-primary">Ver más</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card card-temporal">
                                <div class="card-body megaoptions-border-space-sm">
                                    <div class="row">
                                        <div class="col-4">Mailing</div>
                                        <div class="col-8">
                                            <b>Otro mail</b>
                                            <p>Envio simple</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Contenido</div>
                                        <div class="col-8">otro mensaje</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Fecha de envío</div>
                                        <div class="col-8">2022-12-01 15:25:17
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Veces abierto</div>
                                        <div class="col-8">1</div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4">Detalle</div>
                                        <div class="col-8">
                                            <button class="btn btn-primary">Ver más</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="card-body p-0 mt-3">
                                <div class="taskadd">
                                    <div class="container">
                                        <div class="table-responsive table-bordernone">
                                            <table class="table table-concesionarios">
                                                <thead>
                                                    <tr>
                                                        <th>Mailing</th>
                                                        <th>Contenido</th>
                                                        <th>Fecha de envío</th>
                                                        <th>Veces abierto</th>
                                                        <th>Detalle</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let h of history">
                                                        <td class="text-center">
                                                            <span class="th-hide">Mailing</span>
                                                            <h6 class="task_title_0">{{h.body.mailing_asunto}}</h6>
                                                            <p class="project_name_0">{{h.mailing_type}}</p>
                                                        </td>
                                                        <td class="text-center">
                                                            <span class="th-hide">Contenido</span>
                                                            <p class="task_desc_0">{{h.body.message}}</p>
                                                        </td>
                                                        <td class="text-center">
                                                            <span class="th-hide">Fecha de envío</span>
                                                            <p>{{h.created_time}}</p>
                                                        </td>
                                                        <td class="text-center">
                                                            <span class="th-hide">Veces abierto</span>
                                                            <p>{{h.mailing_open}}</p>
                                                        </td>
                                                        <td class="text-center">
                                                            <button (click)="verDetalle(h.id)"
                                                                class="btn btn-primary">Ver más</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>