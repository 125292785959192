import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {

  configurations: any

  constructor(
    private http: HttpClient
  ) {

  }

  async loadConfigurations() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const resultado = <any>await lastValueFrom(this.http.post(`${environment.url_api}Configuration/get_configurations`, data))
    if (resultado.success) {
      this.configurations = resultado.configuraciones
    }
  }

  getConfigurations() {
    return this.configurations
  }

}
