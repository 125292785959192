<h3 class="m-b-10">Comparativas</h3>

<div class="row m-t-10 m-b-20">
    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="comparativa">Seleccione criterio</label>
            <ng-select [(ngModel)]="filtros.opciones_comparativas" id="comparativa" bindLabel="name" bindValue="id"
                [items]="opciones_comparativas" class="js-example-basic-multiple">
            </ng-select>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="universo">Seleccione universo</label>
            <ng-select [(ngModel)]="filtros.opciones_universo" id="universo" bindLabel="name" bindValue="id"
                [items]="opciones_universo" class="js-example-basic-multiple">
            </ng-select>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="concesionario">Seleccione concesionario</label>
            <ng-select (change)="change_concesionario()" [(ngModel)]="filtros.concesionario" id="concesionario" bindLabel="name" bindValue="id"
                [items]="concesionarios" class="js-example-basic-multiple">
            </ng-select>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="seller">Seleccione vendedor</label>
            <ng-select [(ngModel)]="filtros.seller" id="seller" bindLabel="name" bindValue="id" [items]="sellers"
                class="js-example-basic-multiple">
            </ng-select>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <button (click)="make_graphic()" class="btn btn-primary">¡Buscar!</button>
        </div>
    </div>

</div>

<ngx-charts-bar-horizontal [view]="view" [scheme]="colorScheme" [results]="single" [gradient]="gradient"
    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)"
    (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
</ngx-charts-bar-horizontal>