import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

const Swal = require('sweetalert2')

@Component({
  selector: 'app-modal-create-user',
  templateUrl: './modal-create-user.component.html',
  styleUrls: ['./modal-create-user.component.scss']
})
export class ModalCreateUserComponent implements OnInit {

  jwt
  is_loading = false

  lead_to_create = {
    'nombres': '',
    'apellidos': '',
    'correo': '',
    'password': '',
    'tipo_de_usuario': ''
  };

  tipo_de_usuarios = []

  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/add_usuario_init`, data))
    if (response.success) {
      this.tipo_de_usuarios = response.user_types
    }
  }

  validate() {
    if (!this.lead_to_create.nombres) {
      Swal.fire('Ingrese nombres', '', 'error')
      return false
    }
    if (!this.lead_to_create.apellidos) {
      Swal.fire('Ingrese apellidos', '', 'error')
      return false
    }

    if (!this.validar_correo(this.lead_to_create.correo)) {
      Swal.fire('Ingrese correo electrónico válido', '', 'error')
      return false
    }

    if (!this.lead_to_create.password) {
      Swal.fire('Ingrese contraseña del usuario nuevo', '', 'error')
      return false
    }
    return true
  }
  validar_correo = (correo) => {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (correo) {
      if (cadena.test(correo)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  async onSubmit() {
    if (this.validate()) {
      const data = new FormData()
      data.append('jwt', this.jwt)
      data.append('user', JSON.stringify(this.lead_to_create))
      this.is_loading = true
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/add_usuario`, data))
      this.is_loading = false
      if (response.success) {
        Swal.fire(response.message, '', 'success')
        this.activeModalService.close(response);
      } else {
        Swal.fire(response.message, '', 'error')
      }
    }
  }
  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }

}
