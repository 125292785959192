import { Component, OnInit } from '@angular/core'
import { AudienceService } from '../shared/services/audience.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalSelectWhatsappTemplateComponent } from '../modal-select-whatsapp-template/modal-select-whatsapp-template.component'
import { ModalPreviewTemplateLoyaltyWhatsappComponent } from '../modal-preview-template-loyalty-whatsapp/modal-preview-template-loyalty-whatsapp.component'
import { DomSanitizer } from '@angular/platform-browser';
import { CampaignService } from '../shared/services/campaign.service'
import { EncuestaService } from '../shared/services/encuesta.service'
import { Router } from '@angular/router';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-loyalty-campaigns-whatsapp-add',
  templateUrl: './loyalty-campaigns-whatsapp-add.component.html',
  styleUrls: ['./loyalty-campaigns-whatsapp-add.component.scss']
})
export class LoyaltyCampaignsWhatsappAddComponent implements OnInit {

  prueba: boolean = false; 

  campaign = {
    audience_id: null,
    name: null,
    template: null,
    asunto: '',
    type: 0,
    encuesta_id: null,
    fecha_lanzamiento: null,
    fecha_fin: null,
    prueba: 0
  }

  

  type_of_envio = [
    {
      id: 1,
      name: 'Remarketing'
    },
    {
      id: 2,
      name: 'Recompra'
    },
    {
      id: 3,
      name: 'Encuesta'
    },
    {
      id: 4,
      name: 'Referidos'
    },
    {
      id: 5,
      name: 'Bienvenida'
    },
    {
      id: 6,
      name: 'Planning'
    },
    {
      id: 7,
      name: 'Invitaciones'
    }
  ]

  list_of_encuestas = []

  test_audience = []
  person_selected = null


  template_preview: any

  merges = []
  type_discriminacion_merges = []

  typeOfDiscriminacion


  list_of_models = []
  list_of_audiences = []

  constructor(
    private audienceService: AudienceService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private campaignService: CampaignService,
    private encuestaService: EncuestaService,
    public router: Router
  ) {
    this.typeOfDiscriminacion = [
      {
        id: 1,
        name: 'Texto / URL'
      },
      {
        id: 2,
        name: 'Mostrar / Ocultar'
      }
    ]
  }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.loadAudiences()
    this.loadTiposDeDiscriminacionMerge()
    this.loadEncuestas()
  }

  minDate(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = this.padNumber(now.getMonth() + 1);
    const day = this.padNumber(now.getDate());
    const hours = this.padNumber(now.getHours());
    const minutes = this.padNumber(now.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  async loadEncuestas() {
    const res = await this.encuestaService.get_encuestas_active()
    if (res.success) {
      this.list_of_encuestas = res.encuestas
    }
  }

  async loadTiposDeDiscriminacionMerge() {
    const res = <any>await this.audienceService.get_tipos_de_discriminacion_merge(this.campaign)
    if (res.success) {
      this.type_discriminacion_merges = res.res
      console.log(this.type_discriminacion_merges)
    }
  }

  async loadAudiences() {
    const res = <any>await this.audienceService.get_all_audiences_whatsapp_disponibles()
    if (res.success) {
      this.list_of_audiences = res.audiences
    }
  }

  select_template() {
    if (!this.campaign.audience_id) {
      Swal.fire('Debes seleccionar la audiencia primero', '', 'warning')
    } else {
      const dialogRef = this.modalService.open(ModalSelectWhatsappTemplateComponent, {
        size: 'xl'
      })

      dialogRef.result.then(
        (data) => {
          if (data.success) {
            this.campaign.template = data.template
            this.template_preview = data.template.html

            this.generate_merges()
            console.log(this.campaign)
          }
        },
        (reason) => {
          console.log('Modal cerrado con motivo:', reason)
        }
      )
    }

  }

  async generate_merges() {
    const merges = this.extractMergeVariables(this.campaign.template.html)
    console.log('merges', merges)
    console.log('list_of_models :', this.list_of_models)
    this.merges = []
    if (merges) {
      for (let index = 0; index < merges.length; index++) {
        const merge = merges[index];
        this.merges.push({
          'name': merge,
          'type': 0,
          'subtype': 1,
          'validate': false,
          'items': []
        })
      }


      for (let i = 0; i < this.merges.length; i++) {
        if (this.list_of_models) {
          for (let j = 0; j < this.list_of_models.length; j++) {
            const modelo_to_add = {
              nombre: this.list_of_models[j],
              contenido: null,
              show: true
            }
            this.merges[i].items.push(modelo_to_add)
          }
        }
      }


      if (merges.length > 0) {
        await this.loadTiposDeDiscriminacionMerge()
        this.preSetearValues()
      }


      if (merges.length == 1) {
        Swal.fire(`Se encontró ${merges.length} campo dinámico en la plantilla.`, '', 'info')
      } else if (merges.length > 1) {
        Swal.fire(`Se encontró ${merges.length} campos dinámicos en la plantilla.`, '', 'info')
      }


    }
  }

  preSetearValues() {
    if (this.campaign.type == 5) {
      // Cuando es bienvenida
      console.log('BIENVENIDA MERGES', this.merges)
      for (let i = 0; i < this.merges.length; i++) {

        switch (this.merges[i].name) {
          case '#MERGE_bienvenida_modelo_banner':
            this.merges[i].type = 5
            break;
          case '#MERGE_bienvenida_modelo_eleccion':
            this.merges[i].type = 9
            break;
          case '#MERGE_bienvenida_has_sensing':
            this.merges[i].type = 10
            break;
          case '#MERGE_token':
            this.merges[i].type = 3
            break;
          case '#MERGE_bienvenida_vendedor':
            this.merges[i].type = 6
            break;
          case '#MERGE_bienvenida_jefepv':
            this.merges[i].type = 7
            break;
          case '#MERGE_bienvenida_dealer':
            this.merges[i].type = 8
            break;

          case '#MERGE_LINK':
            this.merges[i].type = 12
            break;
              
          case '#MERGE_link_dinamico':
            this.merges[i].type = 13
          break;

          case '#MERGE_texto_dinamico':
            this.merges[i].type = 15
          break;
        }
      }

    } else {
      for (let i = 0; i < this.merges.length; i++) {


        switch (this.merges[i].name) {

          case '#MERGE_nombres':
            this.merges[i].type = 1
            break;
          case '#MERGE_modelo':
          case '#MERGE_imagen':

            this.merges[i].type = 2
            break;
          case '#MERGE_id_encuesta':
            this.merges[i].type = 4
            break;
          case '#MERGE_encuesta_id':
            this.merges[i].type = 4
            break;
          case '#MERGE_token':
            this.merges[i].type = 3
            break;

          case '#MERGE_dealer_mailing':
            this.merges[i].type = 11
            break;

          case '#MERGE_LINK':
          case '#MERGE_link':
            this.merges[i].type = 12
          break;


          case '#MERGE_link_dinamico':
          case '#MERGE_link_black_list':
          case '#MERGE_tc':

            this.merges[i].type = 13
          break;

          case '#MERGE_texto_dinamico':
          case '#MERGE_Fecha_Vigencia':
            this.merges[i].type = 15
          break;

        }
      }
    }
    this.pre_validate_merges()
  }

  add_campaign() {
    console.log(this.campaign)
    const res = this.extractMergeVariables(this.campaign.template.html)
    console.log('MERGES : ', res)
  }

  extractMergeVariables(htmlContent: string): string[] {
    const mergeRegex = /#MERGE_\w+/g;
    const matches = htmlContent.match(mergeRegex);
    return matches ? Array.from(new Set(matches)) : [];
  }

  async get_audience(audiencia) {
    if (audiencia.id) {
      const res = <any>await this.audienceService.get_opciones_por_audiencia_whatsapp(audiencia.id)
      if (res.success) {
        this.campaign.template = null
        this.merges = []
        this.list_of_models = res.res
        this.test_audience = res.persons

        for (let i = 0; i < this.test_audience.length; i++) {
          var new_name
          if (this.test_audience[i].modelo) {
            new_name = `${this.test_audience[i].nombres}`
          } else {
            new_name = `${this.test_audience[i].nombres}`
          }
          this.test_audience[i].nombres = new_name
        }
      }
    }
  }

  async addCampaign() {


    console.log('CAMPAIGN', this.campaign)
    console.log('test_audience', this.test_audience)
    console.log('MERGES', this.merges)
    const validate_merges = this.pre_validate_merges(true)

    if (!this.campaign.name) {
      Swal.fire('Debe escribir el nombre de la campaña', '', 'error')
      return 0
    }

    if (!this.campaign.audience_id) {
      Swal.fire('Debe seleccionar la audiencia', '', 'error')
      return 0
    }

    if (!this.campaign.fecha_lanzamiento) {
      Swal.fire('Debe seleccionar una fecha de lanzamiento para la campaña', '', 'error')
      return 0
    }

    if (!this.campaign.fecha_fin) {
      Swal.fire('Debe seleccionar una fecha culminación para la campaña', '', 'error')
      return 0
    }

    if (!this.campaign.type) {
      Swal.fire('Debe seleccionar el tipo de envio', '', 'error')
      return 0
    }

    if (validate_merges) {
      // Swal.fire('Success - Debería grabar', '', 'success')

      Swal.fire({
        title: "¿Estás seguro?",
        html: `Vas a generar la campaña y enviarla`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Crear",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.showLoading()
          this.mandar_campaign()
        }
      });
    }
  }

  onPruebaChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement) {
      this.campaign.prueba = 1;
    } else {
      this.campaign.prueba = 0;
    }

  }

  async mandar_campaign() {
    const res = <any>await this.campaignService.makeCampaignWhatsapp({
      campaign: this.campaign,
      merges: this.merges
    })

    if (res.success) {
      Swal.fire(res.message, '', 'success')
      this.resetFields()
      this.router.navigate(['loyalty-comunication/campaigns/from-whatsapp']);
    } else {
      Swal.fire(res.message, '', 'error')
    }
  }

  resetFields() {
    this.campaign = {
      audience_id: null,
      name: null,
      template: null,
      asunto: '',
      type: 0,
      encuesta_id: null,
      fecha_lanzamiento: null,
      fecha_fin: null,
      prueba: 0
    }
    this.merges = []
    this.person_selected = null
    this.type_discriminacion_merges = []
    this.list_of_models = []
    this.list_of_audiences = []
  }

  pre_validate_merges(mostrarAlerta = false) {


    for (let i = 0; i < this.merges.length; i++) {
      if (this.merges[i].type == 1) {
        // NOMBRE
        this.merges[i].validate = true
      } else if (this.merges[i].type == 2) {
        // MODELO

        console.log(this.merges[i].name);

        

        if (this.merges[i].subtype == 1) {
          let validate__tmp = true
          for (let j = 0; j < this.merges[i].items.length; j++) {

            if (this.merges[i].name == "#MERGE_modelo") {
              if (!this.merges[i].items[j].contenido) {
                this.merges[i].items[j].contenido = this.merges[i].items[j].nombre.modelo;
              }
            }
            

            var contenido = this.merges[i].items[j].contenido;

            if (!contenido) {
              if (mostrarAlerta) {
                Swal.fire(`Error en merge : ${this.merges[i].name}`, '', 'error')
              }
              this.merges[i].validate = false
              validate__tmp = false
              if (mostrarAlerta) {
                return false
              }
            }
          }

          this.merges[i].validate = validate__tmp

        } else if (this.merges[i].subtype == 2) {
          this.merges[i].validate = true
        }
      } else if (this.merges[i].type == 0) {
        // NO SELECCIONO NADA
        if (mostrarAlerta) {
          Swal.fire(`Error en merge : ${this.merges[i].name}`, '', 'error')
        }

        if (mostrarAlerta) {
          return false
        }

      } else if (this.merges[i].type == 3) {
        // TOKEN
        this.merges[i].validate = true
      } else if (this.merges[i].type == 4) {

        console.log("hola");
        // ENCUESTA
        let validate__tmp = true

        if (this.campaign.type != 3) {
          validate__tmp = false
          if (mostrarAlerta) {
            Swal.fire(`Error en merge : ${this.merges[i].name}`, 'Debe seleccionar que el tipo de envio es una encuesta', 'error')
            return false
          }
        }

        console.log("Pasó el punto 1");


        if (!this.campaign.encuesta_id) {
          validate__tmp = false
          if (mostrarAlerta) {
            Swal.fire(`Error en merge : ${this.merges[i].name}`, 'Debe seleccionar la encuesta', 'error')
            return false
          }
        }

        this.merges[i].validate = true
      }  else if (this.merges[i].type == 12) {
        this.merges[i].validate = true

      }  else if (this.merges[i].type == 13) {

        var contenido = this.merges[i].link;

        if (this.validateLink(contenido)) {
          this.merges[i].validate = true; 
        } else {
          this.merges[i].validate = false; 

        }

      } else if (this.merges[i].type == 15) {

        var contenido = this.merges[i].texto;

        if (contenido) {
          this.merges[i].validate = true; 
        } else {
          this.merges[i].validate = false; 

        }

      } else {
        // El resto
        this.merges[i].validate = true
      } 
    }

    console.log(this.merges);

    return true
  }


  
  validateLink(link: string): boolean {
    const regex = /^(http:\/\/|https:\/\/)/;
    return regex.test(link);
  }
  


  select_person(person) {
    console.log(person)
    this.changePreview(person)
    let person_info = JSON.stringify(person)
    Swal.fire({
      title: "<strong>Persona elegida:</strong>",
      icon: "info",
      html: `
        <pre>${person_info}</pre>
      `,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false
    });
  }

  ver_preview() {
    console.log(this.person_selected)
    if (this.person_selected !== null) {
      var person = {}
      for (let i = 0; i < this.test_audience.length; i++) {
        const persona = this.test_audience[i];
        if (persona.id === this.person_selected) {
          person = persona
          break
        }
      }

  
      var html_to_send = this.changePreview(person)

      html_to_send = html_to_send.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
      html_to_send = html_to_send.replace(/\n/g, '<br>');

      var html_button = '';

      if (this.campaign.template.type == "reply") {

        const optionsArray = JSON.parse(this.campaign.template.options);

        if (optionsArray) {
          optionsArray.forEach((option, index) => {
              html_button += `
                <div class="bg-white mt-2 rounded p-2 text-primary">${option}</div>
              `;
          });
        }
        
      }


      const dialogRef = this.modalService.open(ModalPreviewTemplateLoyaltyWhatsappComponent, {
        size: 'xl',
        windowClass: 'modal-xxl'
      })

      dialogRef.componentInstance.html_sent = html_to_send
      dialogRef.componentInstance.html_button = html_button

      dialogRef.result.then(
        (data) => {
          console.log(data)
        },
        (reason) => {
          console.log('Modal cerrado con motivo:', reason)
        }
      )
    } else {
      Swal.fire('Debe elegir una persona para generar el preview del mailing', '', 'error')
    }
  }


  changePreview(person) {
    this.template_preview = this.campaign.template.html

    for (let i = 0; i < this.merges.length; i++) {
      switch (this.merges[i].type) {
        case 1:
          // NOMBRE
          this.template_preview = this.template_preview.replace(this.merges[i].name, person.nombres)
          break
        case 2:
          // MODELO
          console.log('changePreview case 2')
          if (this.merges[i].subtype == 1) {
            var contenido = this.getMergeValueFromPersona(person, this.merges[i], false)
            this.template_preview = this.template_preview.replace(this.merges[i].name, contenido)
          } else if (this.merges[i].subtype == 2) {
            var contenido = this.getMergeValueFromPersona(person, this.merges[i], true)
            this.template_preview = this.template_preview.replace(this.merges[i].name, contenido)
          }
          break
        case 3:
          // TOKEN
          this.template_preview = this.template_preview.replace(this.merges[i].name, 'TOKEN_GENERADO')
          break
        case 5:
          this.template_preview = this.template_preview.replace(this.merges[i].name, person.bienvenida_modelo_banner)
          break;
        case 6:
          this.template_preview = this.template_preview.replace(this.merges[i].name, person.bienvenida_vendedor)
          break;
        case 7:
          this.template_preview = this.template_preview.replace(this.merges[i].name, person.bienvenida_jefepv)
          break;
        case 8:
          this.template_preview = this.template_preview.replace(this.merges[i].name, person.bienvenida_dealer)
          break;
        case 9:
          this.template_preview = this.template_preview.replace(this.merges[i].name, person.bienvenida_modelo_eleccion)
          break;
        case 10:
          this.template_preview = this.template_preview.replace(this.merges[i].name, person.bienvenida_has_sensing)
          break;
        case 11:

          if (person.concesionario_id == 3) {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), 'INDEPENDENCIA');
          } else if (person.concesionario_id == 7) {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), 'AREQUIPA');
          } else if (person.concesionario_id == 8) {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), 'HUANCAYO');
          } else if (person.concesionario_id == 9) {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), 'PIURA');
          } else if (person.concesionario_id == 10) {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), 'TRUJILLO');
          } else if (person.concesionario_id == 13) {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), 'LA MOLINA');
          } else if (person.concesionario_id == 14) {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), 'SAN ISIDRO');
          } else if (person.concesionario_id == 18) {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), 'SAN MIGUEL');
          } else {
            this.template_preview = this.template_preview.replace(new RegExp(this.merges[i].name, 'g'), '');
          }


          break;
          case 12:

            var enlace = "https://autos.honda.com.pe/";

              switch (this.campaign.type) {
                case 3:
                  if (this.campaign.encuesta_id) {
                    enlace = "https://autos.honda.com.pe/encuesta/"+this.campaign.encuesta_id+"/"+person.token+"/";
                  }
                break;
                case 1:
                  enlace = "https://autos.honda.com.pe/sms/?sms_param="+person.token;
                break;
                case 2:
                  enlace = "https://autos.honda.com.pe/recompra-honda/?sms_param="+person.token;
                break;
                case 8:
                  enlace = "https://autos.honda.com.pe/sms/?sms_param="+person.token;
                break;
              }
              
            this.template_preview = this.template_preview.replace(this.merges[i].name, enlace)
          break;

          case 13: 

          var link = "";
          if (this.merges[i].link) {
            link = this.merges[i].link;
          }

          this.template_preview = this.template_preview.replace(this.merges[i].name, link)
          break;

          case 15: 
            var texto = "";
            if (this.merges[i].texto) {
              texto = this.merges[i].texto;
            }

            this.template_preview = this.template_preview.replace(this.merges[i].name, texto)
          break;
      }
    }


    return this.template_preview

  }


  getMergeValueFromPersona(persona, merge, showAndHide = false) {
    for (let i = 0; i < merge.items.length; i++) {
      const item = merge.items[i];
      if (item.nombre.modelo == persona.modelo_comunicacion) {
        if (showAndHide) {
          if (item.show) {
            return ""
          } else {
            return "display: none !important;"
          }
        } else {
          return item.contenido
        }
      }
    }
  }


}
