<app-breadcrumb [title]="'Reportes Antiguos'" [items]="['Campañas', 'Reportes Antiguos']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>Aca se muestran todos los reportes que se generaron antiguamente antes de la existencia
                            de esta herramienta.</small>
                    </div>
                </div>
                <div class="card-body">

                    <ngb-accordion *ngFor="let reporte of reportes_antiguos">
                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                                <span>{{reporte.name}}</span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div *ngIf="reporte.reportes.length > 0">
                                    <ul>
                                        <li *ngFor="let excel of reporte.reportes">
                                            <a target="_blank" href="{{excel.link}}">{{excel.name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>

                </div>
            </div>
        </div>
    </div>
</div>