import { Component, OnInit } from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';


const Swal = require('sweetalert2')


@Component({
  selector: 'app-dashboard-sesion',
  templateUrl: './dashboard-sesion.component.html',
  styleUrls: ['./dashboard-sesion.component.scss']
})
export class DashboardSesionComponent implements OnInit {


  filtros = {
    'fecha' : ''
  }
  usuarios = []
  private jwt

  // get_inicios_de_sesion
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private http: HttpClient
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }

  async buscar(){
    const data = new FormData()
    data.append('date', this.filtros.fecha)
    data.append('jwt', this.jwt)
    Swal.showLoading()
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_inicios_de_sesion`, data))
    Swal.close()
    console.log('response' , response)
    if(response.success){
      this.usuarios = response.data
      this.filtros.fecha = response.fecha
    }
  }

  async ngOnInit(){
    this.buscar()
    // const data = new FormData()
    // data.append('date', this.filtros.fecha)
    // data.append('jwt', this.jwt)
    // const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_inicios_de_sesion`, data))
    // if(response.success){
    //   this.usuarios = response.data
    // }
    // console.log(response)
  }

}
