<app-breadcrumb [title]="'Whatsapp'" [items]="['Registros']" [active_item]="'Whatsapp'"></app-breadcrumb>
<div class="go-back">
    <a (click)="go_back()"><i class="fa fa-arrow-circle-left"></i></a>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col call-chat-body">
            <div class="card">
                <div class="card-body p-0">
                    <div class="row chat-box">
                        <!-- Chat right side start-->
                        <div class="col pe-0 chat-right-aside">
                            <!-- chat start-->
                            <div class="chat">
                                <!-- chat-header start-->
                                <div class="chat-header clearfix">
                                    <img class="rounded-circle" [src]="lead?.moto_imagen" alt="">
                                    <div class="about">
                                        <div class="name">{{lead?.nombres}} <span class="font-primary f-12"
                                                *ngIf="chatUser?.typing">Typing...</span></div>
                                        <div class="status digits">Modelo: {{lead?.modelo}}</div>
                                    </div>
                                    <!-- <ul class="list-inline float-start float-sm-end chat-menu-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"><i
                                                    class="icon-search"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"><i
                                                    class="icon-clip"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"><i
                                                    class="icon-headphone-alt"></i></a>
                                        </li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"><i
                                                    class="icon-video-camera"></i></a></li>
                                        <li class="list-inline-item toogle-bar" (click)="mobileMenu()"><a
                                                href="javascript:void(0)"><i class="icon-menu"></i></a>
                                        </li>
                                    </ul> -->
                                </div>
                                <!-- chat-header end-->
                                <div class="chat-history chat-msg-box custom-scrollbar">
                                    <ul>
                                        <li *ngFor="let chat of chats?.message"
                                            [ngClass]="{'clearfix': chat.sender == profile.id }">
                                            <div class="message my-message mb-0"
                                                *ngIf="chat.sender != profile.id; else myChat">
                                                <img class="rounded-circle float-start chat-user-img img-30"
                                                    [src]="chatUser?.profile" alt="">
                                                <div class="message-data text-end">
                                                    <span class="message-data-time">{{chat?.time}}</span>
                                                </div> {{chat?.text}}
                                            </div>
                                            <ng-template #myChat>
                                                <div class="message other-message pull-right">
                                                    <img class="rounded-circle float-end chat-user-img img-30"
                                                        [src]="profile?.profile" alt="">
                                                    <div class="message-data">
                                                        <span class="message-data-time">{{chat?.time}}</span>
                                                    </div> {{chat?.text}}
                                                </div>
                                            </ng-template>
                                        </li>
                                        <!-- <ng-container *ngIf="!chats?.message.length">
                                            <div class="image-not-found">
                                                <div class="d-block start-conversion">
                                                    <img src="assets/images/start-conversion.jpg" class="img-fluid">
                                                </div>
                                            </div>
                                        </ng-container> -->
                                    </ul>
                                </div>
                                <!-- end chat-history-->
                                <form #chatForm="ngForm" (ngSubmit)="sendMessage(chatForm)">
                                    <div class="chat-message clearfix">
                                        <div class="row">
                                            <div class="col-xl-12 d-flex">
                                                <!-- <div class="smiley-box bg-primary">
                                                    <div class="picker">
                                                        <img src="assets/images/smiley.png" alt=""
                                                            (click)=toggleEmojiPicker()>
                                                    </div>
                                                </div> -->
                                                <div class="input-group text-box" *ngIf="disponible_mandarwhatsapp">
                                                    <input class="form-control input-txt-bx"
                                                        [class.border-danger]="error" [(ngModel)]="chatText"
                                                        id="message-to-send" type="text" name="message"
                                                        placeholder="Escribe un mensaje......">
                                                    <ng-container *ngIf="showEmojiPicker">
                                                        <emoji-mart (emojiClick)="addEmoji($event)" set="apple"
                                                            useButton="true"></emoji-mart>
                                                    </ng-container>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-primary" (click)="sendMessage(chatForm)"
                                                            type="button">Enviar</button>
                                                    </div>
                                                </div>

                                                <div class="input-group text-box" *ngIf="!disponible_mandarwhatsapp">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-primary" (click)="iniciarConversacion(chatForm)"
                                                            type="button">Iniciar conversación</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>


                                <!-- end chat-message-->
                                <!-- chat end-->
                                <!-- Chat right side ends-->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->