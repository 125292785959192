import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AudienceService } from '../shared/services/audience.service';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CampusService } from 'src/app/shared/services/campus.service';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-loyalty-campaigns-audience-add-from-excel',
  templateUrl: './loyalty-campaigns-audience-add-from-excel.component.html',
  styleUrls: ['./loyalty-campaigns-audience-add-from-excel.component.scss']
})
export class LoyaltyCampaignsAudienceAddFromExcelComponent implements OnInit {

  files: File[] = [];
  audiencia
  audienciaType


  title = 'crm';
  file?: File;
  timeout: any;
  uploadProgress: number = 0;
  uploadSub?: Subscription;
  rows = [];
  rows_to_show = []
  cabeceras = []
  audiencia_type



  columns = [{ prop: 'nombres', name: 'nombres' },
  { prop: 'celular', name: 'celular' },
  { prop: 'dni', name: 'dni' },
  { prop: 'correo_electronico', name: 'correo_electronico' },
  { prop: 'fecha', name: 'fecha' },
  { prop: 'modelo', name: 'modelo' },
  { prop: 'version', name: 'version' },
  { prop: 'modelo_comunicacion', name: 'modelo_comunicacion' },
  { prop: 'fuente', name: 'fuente' }];


  constructor(
    private audienceService: AudienceService,
    private http: HttpClient,
    public router: Router,
    private route: ActivatedRoute,
    private campusService: CampusService

  ) {

    this.audiencia_type = ""

    this.audiencia = {
      name: '',
      type: '',
      subtype: 'Regular'
    }

    this.audienciaType = [];
    
    /*
     this.audienciaType = [
      'Remarketing',
      'Recompra',
      'Encuesta - Cotizaciones',
      'Encuesta - Entrega',
      'Referidos',
      'Bienvenida',
      'Encuesta - Planning',
      'Invitaciones',
      'Encuesta - Regalo',
    ] */
  }

  ngOnInit(): void {



    this.route.url.subscribe(segments => {

      const currentPath = segments.join('/');


      if (currentPath.includes('audiencias-whatsapp')) {

        this.audiencia_type = 'Whatsapp'
  
      } else if (currentPath.includes('sms')) {

        this.audiencia_type = 'SMS';

      } else if (currentPath.includes('audiencias')) {
  
        this.audiencia_type = 'Email'
      }

    })

    this.traer_tipos_audiencia();
    this.traer_audiencia_dinamica_campos();

  }

  dealers: []

  async traer_dealers(){
    var res = <any> await this.campusService.get_campus();
    if (res.success) {
      this.dealers  = res.list_of_campus; 
    }
  }

  async traer_tipos_audiencia(){
    const audiencia_res = <any>await this.audienceService.get_all_audiences_type();
    this.audienciaType = audiencia_res.audiences;
  }



  replaceFile() {
    this.files.splice(0, 1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  cabeceras_generica = []


  generate_preview() {

    for (let indice in this.rows[0]) {
      this.cabeceras.push(indice)
    }

  
  
    var maxValue = 10
    for (let index = 0; index < this.rows.length; index++) {
      if (this.rows_to_show.length < maxValue) {
        this.rows_to_show.push(this.rows[index])
      } else {
        break
      }
    }

    this.mostrar_valores_dinamicos();


  }

  async generate_audience() {


    if (!this.audiencia.name) {
      Swal.fire('Ingrese nombre de la audiencia', '', 'error')
      return false
    }

    if (!this.audiencia.type) {
      Swal.fire('Seleccione el tipo de la audiencia', '', 'error')
      return false
    }

    if (this.rows.length < 1) {
      Swal.fire('Debe ingresar un excel con contenido', '', 'error')
      return false
    }


    Swal.fire({
      title: '¿Estás seguro?',
      text: `Crearás una audiencia con ${this.rows.length} personas.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#000',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.generarAudiencia();
      }
    })


  }



  async generarAudiencia() {

    const to_add = {
      'data': this.rows,
      'audiencia': this.audiencia,
      'cabeceras_dinamicas': this.cabeceras_generica,
      'dealers_dinamicos': this.dealers_seleccionados
    }

    let res: any; 

    if (this.audiencia_type == 'Email') {
       res = <any>await this.audienceService.generate_and_uploadAudienceFromExcel(to_add)
    }

    if (this.audiencia_type == 'Whatsapp') {
       res = <any>await this.audienceService.generate_and_uploadAudienceFromExcelWhatsapp(to_add)
    }

    if (this.audiencia_type == 'SMS') {
       res = <any>await this.audienceService.generate_and_uploadAudienceFromExcelSMS(to_add)
    }

    if (res.success) {
      this.audiencia = {
        name: '',
        type: ''
      }

      Swal.fire(res.message, '', 'success')
      this.reset()

      if(this.audiencia_type == 'Email'){

        this.router.navigate(['loyalty-comunication/audiencias']);

      } 
      
      if(this.audiencia_type == 'Whatsapp'){
        this.router.navigate(['loyalty-comunication/audiencias-whatsapp']);
      }

      if(this.audiencia_type == 'SMS'){
        this.router.navigate(['loyalty-comunication/audiencias-sms']);
      }

    } else {
      Swal.fire(res.message, '', 'error')
    }
  }



  reset() {
    this.file = undefined;
    this.uploadProgress = 0;
    this.uploadSub = undefined;
    this.rows = [];
    this.rows_to_show = []
    this.cabeceras = []
  }

  onPage(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  onSelect(event: any) {
    if (!this.audiencia.type) {
      Swal.fire('Debes elegir el tipo de audiencia primero', '', 'error')
    } else {
      this.file = event.addedFiles[0];

      if (this.file) {
        const formData = new FormData();

        formData.append("fileToUpload", this.file)
        formData.append("tipo_audiencia", this.audiencia.type)
        formData.append("subtipo_audiencia", this.audiencia.subtype)


        Swal.showLoading()
        const upload$ = this.http.post(`${environment.url_api}Audience/process_excel`, formData, {
          reportProgress: true,
          observe: 'events'
        })

        this.uploadSub = upload$.subscribe((event: any) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.uploadProgress = 0;
              break;

            case HttpEventType.UploadProgress:
              this.uploadProgress = Math.round(100 * (event.loaded / event.total));
              break;

            case HttpEventType.Response:
              const body: any = event.body

              if (!body.success) {
                Swal.fire('Error', body.message, 'error')
                this.reset();
              } else {
                Swal.fire('¡Listo!', `Se encontraron: ${body.data.length} registros`, 'success')
                this.rows = body.data
                this.generate_preview()
              }
              break;

            default:
          }
        })
      }
    }
  }


  mostrar_valores_dinamicos() {
    if (this.audiencia.type == "Genérico") {
      this.cabeceras_generica = this.cabeceras.map((nombre) => {
        let valor;

        const nombreMayuscula = nombre.toUpperCase();

        switch (nombreMayuscula) {
          case 'CONCESIONARIO':
          case 'DEALER':

            valor = 'concesionario_nombre';

            this.habilito_dealer_generico = true;
            this.traer_dealers();

            console.log(this.rows_to_show);
            this.dealer_genericos = Array.from(new Set(this.rows_to_show.map(row => row[nombre])));

            break;
          case 'NOMBRES':
            valor = 'nombres';
            break;
          case 'CELULAR':
            valor = 'celular';
            break;
          case 'CORREO':
            valor = 'correo_electronico';
            break;
          default:
            valor = null;
            break;
        }
        
        // Set the selectedOptions array with default values
        if (valor) {
          const index = this.cabeceras.indexOf(nombre);
          this.selectedOptions[index] = valor;
        }
        
        return {
          cabecera: nombre,
          valor: valor,
          validar: false
        };
      });
      
      console.log(this.selectedOptions);

    }
  }

  availableOptions: { name: string, texto: string }[] = [];

  async traer_audiencia_dinamica_campos(){
    const dinamica_campos = <any>await this.audienceService.traer_audiencia_dinamica_campos();
    this.availableOptions = dinamica_campos.opciones;  
  }


  selectedOptions: string[] = [];
  validations: boolean[] = new Array(this.cabeceras.length).fill(false);

   getColumnName(index: number): string {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let columnName = '';

    while (index >= 0) {
        columnName = letters[index % 26] + columnName;
        index = Math.floor(index / 26) - 1;
    }

    return columnName;
}

  getAvailableOptions(currentIndex: number): { name: string, texto: string }[] {
    const selected = new Set(this.selectedOptions);
    return this.availableOptions.filter(option => 
      !selected.has(option.name) || this.selectedOptions[currentIndex] === option.name
    );
  }

  onSelectOption(cabecera, event: any, index: number) {

    const selectedValue = event.target.value;
    
    if(selectedValue == "concesionario_nombre" ){
      this.habilito_dealer_generico = true;
      this.traer_dealers();
      this.dealer_genericos = Array.from(new Set(this.rows_to_show.map(row => row[cabecera])));
    } 
    
    if (selectedValue) {
      const prevValue = this.selectedOptions[index];
      if (prevValue !== selectedValue) {
        this.selectedOptions.forEach((option, i) => {
          if (option === prevValue && i !== index) {
            this.selectedOptions[i] = null;
            this.cabeceras_generica[i].valor = null;
          }
        });
      }
      
      this.selectedOptions[index] = selectedValue;
      this.cabeceras_generica[index].valor = selectedValue;
    } else {
      this.selectedOptions[index] = null;
      this.cabeceras_generica[index].valor = null;
    }

    if (!this.selectedOptions.includes("concesionario_nombre")) {
      this.habilito_dealer_generico = false;
      this.dealer_genericos = [];
      this.dealers_seleccionados = [];
  }
}


  onToggleValidation(index: number) {
    this.validations[index] = !this.validations[index];
    this.cabeceras_generica[index].validar = this.validations[index];
  }

  dealer_genericos = [];
  habilito_dealer_generico = false;
  dealers_seleccionados: { generico: string, dealer_id: string }[] = [];

  onSelectChange(generico: string, dealer_id: string) {
    const index = this.dealers_seleccionados.findIndex(pair => pair.generico === generico);
    if (index > -1) {
      this.dealers_seleccionados[index].dealer_id = dealer_id;
    } else {
      this.dealers_seleccionados.push({ generico, dealer_id });
    }
  }
  
  

}