<div class="container">
    <div class="form-group">
        <h2>Crear lead</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombres">Nombres</label>
                <input type="text" class="form-control" id="nombres" [(ngModel)]="lead_to_create.nombres">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="correo_electronico">Correo electrónico</label>
                <input type="text" class="form-control" id="correo_electronico"
                    [(ngModel)]="lead_to_create.correo_electronico">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="celular">Celular</label>
                <input maxlength="9" type="text" class="form-control" id="celular" [(ngModel)]="lead_to_create.celular">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="dni">DNI</label>
                <input maxlength="8" type="text" class="form-control" id="dni" [(ngModel)]="lead_to_create.dni">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="fecha_estimada_compra">Fecha estimada de compra</label>
                <ng-select bindValue="id" bindLabel="name" [items]="fecha_estimada_de_compra"
                    class="js-example-basic-multiple" [(ngModel)]="lead_to_create.fecha_estimada_compra">
                </ng-select>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="modelo">Modelo</label>
                <ng-select bindValue="name" bindLabel="name" [items]="modelos" class="js-example-basic-multiple"
                    [(ngModel)]="lead_to_create.modelo">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="concesionario">Concesionario</label>
        <ng-select (change)="look_for_sellers()" bindValue="id" bindLabel="name" [items]="concesionarios"
            class="js-example-basic-multiple" [(ngModel)]="lead_to_create.concesionario_id">
        </ng-select>
    </div>
    <div class="form-group" *ngIf="user_type_id != 2">
        <label for="concesionario">Vendedor</label>
        <ng-select  bindValue="id" bindLabel="name" [items]="sellers_disponibles"
            class="js-example-basic-multiple" [(ngModel)]="lead_to_create.seller_id">
        </ng-select>
    </div>
    <div class="col-12">
        <div class="form-group">
            <label for="comentarios">Comentarios</label>
            <textarea [(ngModel)]="lead_to_create.comentarios" class="form-control" id="comentarios" cols="30"
                rows="10"></textarea>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>

</div>