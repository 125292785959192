import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { lastValueFrom } from 'rxjs'
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrosService {

  private registros
  private cabeceras
  private nombre_formulario

  constructor(public router: Router, private http: HttpClient) { }

  async get_registros(tipo: string) {
    var response
    if (!Array.isArray(this.registros)) {
      const data = new FormData;
      data.append('tipo_registros', tipo)
      response = <any>await lastValueFrom(this.http.post(`${environment.url_api}RegistrosAPI/get_registros`, data))
      if (!response.success) {
        throw new Error(response.message);
      }
    }

    return {
      'registros': response.registros,
      'cabeceras': response.cabeceras,
      'nombre_formulario': response.nombre_formulario
    }

  }



}
