import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalty-campaigns-templates',
  templateUrl: './loyalty-campaigns-templates.component.html',
  styleUrls: ['./loyalty-campaigns-templates.component.scss']
})
export class LoyaltyCampaignsTemplatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
