<div class="row">
    <h3>Porcentajes</h3>
    <div class="col-12 col-sm-12 col-xl-6 col-lg-6">
        <div class="card o-hidden">
            <div class="bg-secondary b-r-4 card-body">
                <div class="media static-top-widget">
                    <div class="media-body"><span class="m-0">Llamadas hechas</span>
                        <h4 class="mb-0 counter">{{data.respondio_llamada}}%</h4><i class="icon-bg"
                            data-feather="percent"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-xl-6 col-lg-6">
        <div class="card o-hidden">
            <div class="bg-primary b-r-4 card-body">
                <div class="media static-top-widget">
                    <div class="media-body"><span class="m-0">Chats realizados</span>
                        <h4 class="mb-0 counter">{{data.chats_realizados}}%</h4><i class="icon-bg" data-feather="percent"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-xl-6 col-lg-6">
        <div class="card o-hidden">
            <div class="bg-primary b-r-4 card-body">
                <div class="media static-top-widget">
                    <div class="media-body"><span class="m-0">Cotizaciones enviadas</span>
                        <h4 class="mb-0 counter">
                            {{data.cotizacion}}%</h4><i class="icon-bg" data-feather="percent"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-xl-6 col-lg-6">
        <div class="card o-hidden">
            <div class="bg-secondary b-r-4 card-body">
                <div class="media static-top-widget">
                    <div class="media-body"><span class="m-0">Leads no contactados</span>
                        <h4 class="mb-0 counter">{{data.no_contactados}}%</h4><i class="icon-bg"
                            data-feather="percent"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>