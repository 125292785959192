import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import Pusher from 'pusher-js'
import { Router } from '@angular/router';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification: boolean = false;

  public cantNotifNews = 0

  public notifications = []

  pusher: Pusher;
  channel: any;


  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  async ngOnInit() {

    const jwt = JSON.parse(localStorage.getItem("user")).jwt
    const data = new FormData()
    data.append('jwt', jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}WebSocket/get_user_id_by_jwt`, data))
    const user_id = response.user_id

    console.log(user_id)


    if (user_id) {
      this.pusher = new Pusher('98f50c72fb1faacc6978', {
        cluster: 'us2',
        authEndpoint: `${environment.url_api}pusher/auth`,
      });

      this.channel = this.pusher.subscribe(`private-my-channel-${user_id}`);

      const response_notifications = <any>await lastValueFrom(this.http.post(`${environment.url_api}Notifications/get_all_my_notifications`, data))

      if (response_notifications.success) {
        const notifications = response_notifications.notifications
        this.notifications = notifications
        for (var i = 0; i < notifications.length; i++) {
          this.cantNotifNews++
        }

      }

      console.log('notifications => ', this.notifications)

      this.channel.bind('my-event', data => {
        console.log('Data:', data);
      });
    }


    // this.notifications = [
    //   {
    //     url: '/leads/tienda/146/322830/whatsapp',
    //     name: '¡Ricardo García te envió un mensaje de Whatsapp!',
    //     date: '10min.'
    //   },
    //   {
    //     url: '/leads/tienda/146/322830/whatsapp',
    //     name: '¡Ricardo García te envió un mensaje de Whatsapp!',
    //     date: '10min.'
    //   },
    //   {
    //     url: '/leads/tienda/146/322830/whatsapp',
    //     name: '¡Ricardo García te envió un mensaje de Whatsapp!',
    //     date: '10min.'
    //   },
    //   {
    //     url: '/leads/tienda/146/322830/whatsapp',
    //     name: '¡Ricardo García te envió un mensaje de Whatsapp!',
    //     date: '10min.'
    //   },
    //   {
    //     url: '/leads/tienda/146/322830/whatsapp',
    //     name: '¡Ricardo García te envió un mensaje de Whatsapp!',
    //     date: '10min.'
    //   },
    // ]
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  rediretToUrl(url) {
    this.router.navigateByUrl(url)
  }

}
