import { Component, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddConcesionarioComponent } from '../modales-generados/modal-add-concesionario/modal-add-concesionario.component';
import { VincularUsuarioConcesionarioComponent } from '../modales-generados/vincular-usuario-concesionario/vincular-usuario-concesionario.component';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-concesionarios',
  templateUrl: './concesionarios.component.html',
  styleUrls: ['./concesionarios.component.scss']
})
export class ConcesionariosComponent implements OnInit {

  private jwt
  user_id
  concesionarios: []

  usuarios: []

  private routeSub: Subscription;


  new_concesionario = {
    name: 'Rick'
  }


  concesionario_id

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.concesionario_id = 0
    this.routeSub = this.route.params.subscribe(params => {
      this.concesionario_id = params.concesionario_id

      if (this.concesionario_id) {
        this.editar_concesionario() /** EDITAR CONCESIONARIO */
      }
    })

  }

  async editar_concesionario() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('conce_id', this.concesionario_id)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/edit_concesionarios`, data))
    if (response.success) {
      this.usuarios = response.usuarios
    }

  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/get_concesionarios`, data))
    if (response.success) {
      this.concesionarios = response.concesionarios
    }
    console.log(this.concesionarios)
  }


  // VincularUsuarioConcesionarioComponent
  agregarConcesionario() {

    // const dialogRef = this.modalService.open(ModalAddConcesionarioComponent, { data: { name: this.name }, disableClose: true });
    const dialogRef = this.modalService.open(ModalAddConcesionarioComponent)

    dialogRef.result.then((data) => {
      if (data.success) {
        this.concesionarios = data.concesionarios
      }
    })
  }

  vincularUsuarioModal() {
    const dialogRef = this.modalService.open(VincularUsuarioConcesionarioComponent)
    dialogRef.componentInstance.conce_id = this.concesionario_id;

    dialogRef.result.then((data) => {
      if (data.success) {
        Swal.fire(
          data.message,
          '',
          'success'
        )
        this.usuarios = data.usuarios
      }
    })
  }

  removeFromConce(usuario_id) {
    if (usuario_id) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: "Eliminarás al usuario del concesionario",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#000',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.borrarLead(usuario_id)
        }
      })
    } else {
      Swal.fire(
        '¡Error!',
        '',
        'error'
      )
    }
  }

  async borrarLead(usuario_id) {

    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('conce_id', this.concesionario_id)
    data.append('usuario_id', usuario_id)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/remove_user_from_concesionario`, data))

    if (response.success) {
      this.usuarios = response.usuarios
      Swal.fire(
        '¡Listo!',
        response.message,
        'success'
      )
    } else {
      Swal.fire(
        '¡Error!',
        response.message,
        'error'
      )
    }


  }

  actualizarVendedor(usuario_id) {
    this.usuarios.forEach((usu: any) => {
      if (usu.id == usuario_id) {
        this.editar_vendedor(usu)
      }
    })
  }

  async editar_vendedor(usuario) {
    const asignacion = usuario.asignacion
    var status = usuario.estado_en_conce
    const id = usuario.id



    if (status && status == 1) {
      status = 1
    } else {
      status = 0
    }

    if (Number.isInteger(Number(asignacion))) {
      if (id) {
        const data = new FormData()
        data.append('jwt', this.jwt)
        data.append('conce_id', this.concesionario_id)
        data.append('asignacion', asignacion)
        data.append('status', status)
        data.append('usuario_id', id)

        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/editar_usuario_campus`, data))
        console.log(response)
        if (response.success) {
          Swal.fire(
            response.message,
            '',
            'success'
          )
        } else {
          Swal.fire(
            response.message,
            '',
            'error'
          )
        }
      } else {
        Swal.fire(
          'No se encontró el usuario a editar',
          '',
          'error'
        )
      }
    } else {
      Swal.fire(
        'La asignación debe ser un número entero',
        '',
        'error'
      )
    }




  }

}