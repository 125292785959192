import { Input, Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

const Swal = require('sweetalert2')
@Component({
  selector: 'app-modal-reasignar-lead',
  templateUrl: './modal-reasignar-lead.component.html',
  styleUrls: ['./modal-reasignar-lead.component.scss']
})
export class ModalReasignarLeadComponent implements OnInit {



  is_loading = false
  @Input() private lead_id
  @Input() private jwt

  vendedores = []

  lead_to_update = {
    seller_id: '',
    lead_id: ''
  }

  actual_seller_id = 0

  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {

  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('lead_id', this.lead_id)
    this.lead_to_update.lead_id = this.lead_id
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_sellers_dispo_by_lead_id`, data))
    if (response.success) {
      this.vendedores = response.sellers
      this.actual_seller_id = response.actual_seller_id
    } else {
      console.log(response.message)
    }
  }

  onSubmit() {
    if (this.validate()) {
      this.grabar()
    }
  }

  async grabar() {
    if (this.actual_seller_id == Number.parseInt(this.lead_to_update.seller_id)) {
      Swal.fire('Es el mismo vendedor', '', 'warning')
      return false
    } else {
      this.is_loading = true
      const data = new FormData()
      data.append('jwt', this.jwt)
      data.append('lead_to_update', JSON.stringify(this.lead_to_update))
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/update_lead_reasignar_single`, data))
      this.is_loading = false
      if (response.success) {
        Swal.fire(response.message, '', 'success')
        this.activeModalService.close(response);
      } else {
        Swal.fire(response.message, '', 'error')
      }
    }
  }

  validate() {
    if (!this.lead_to_update.seller_id) {
      Swal.fire('Seleccione el vendedor', '', 'error')
      return false
    }
    return true
  }
  onCancel() {
    this.activeModalService.close({
      'success': false
    });
  }

}
