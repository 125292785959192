import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs'
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-jefe-select-tienda',
  templateUrl: './jefe-select-tienda.component.html',
  styleUrls: ['./jefe-select-tienda.component.scss']
})
export class JefeSelectTiendaComponent implements OnInit {
  tiendas: []
  tienda_selected

  constructor(private http: HttpClient, public router: Router) {
    this.tienda_selected = localStorage.getItem('tienda_selected')
  }

  pickTienda() {
    localStorage.setItem('tienda_selected', this.tienda_selected)
    localStorage.removeItem('lead_comercial__filtros');
  }


  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('user_type_id', JSON.parse(localStorage.getItem("user")).user_type_id )

    
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_campus_assigned_by_user_id_jefe`, data))
    if (response.success) {
      this.tiendas = response.campus
    }
  }

}
