import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateUserComponent } from 'src/app/modal-create-user/modal-create-user.component';

const Swal = require('sweetalert2')


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  private jwt
  private routeSub: Subscription;
  user_id
  user_to_edit
  concesionarios = []

  new_concesionarios = []

  usuarios: any

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.user_to_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.user_id = params.usuario_id
    })
  }

  async ngOnInit() {
    if (this.user_id >= 0) {
      this.get_data_from_user()
    } else {
      this.refresh_data()
    }
  }

  async get_data_from_user() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('user_id', this.user_id)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/get_info_by_userID`, data))
    if (response.success) {
      this.user_to_edit = response.usuario
      console.log(`user_to_edit :`, this.user_to_edit)
      this.concesionarios = response.concesionarios
    }

    console.log(this.concesionarios)
  }

  async refresh_data() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/get_list_of_users`, data))
    if (response.success) {
      this.usuarios = response.payload.usuarios
      this.usuarios.forEach((usu, index) => {
        this.usuarios[index].campus = this.usuarios[index].campus?.split(",")
      })
    }
  }

  async asignar() {
    console.log(this.user_to_edit)
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('user_id', this.user_id)
    var concesionarios_to_update = []


    if (this.user_to_edit.status) {
      data.append('estado', "1")
    } else {
      data.append('estado', "0")
    }




    this.concesionarios.forEach((el, index) => {
      if (el.asignado == 1) {
        concesionarios_to_update.push(el.id)
      }
    })


    data.append('user_to_edit', JSON.stringify(this.user_to_edit))
    data.append('concesionarios', JSON.stringify(concesionarios_to_update))
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/asignar_sedes`, data))
    this.sendAlert(response.message, response.success ? 'success' : 'error')
  }


  selectConce($event) {
    const id = $event.target.value
    const isChecked = $event.target.checked
    console.log('VALUE => ', id)
    console.log('isChecked => ', isChecked)

    this.concesionarios.map((d) => {

      if (d.id == id) {
        d.asignado = isChecked ? '1' : '0'
        return d
      }
      return d
    })

  }


  sendAlert(message, type) {
    Swal.fire({
      icon: type,
      title: message,
      text: ''
    });
  }

  agregar_usuario_modal() {
    console.log('agregar_usuario_modal')
    const dialogRef = this.modalService.open(ModalCreateUserComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      if (data.success) {
        this.usuarios = data.payload.usuarios
        this.usuarios.forEach((usu, index) => {
          this.usuarios[index].campus = this.usuarios[index].campus?.split(",")
        })
      }
    })
  }

}
