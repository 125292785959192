import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-cantidad-lead-por-fuente',
  templateUrl: './dashboard-cantidad-lead-por-fuente.component.html',
  styleUrls: ['./dashboard-cantidad-lead-por-fuente.component.scss']
})


export class DashboardCantidadLeadPorFuenteComponent implements OnInit {

  @Input() single = []
  view = null

  // options
  gradient = false;
  showLegend = true;
  showLabels = true;
  isDoughnut = true;

  colorScheme = {
    domain: ['#f26171', '#000000', '#f04255', '#000000', '#ff102a', '#aa0013', '#000000', '#67020d']
  };

  constructor() { }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  ngOnInit(): void {
  }

}
