import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from "@angular/common/http";

const Swal = require('sweetalert2')

@Component({
  selector: 'app-vincular-usuario-concesionario',
  templateUrl: './vincular-usuario-concesionario.component.html',
  styleUrls: ['./vincular-usuario-concesionario.component.scss']
})
export class VincularUsuarioConcesionarioComponent implements OnInit {


  @Input() public conce_id
  private jwt

  usuarios: []

  data = {
    'usuarios': []
  }


  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.usuarios = []
    this.jwt = JSON.parse(localStorage.user).jwt
  }

  ngOnInit(): void {
    this.load_usuarios()

  }

  async load_usuarios() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('conce_id', this.conce_id)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/get_users_to_add_in_conce`, data))
    if (response.success) {
      this.usuarios = response.usuarios
    }
  }

  onSubmit() {
    this.vincular_usuarios()
  }

  async vincular_usuarios() {
    var message_to_show = ``
    var cantidad_usuarios = this.data.usuarios.length
    if (cantidad_usuarios == 1) {
      message_to_show = `Vincularás al usuario al concesionario`
    } else {
      message_to_show = `Vincularás a los ${cantidad_usuarios} usuarios al concesionario`
    }

    Swal.fire({
      title: '¿Estás seguro?',
      text: message_to_show,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#000',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.vincular_usuarios_send()
      }
    })


  }

  async vincular_usuarios_send() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('conce_id', this.conce_id)
    data.append('usuarios', JSON.stringify(this.data.usuarios))
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/vincular_usuarios_to_campus`, data))

    if (response.success) {
      this.activeModalService.close(response)
    }
  }

  onCancel() {
    this.activeModalService.close()
  }

}
