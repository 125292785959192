import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../environments/environment';
const Swal = require('sweetalert2')

@Injectable({
    providedIn: "root"
})
export class UsersService {
    public showLoader: boolean = false;
    public userData;
    constructor(private http: HttpClient, public router: Router) { }

    async login(email: string, password: string) {
        this.showLoader = true;

        const data = new FormData;
        data.append('email', email)
        data.append('password', password)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/make_login`, data))

        if (response.success) {
            console.log(response)
            this.userData = response.data
            localStorage.setItem('user', JSON.stringify(response.data));

            if ([2, 3, 5].includes(Number.parseInt(JSON.parse(localStorage.user).user_type_id))) {
                const data = new FormData()
                data.append('jwt', JSON.parse(localStorage.user).jwt)



                const response2 = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/get_tiendas_asigned`, data))
                console.log('responseeeeeee', response2)
                if (response2.success) {
                    if (response2.tiendas.length == 1) {
                        localStorage.setItem('tienda_selected', response2.tiendas[0].id)
                        this.router.navigate(['/'])
                    } else {
                        this.router.navigate(['/asesor-select-tienda'])
                    }
                } else {
                    console.log(response.message)
                }
            } else {
                this.router.navigate(['/dashboard/default']);
            }
        } else {
            this.showLoader = false
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.message,
            });
        }
        this.showLoader = false
    }


    SetUserData(user: any) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
    }
}