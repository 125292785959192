<div class="container">
    <div class="form-group">
        <h2>Crear plantilla Whatsapp</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="template">Ingrese el nombre de la plantilla</label>
                <input type="text" class="form-control" id="template" [(ngModel)]="template.name">
            </div>
        </div>


        <div class="m-t-10 col-12" *ngIf="mergeVariables.length > 0">
            <h6>Se encontró {{mergeVariables.length}} campo(s) dinámico(s) en este template.</h6>
            <ul>
                <li class="merge" *ngFor="let merge of mergeVariables">{{merge}}</li>
            </ul>
        </div>


        <div class="col-12 m-t-20">
            <div class="form-group">
                <label class="col-form-label pt-0">Ingrese mensaje <span class="font-danger">*</span></label>
                <textarea class="form-control" (input)="highlightWords($event.target.value)"></textarea>
            </div>
        </div>


    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button> 
    </div>
</div>