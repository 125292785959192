<div class="container">
    <div class="form-group">
        <h2>Reasignar lead</h2>
    </div>
    <div class="row">
        <div class="col-12">
            <h5>Vas a reasignar {{cantidad_leads}} leads</h5>
        </div>
        <div class="col-12 col-md-12">
            <div class="form-group">
                <label for="vendedores">Selecciona el vendedor al que quieres que se deriven los leads</label>
                <ng-select id="vendedores" bindValue="id" bindLabel="nombres" placeholder=""
                    [items]="vendedores_disponibles" class="js-example-basic-multiple" [(ngModel)]="data.seller_id">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>
</div>