<app-breadcrumb [title]="'Descanso'" [items]="['Usuarios']" [active_item]="'Descanso'">
</app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h6>Usuario : {{usuario.name}} {{usuario.last_name}}</h6>
                    <div class="row m-t-10">
                        <div class="form-group col-12 col-md-6">
                            <label for="tipo_descanso">Seleccione el tipo de descanso</label>

                            <ng-select placeholder="Seleccione" [items]="tipo_descanso_disponible"
                                class="js-example-basic-multiple" [(ngModel)]="new_descanso.tipo_descanso"
                                bindValue="id" bindLabel="name">
                            </ng-select>


                            <!-- <select name="tipo_descanso" id="tipo_descanso" class="form-control">
                                <option hidden value="">- Seleccione - </option>
                                <option value="Médico">Médico</option>
                                <option value="Vacaciones">Vacaciones</option>
                                <option value="Otro">Otro</option>
                            </select> -->
                        </div>
                        <div class="form-group col-12 col-md-6">
                            <label for="fecha_inicio">Fecha de inicio</label>
                            <input [(ngModel)]="new_descanso.fecha_inicio" id="fecha_inicio" type="date"
                                class="form-control">
                        </div>
                        <div class="form-group col-12 col-md-6">
                            <label for="fecha_fin">Fecha de fin</label>
                            <input [(ngModel)]="new_descanso.fecha_fin" id="fecha_fin" type="date" class="form-control">
                        </div>
                        <div class="form-group col-12 col-md-12">
                            <label for="comentarios">Comentarios</label>
                            <textarea [(ngModel)]="new_descanso.comentarios" class="form-control" id="comentarios"
                                cols="30" rows="10"></textarea>
                        </div>
                        <div class="form-group buttons-opciones">
                            <button (click)="agregar()" class="btn btn-primary">Agregar</button>
                        </div>
                        <div class="detail">
                            <small>
                                <b>*Al agregar un descanso, el usuario durante ese tiempo no podrá ingresar al sistema
                                    ni recibirá leads.</b>
                            </small>
                        </div>
                    </div>

                </div>
                <div class="card-body">
                    <!-- <h5 class="f-w-100">Descansos:</h5> -->
                    <div class="table-responsive">



                        <div *ngIf="descansos.length == 0">
                            <h4>El usuario no cuenta con descansos</h4>
                        </div>
                        <div *ngIf="descansos.length > 0">
                            <div class="card card-temporal" *ngFor="let descanso of descansos">
                                <div class="card-body megaoptions-border-space-sm">
                                    <div class="row">
                                        <div class="col-4">Tipo de descanso</div>
                                        <div class="col-8"><span
                                                class="badge badge-info">{{descanso.tipo_descanso_nombre}}</span></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Usuario creador</div>
                                        <div class="col-8">{{descanso.nombre_aprobador}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Detalle</div>
                                        <div class="col-8">{{descanso.comentarios}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Fecha de inicio</div>
                                        <div class="col-8">{{descanso.fecha_inicio}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">Fecha de inicio</div>
                                        <div class="col-8">{{descanso.fecha_fin}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-group buttons-opciones m-t-50">
                        <button class="btn btn-success">¡Exportar!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>