<app-breadcrumb [title]="'Ingresar personas al Black List'"
    [items]="['Campañas', 'Audiencias','Generar']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="card mb-0">
            <div class="card-body">

                <br>
                <div class="row dropzone-custom" *ngIf="rows.length == 0">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>SUBA AQUÍ SU ARCHIVO DE EXCEL:</h5>
                            </div>
                            <div class="card-body">
                                <ngx-dropzone (change)="onSelect($event)" [multiple]="false" *ngIf="!file">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="icon-cloud-up"></i>
                                            <h6>Arrastre aquí su archivo</h6>
                                        </div>
                                    </ngx-dropzone-label>
                                </ngx-dropzone>

                                <progress [hidden]="!file && rows.length === 0" [value]="uploadProgress" max="100"> {{
                                    uploadProgress }}% </progress>
                                <ngx-datatable *ngIf="rows.length > 0" [rows]="rows" [columns]="columns">
                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="row" *ngIf="rows.length > 0">
      
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th *ngFor="let cabecera of cabeceras" class="text-center" scope="col">{{cabecera}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row_to_show of rows_to_show">
                                    <td class="text-center" *ngFor="let cabecera of cabeceras">{{row_to_show[cabecera]}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <button (click)="generate_black_list()" class="btn btn-primary">¡Agregar Black List!</button>
            </div>
        </div>
    </div>
</div>
<br>