import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../shared/services/campaign.service';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';


import Swal from 'sweetalert2';
@Component({
  selector: 'app-loyalty-campaigns-sms',
  templateUrl: './loyalty-campaigns-sms.component.html',
  styleUrls: ['./loyalty-campaigns-sms.component.scss']
})
export class LoyaltyCampaignsSmsComponent implements OnInit {

  protected campaigns = [ ]

  // ARRAY CON FILTROS
  filtros

  saldo
  saldo_mes
  moneda

  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private campaignService: CampaignService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe,
    private NgbTooltipModule: NgbTooltipModule

  ) { 

    this.filtros = {}

    if (localStorage.getItem("campaigns_sms_filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("campaigns_sms_filtros"))
    }
  
    this.default_values = {}


  }

  
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2')!;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0')!;
  }


  detallado_campaign = {
    invitaciones: 0,
    planning:0,

    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0,
  }

  detallado_envio = {
    invitaciones: 0,
    planning:0,

    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0,
  }
  
  
  async ngOnInit() {

    this.establecerMesAnioActual();
    this.setearFechas();


    this.load_values();
    this.load_campaigns();

  }



  fechaMin: string;
  fechaMax: string;

  establecerMesAnioActual() {
    const hoy = new Date();
    const anio = hoy.getFullYear();
    const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Formato 'YYYY-MM'
    this.filtros.mesAnio = `${anio}-${mes}`;
  }

  setearFechas() {
    const mesAnio = this.filtros.mesAnio;
    if (mesAnio) {
      const [anio, mes] = mesAnio.split('-').map(Number);
      const primerDiaMes = new Date(anio, mes - 1, 1);
      const ultimoDiaMes = new Date(anio, mes, 0);
      
      // Obtener el año y mes actual
      const fechaActual = new Date();
      const anioActual = fechaActual.getFullYear();
      const mesActual = fechaActual.getMonth() + 1; // getMonth() devuelve el mes en base 0
  
      this.filtros.fecha_inicio = primerDiaMes.toISOString().split('T')[0];
  
      if (anio === anioActual && mes === mesActual) {
        // Si es el mes y año actual, usar la fecha actual
        this.filtros.fecha_fin = fechaActual.toISOString().split('T')[0];
      } else {
        // De lo contrario, usar el último día del mes
        this.filtros.fecha_fin = ultimoDiaMes.toISOString().split('T')[0];
      }
  
      this.filtros.fecha_lanzamiento_inicio = '';
      this.filtros.fecha_lanzamiento_fin = '';
  
      // Actualizar los campos de fecha con el rango del mes seleccionado
      this.fechaMin = this.filtros.fecha_inicio;
      this.fechaMax = this.filtros.fecha_fin;
  
      this.updateDateInputs();
    }
  }
  
  updateCalendar() {
    this.setearFechas();
  }

  updateDateInputs() {
    const inputs = document.querySelectorAll('input[type="date"]');
    inputs.forEach(input => {
      (input as HTMLInputElement).setAttribute('min', this.fechaMin);
      (input as HTMLInputElement).setAttribute('max', this.fechaMax);
    });
  }


  rerender(): void {


    localStorage.setItem("campaigns_sms_filtros", JSON.stringify(this.filtros))


    this.buttonFiltrarTexto = `Cargando...`
    this.load_campaigns();

    this.buttonFiltrarTexto = "Filtrar"

  }



  async load_values() {

    const res = <any>await this.campaignService.campaign_service_init_general();

    if (res.success) {

      this.default_values.type = res.payload.type
      this.default_values.tipo_filtro = res.payload.tipo_filtro
    }

    this.buttonFiltrarTexto = "Filtrar"

  }
  costo_total ;

  reporte;

  async load_campaigns() {
    Swal.showLoading()

    const res = <any>await this.campaignService.get_campaign_filter(this.filtros, "SMS");

    if (res.success) {

      this.campaigns = res.campaigns;

      this.costo_total = parseFloat(res.costo_total).toFixed(2) ;

      const response_saldo = <any>await this.campaignService.campaign_service_saldo_by_filter(this.filtros, "sms");

      if (response_saldo.success) {
        this.moneda = response_saldo.moneda;

        this.saldo_mes = response_saldo.saldo_mes;
        var calculo_saldo = this.saldo_mes - this.costo_total;
        this.saldo = calculo_saldo.toFixed(2) 
      }
   
      this.reporte = res.reporte.por_tipo;
      console.log(this.reporte);


    }
    Swal.close()
  }


  editarSaldo() {
    Swal.fire({
      title: 'Editar Saldo',
      input: 'number',
      inputLabel: 'Ingrese el nuevo saldo',
      inputValue: this.saldo_mes,
      showCancelButton: true,
      confirmButtonText: 'Actualizar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Debe ingresar un número válido';
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const saldo = parseFloat(result.value);
        const mesAnio = this.filtros.mesAnio;

        if (this.guardar_saldo(saldo, mesAnio, "sms")) {
          Swal.fire('Actualizado!', '', 'success');
          this.load_campaigns();

        }

      }
    });
  }

  async guardar_saldo (saldo, mesAnio, type){
    const res =  <any>await this.campaignService.insert_saldo_mes(saldo, mesAnio, type);
    if (res.success) {
      return true;
    } else {
      return false;
    }
  }
}