import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-import-black-list',
  templateUrl: './import-black-list.component.html',
  styleUrls: ['./import-black-list.component.scss']
})
export class ImportBlackListComponent implements OnInit {

  files: File[] = [];
  audiencia


  title = 'crm';
  file?: File;
  timeout: any;
  uploadProgress: number = 0;
  uploadSub?: Subscription;
  rows = [];
  rows_to_show = []
  cabeceras = []

  audiencia_type



  columns = [{ prop: 'nombres', name: 'nombres' },
  { prop: 'celular', name: 'celular' },
  { prop: 'dni', name: 'dni' },
  { prop: 'correo_electronico', name: 'correo_electronico' },
  { prop: 'fecha', name: 'fecha' },
  { prop: 'modelo', name: 'modelo' },
  { prop: 'version', name: 'version' },
  { prop: 'modelo_comunicacion', name: 'modelo_comunicacion' },
  { prop: 'fuente', name: 'fuente' }];


  constructor(
    private BlackListService: BlackListService,
    private http: HttpClient,
    public router: Router,
    private route: ActivatedRoute

  ) {


  }

  ngOnInit(): void {

  }

  replaceFile() {
    this.files.splice(0, 1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  generate_preview() {

    for (let indice in this.rows[0]) {
      this.cabeceras.push(indice)
    }


    for (let index = 0; index < this.rows.length; index++) {
        this.rows_to_show.push(this.rows[index])
   
    }

    console.log(this.rows_to_show)

  }

  async generate_black_list() {

    if (this.rows.length < 1) {
      Swal.fire('Debe ingresar un excel con contenido', '', 'error')
      return false
    }


    Swal.fire({
      title: '¿Estás seguro?',
      text: `Estas ${this.rows.length} personas no podrán ser considerados en las campañas de comunicación`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#000',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.generar();
      }
    })


  }

  async generar() {
    const to_add = {
      'data': this.rows,
      'audiencia': this.audiencia
    }

  let res = <any>await this.BlackListService.generate_BlackList_FromExcel(to_add)


    if (res.success) {
      Swal.fire(res.message, '', 'success')
      this.reset()
      this.router.navigate(['loyalty-comunication/black-list']);
      

    } else {
      Swal.fire(res.message, '', 'error')
    }
  }



  reset() {
    this.file = undefined;
    this.uploadProgress = 0;
    this.uploadSub = undefined;
    this.rows = [];
    this.rows_to_show = []
    this.cabeceras = []
  }

  onPage(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  onSelect(event: any) {

      this.file = event.addedFiles[0];

      if (this.file) {
        const formData = new FormData();

        formData.append("fileToUpload", this.file)

        Swal.showLoading()
        const upload$ = this.http.post(`${environment.url_api}BlackList/process_excel`, formData, {
          reportProgress: true,
          observe: 'events'
        })

        this.uploadSub = upload$.subscribe((event: any) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.uploadProgress = 0;
              break;

            case HttpEventType.UploadProgress:
              this.uploadProgress = Math.round(100 * (event.loaded / event.total));
              break;

            case HttpEventType.Response:
              const body: any = event.body

              if (!body.success) {
                Swal.fire('Error', body.message, 'error')
                this.reset();
              } else {
                Swal.fire('¡Listo!', `Se encontraron: ${body.data.length} registros`, 'success')
                this.rows = body.data
                console.log('ROWS : ', this.rows)
                this.generate_preview()
              }
              break;

            default:
          }
        })
      }
    
  }



}
