import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import {UsersService} from '../../../app/logueo/users.service'

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(public authService: UsersService,
    public router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let user = JSON.parse(localStorage.getItem('user'));
    if (!user || user === null) {
      this.router.navigate(['/auth/login']);
      return true
    }
    else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/login']);
        return true
      }

      // Si existe el usuario, podemos verificar roles (por ejemplo, multirol o tipo de usuario)
      if (user.multirol && !user.user_type_id) {

        console.log("debería ir a rol-choise");
         this.router.navigate(['/rol_choice']); // Redirigir si el usuario no tiene un tipo asignado
        return true; // Bloquear el acceso a las rutas de admin
      }

    
 

    }

   

    return true
  }
}
