import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
const Swal = require('sweetalert2')


@Component({
  selector: 'app-users-actividad',
  templateUrl: './users-actividad.component.html',
  styleUrls: ['./users-actividad.component.scss']
})
export class UsersActividadComponent implements OnInit {


  private routeSub: Subscription
  usuario_id
  usuario

  private jwt
  concesionarios: []

  date
  acciones: any


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt

    this.usuario = {}

    this.routeSub = this.route.params.subscribe(parametros => {
      this.usuario_id = parametros.usuario_id
    })

  }

  async ngOnInit() {
    this.make_filter()
  }

  async make_filter() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('user_id', this.usuario_id)
    if (this.date) {
      data.append('date', this.date + ``)
    }
    Swal.showLoading()
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}User_accionAPI/get_acciones_by_user_id`, data))
    Swal.close()

    if (response.success) {
      console.log(response)
      this.date = response.date

      if (response.data) {
        console.log(response)
        this.acciones = response.data
      }


      // this.acciones = <any>response.data
      console.log(typeof this.acciones)
      console.log('this.acciones => ', this.acciones)
      this.usuario = response.user
    }
  }

  async buscar() {
    Swal.showLoading()
    await this.make_filter()
    Swal.close()
  }



}
