<div class="media profile-media">
  <!-- <img  src="assets/images/dashboard/profile_honda.png" alt=""> -->
  <!-- <i class="icofont icofont-motor-bike"></i> -->
  <div class="media-body"><span>{{userName}}</span>
    <p class="mb-0 font-roboto">{{userType}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a href="/user/profile">
      <app-feather-icons [icon]="'user'"></app-feather-icons><span>Mi cuenta </span>
    </a></li>
  <!-- <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li (click)="logoutFunc()">
    <app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Cerrar sesión</span>
  </li>
</ul>