import { Input, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-lead-history-info',
  templateUrl: './lead-history-info.component.html',
  styleUrls: ['./lead-history-info.component.scss']
})
export class LeadHistoryInfoComponent implements OnInit {

  @Input() private lead_id
  @Input() private jwt

  history: any

  constructor(private http: HttpClient,
    private activeModalService: NgbActiveModal) {
    this.history = {
      'whatsapp': [],
      'calls': [],
      'mailing': []
    }
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/get_history_of_comunications_by_lead_id`, data))
    if (response.success) {
      this.history = response.history
    }
    console.log(response)
  }

  onCancel() {
    this.activeModalService.close({
    });
  }

}
