<app-breadcrumb [title]="'Audiencias'" [items]="['Campañas', 'Audiencias']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 item" [routerLink]="'add-from-crm'">
                            <div class="card bg-primary">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Crear desde base de clientes</h5>
                                            <p>Genera una audiencia desde base de leads</p>
                                        </div><i data-feather="book-open"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 item" [routerLink]="'add-from-excel'">
                            <div class="card bg-success">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Importar desde excel</h5>
                                            <p>Genera una audiencia desde un archivo Excel</p>
                                        </div><i data-feather="file-text"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="audiences.length > 0">
            <div class="card">
                <div class="card-header">
                    <h4>Listado de audiencias disponibles <i data-feather="users"></i></h4>

                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th class="text-center">Nombre de audiencia</th>
                                    <th class="text-center">Cantidad de personas</th>
                                    <th class="text-center">Tipo de audiencia</th>
                                    <th class="text-center">Fecha de creación</th>
                                    <th class="text-center">Ver Audiencia</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let audience of audiences">
                                    <td>{{audience.original_name}}</td>
                                    <td class="text-center">{{audience.cantidad}}</td>
                                    <td class="text-center">{{audience.type_audience}}</td>
                                    <td class="text-center">{{audience.created_time}}</td>
                                    <td class="text-center">
                                        <a  target="_blank"
                                            href="https://honda4w-ci.mayu.digital/Audience/generar_excel_audiencia/{{audience.id}}/{{audiencia_type}}"><i
                                            class="fa fa-file-excel-o text-green"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>