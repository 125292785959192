<div class="container">
    <div class="form-group">
        <h2>Crear lead</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombres">Nombres</label>
                <input type="text" class="form-control" id="nombres" [(ngModel)]="lead_to_create.nombres">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="apellidos">Apellidos</label>
                <input type="text" class="form-control" id="apellidos" [(ngModel)]="lead_to_create.apellidos">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="correo">Correo electrónico</label>
                <input type="text" class="form-control" id="correo" [(ngModel)]="lead_to_create.correo">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="password">Contraseña</label>
                <input type="text" class="form-control" id="password" [(ngModel)]="lead_to_create.password">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="form-group">
                <label for="tipo_de_usuario">Tipo de usuario</label>
                <ng-select bindValue="id" bindLabel="name" [items]="tipo_de_usuarios" class="js-example-basic-multiple"
                    [(ngModel)]="lead_to_create.tipo_de_usuario">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>
</div>