import {Input,  Component, OnInit } from '@angular/core'
import { TemplateService } from '../shared/services/template.service'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-select-whatsapp-template',
  templateUrl: './modal-select-whatsapp-template.component.html',
  styleUrls: ['./modal-select-whatsapp-template.component.scss']
})
export class ModalSelectWhatsappTemplateComponent implements OnInit {

  public htmlContent: string | null = null;
 
  template = {
    id: 0
  }

  list_of_templates = []
  is_loading = true

  constructor(
    private templateService: TemplateService,
    private activeModalService: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.load_templates()
  }

  async load_templates() {
    const res = <any>await this.templateService.get_all_templates_whatsapp()
    if (res.success) {
      this.list_of_templates = res.templates

      for (let i = 0; i < this.list_of_templates.length; i++) {
        let created_time = this.list_of_templates[i].created_time.substr(0, 10)
        this.list_of_templates[i].name += ` - (${created_time})`
      }


      this.is_loading = false
    }
  }

  onCancel() {
    this.activeModalService.close({
      success: false
    })
  }

  onSubmit() {
    if (this.template.id > 0) {
      this.activeModalService.close({
        success: true,
        template: this.template
      })
    } else {
      Swal.fire('No ha seleccionado ninguna plantilla', '', 'warning')
    }

  }
  html_b;
  change_template(template) {
    this.template = template
    this.htmlContent = template.html
    this.htmlContent = this.htmlContent.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    this.htmlContent = this.htmlContent.replace(/\n/g, '<br>');

    var html_button = '';

    if (template.type == "reply") {

      const optionsArray = JSON.parse(template.options);

      if (optionsArray) {
        optionsArray.forEach((option, index) => {
            html_button += `
                <div class="bg-white mt-2 rounded p-2 text-primary" style="text-align: center;">
                  <center> ${option} </center>
                </div>
            `;
        });
      }
      
    }


    this.html_b = html_button;


  }

}
