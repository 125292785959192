import { Input, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-modal-correo-detail',
  templateUrl: './modal-correo-detail.component.html',
  styleUrls: ['./modal-correo-detail.component.scss']
})
export class ModalCorreoDetailComponent implements OnInit {

  @Input() private comunicacion_id
  @Input() private jwt


  html: any


  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {
    const data = new FormData()
    data.append('comunicacion_id', this.comunicacion_id)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/get_info_mail_by_comunicacion_id`, data))
    if (response.success) {

      this.html = response.html
    }

  }

}
