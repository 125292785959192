<h3>Historial de acciones</h3>
<table class="table">
    <thead>
        <tr>
            <th>Usuario</th>
            <th>Acción</th>
            <th>Hora</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of data">
            <td>{{d.nombres}}</td>
            <td>{{d.accion}}</td>
            <td>{{d.created_time}}</td>
        </tr>
    </tbody>
</table>