import { Component, OnInit } from '@angular/core'
import { TemplateService } from '../shared/services/template.service'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-select-template',
  templateUrl: './modal-select-template.component.html',
  styleUrls: ['./modal-select-template.component.scss']
})
export class ModalSelectTemplateComponent implements OnInit {

  public htmlContent: string | null = null;

  template = {
    id: 0
  }

  list_of_templates = []
  is_loading = true

  constructor(
    private templateService: TemplateService,
    private activeModalService: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.load_templates()
  }

  async load_templates() {
    const res = <any>await this.templateService.get_all_templates()
    if (res.success) {
      this.list_of_templates = res.templates

      for (let i = 0; i < this.list_of_templates.length; i++) {
        let created_time = this.list_of_templates[i].created_time.substr(0, 10)
        this.list_of_templates[i].name += ` - (${created_time})`
      }


      this.is_loading = false
    }
  }

  onCancel() {
    this.activeModalService.close({
      success: false
    })
  }

  onSubmit() {
    if (this.template.id > 0) {
      this.activeModalService.close({
        success: true,
        template: this.template
      })
    } else {
      Swal.fire('No ha seleccionado ninguna plantilla', '', 'warning')
    }

  }

  change_template(template) {
    this.template = template
    this.htmlContent = template.html
  }

}
