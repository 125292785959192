<div class="container">
    <div class="form-group">
        <h2>Reasignar lead</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="form-group">
                <label for="vendedor">Vendedor</label>
                <ng-select bindValue="id" bindLabel="nombres" placeholder="" [items]="vendedores"
                    class="js-example-basic-multiple" [(ngModel)]="lead_to_update.seller_id">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>
</div>