import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-templates-picker',
  templateUrl: './templates-picker.component.html',
  styleUrls: ['./templates-picker.component.scss']
})
export class TemplatesPickerComponent implements OnInit {

  templates = [
    {
      id: 1,
      name: 'Hola, [NOMBRES]. Seguimos pensando en ti, resuelve esta encuesta y obten un descuento en el siguiente Honda Fest: [LINK]',
      sents: 10
    },
    {
      id: 1,
      name: '¡Hola, [NOMBRE]! Celebra el Día de la Madre con nosotros. Regístrate para recibir ofertas exclusivas y sorpresas especiales: [LINK]',
      sents: 0
    },
    {
      id: 1,
      name: 'Hola, [NOMBRE]. Este Día del Padre, demuéstrale cuánto lo quieres. Regístrate para recibir ideas de regalos y descuentos especiales: [LINK]',
      sents: 12
    },
    {
      id: 1,
      name: '¡Hola, [NOMBRE]! Celebra Fiestas Patrias con nosotros. Regístrate para recibir recetas tradicionales, decoración festiva y promociones especiales: [LINK]',
      sents: 1
    },
    {
      id: 1,
      name: 'Hola, [NOMBRE]. ¡La Navidad está cerca! Regístrate para recibir ideas de regalos, descuentos exclusivos y participar en sorteos especiales: [LINK]',
      sents: 8
    },
    {
      id: 1,
      name: '¡Hola, [NOMBRE]! ¿Estás planeando tus vacaciones de Fiestas Patrias? Regístrate para recibir cotizaciones personalizadas y ofertas especiales: [LINK]',
      sents: 1
    },
    {
      id: 1,
      name: '¡Hola, [NOMBRE]! Te invitamos a nuestro evento especial de Navidad. Regístrate para recibir detalles de la ubicación, horarios y sorpresas que tenemos preparadas: [LINK]',
      sents: 27
    },
    {
      id: 1,
      name: 'Hola, [NOMBRE]. Celebra el Día de la Madre con un regalo especial. Regístrate para recibir un descuento exclusivo en nuestra tienda: [LINK]',
      sents: 1
    },
    {
      id: 1,
      name: '¡Hola, [NOMBRE]! Este año, Santa tiene una sorpresa especial para ti. Regístrate para recibir un regalo misterioso en Navidad: [LINK]',
      sents: 2
    },
    {
      id: 1,
      name: 'Hola, [NOMBRE]. Únete a nuestra celebración de Fiestas Patrias. Regístrate para recibir información sobre actividades, descuentos y promociones especiales: [LINK]',
      sents: 7
    },
    {
      id: 1,
      name: '¡Hola, [NOMBRE]! Este Día del Padre, demuéstrale cuánto lo quieres con un regalo único. Regístrate para recibir ideas originales y descuentos exclusivos: [LINK]',
      sents: 4
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
