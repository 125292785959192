<app-breadcrumb [title]="'Agregar encuesta'" [items]="['Campañas', 'Configuración', 'NPS']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-8">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>En este módulo, podrás crear encuestas</small>
                    </div>
                </div>
                <div class="card-body">
                    <div class="col-12 col-md-12">

                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Ingrese el nombre de la encuesta
                            </label>
                            <div class="col-sm-9">
                                <input class="form-control con_help digits" type="text"
                                    [(ngModel)]="encuesta.name_server">
                                <div class="help" data-container="body" placement="left"
                                    ngbTooltip="Este será el nombre que se visualizará al grabarlo en el CRM">
                                    <i data-feather="info"></i>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Ingrese el título de la encuesta
                            </label>
                            <div class="col-sm-9">
                                <input class="form-control con_help digits" type="text" [(ngModel)]="encuesta.name">
                                <div class="help" data-container="body" placement="left"
                                    ngbTooltip="Este será el nombre que se visualizará como título en la encuesta cuando el usuario ingrese">
                                    <i data-feather="info"></i>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Días que estará disponible la encuesta</label>
                            <div class="col-sm-9">
                                <input min="1" class="form-control digits con_help" type="number"
                                    [(ngModel)]="encuesta.dias_disponible">
                                <div class="help" data-container="body" placement="left"
                                    ngbTooltip="Los días se contarán a partir de que la campaña ha sido enviada, una vez pasado los {{encuesta.dias_disponible}} días, el usuario no podrá ingresar a la encuesta">
                                    <i data-feather="info"></i>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Seleccione el tipo de encuesta</label>
                            <div class="col-sm-9">
                                <ng-select [items]="tipo_encuesta" class="js-example-basic-multiple con_help"
                                    bindLabel="name" bindValue="id" [(ngModel)]="encuesta.type"
                                    (ngModelChange)="onTipoEncuestaChange()">
                                </ng-select>
                                <div class="help" data-container="body" placement="left"
                                    ngbTooltip="Los tipos de encuesta involuntarias son encuestas que por lo general serán impactadas por campañas de marketing, el restante ...">
                                    <i data-feather="info"></i>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-3 col-form-label">Seleccione el subtipo de encuesta</label>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-9">
                                        <ng-select [items]="subtypesOptions" class="js-example-basic-multiple"
                                            bindLabel="name" bindValue="id" [(ngModel)]="encuesta.sub_type">
                                        </ng-select>
                                    </div>
                                    <div class="col-3">
                                        <button (click)="agregarSubtipoEncuesta()" class="btn btn-danger"> + </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br>
                    <div class="col-12 col-md-12" *ngIf="encuesta.type > 1">
                        <div class="text-first">
                            <h5>Preguntas personales: </h5>
                        </div>
                        <div class="media-body text-end">
                            <div class="row">
                                <div class="col-12">
                                    <small>Al elegir este tipo de encuesta, por defecto se
                                        cargará las preguntas genéricas, puedes agregar otro tipo de preguntas
                                        también</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngFor="let pregunta of preguntas_genericas; let i = index">
                            <div class="form-group row">
                                <div class="row">
                                    <label class="col-sm-3 col-form-label">Dato personal Nº {{i+1}}</label>

                                    <!-- 
                                    <div class="col-sm-9" *ngIf="[0,1,2].includes(i)">
                                        <input disabled class="form-control digits" type="text"
                                            [(ngModel)]="preguntas_genericas[i].name">
                                    </div>
                                    -->

                                    <div class="col-sm-9" >


                                        <div class="row">
                                            <div class="col-5">
                                                <input disabled class="form-control digits" type="text"
                                                    [(ngModel)]="preguntas_genericas[i].name">
                                            </div>
                                            <div class="col-2">
                                                <button (click)="editarDatoPersonal(i)" class="btn btn-secondary">
                                                    <i alt="Editar" class="icon-pencil-alt2"></i>
                                                </button>
                                            </div>
                                            <div class="col-2">
                                                <button (click)="eliminarDatoPersonal(i)" class="btn btn-primary">
                                                    <i alt="Eliminar" class="icon-trash"></i>
                                                </button>
                                            </div>

                                            <div class="col-3 text-end pregunta_personal flechas d-flex justify-content-end">
                                                <button class="col-6 btn btn-success me-1" style="padding: 0px;"  (click)="moverPreguntaPersonalArriba(i)" [disabled]="i === 0">⬆</button>
                                                <button class="col-6 btn btn-success" style="padding: 0px;"  (click)="moverPreguntaPersonalAbajo(i)" [disabled]="i === preguntas.length - 1">⬇</button>
                                            </div>

                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-primary" (click)="agregarDatoPersonal()">Agregar dato personal </button>
                        <hr>
                    </div>


                    <div class="col-12 col-md-12" tooltipContainer>


                        <div class="pregunta form-group row" *ngFor="let pregunta of preguntas; let i = index"
                            [ngClass]="{'subpregunta': pregunta.parent && pregunta.parent.has_parent}">
                            <div class="form-group row">
                                <div class="row m-b-40">
                                    <div class="col-12 col-md-6">
                                        <h6 class="text-decoration-underline" *ngIf="!pregunta.parent.has_parent">
                                            Pregunta Nº {{i+1}}</h6>
                                        <h6 class="text-decoration-underline" *ngIf="pregunta.parent.has_parent">
                                            Pregunta Nº {{i+1}} - Subpregunta cuando elige opción
                                            "{{pregunta.parent.respuesta_gatillo}}"</h6>
                                    </div>
                                    <div class="col-12 col-md-6 text-end flechas">
                                        <button class="btn btn-success" (click)="moverPreguntaArriba(i)"
                                            [disabled]="i === 0">⬆</button>
                                        <button class="btn btn-success" (click)="moverPreguntaAbajo(i)"
                                            [disabled]="i === preguntas.length - 1">⬇</button>
                                    </div>
                                </div>

                                <div class="row m-t-15">
                                    <label class="col-sm-3 col-form-label">Ingrese título (opcional)</label>
                                    <div class="col-sm-9">
                                        <input class="form-control digits" type="text"
                                            [(ngModel)]="preguntas[i].titulo">
                                    </div>
                                </div>

                                <div class="row m-t-15">
                                    <label class="col-sm-3 col-form-label">Ingrese la pregunta</label>
                                    <div class="col-sm-7">
                                        <input class="form-control digits" type="text" [(ngModel)]="preguntas[i].texto">
                                    </div>
                                    <div class="col-sm-2">
                                        <button class="btn btn-danger"
                                            (click)="eliminarPregunta(i, preguntas[i].parent.option_id)">Eliminar</button>
                                    </div>
                                </div>



                                <div class="row m-t-20">
                                    <label class="col-sm-3 col-form-label">Seleccione tipo de pregunta</label>
                                    <div class="col-sm-9">
                                        <ng-select [items]="tipo_preguntas" class="js-example-basic-multiple con_help"
                                            placeholder="Seleccione" bindLabel="name" bindValue="id"
                                            [(ngModel)]="preguntas[i].tipo">
                                        </ng-select>

                                        <div *ngIf="preguntas[i].tipo - 1 >= 0" class="help_button"
                                            (click)="mostrarHelp(preguntas[i].tipo - 1)">
                                            <button class="btn btn-help">?</button>
                                        </div>


                                    </div>
                                </div>


                                <div class="row m-t-20" *ngIf="preguntas[i].tipo === 2">
                                    <label class="col-sm-3">
                                        ¿Deseas agregar subpreguntas por rango?
                                    </label>
                                    <div class="col-sm-9">
                                        <label class="switch">
                                            <input [(ngModel)]="preguntas[i].subPregunta[0].has_subpregunta"
                                                type="checkbox"><span class="switch-state"></span>
                                        </label>
                                    </div>
                                    <div class="col-12" *ngIf="preguntas[i].subPregunta[0].has_subpregunta">
                                        <div class="row m-t-20"
                                            *ngFor="let rango of preguntas[i].subPregunta[0].rangos; let j = index">
                                            <label class="col-sm-3 col-form-label">Rango {{rango.name}}</label>
                                            <div class="col-sm-9">
                                                <input class="form-control digits" type="text"
                                                    [(ngModel)]="preguntas[i].subPregunta[0].rangos[j].pregunta"
                                                    placeholder="Ingrese pregunta">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Input y botones para opciones si el tipo es 4 -->

                                <div class="col-sm-12" *ngIf="preguntas[i].tipo === 4" >
                                    <div class="row">
                                        <div class="col-12 col-sm-3">
                                            <label for="">Elija el tipo de selección</label>
                                        </div>

                                        
                                        <div class="col-12 col-sm-9"><ng-select [items]="tipo_de_opciones"
                                                class="js-example-basic-multiple" placeholder="Seleccione"
                                                bindLabel="name" bindValue="id"
                                                [(ngModel)]="preguntas[i].subtipo">
                                            </ng-select></div>
                                    </div>
                                </div>

                                <div class="col-sm-12" *ngIf="preguntas[i].tipo === 3" >

                                    <div class="row">
                                        
                                        <div class="col-12 col-sm-3">
                                            <label for="">Selecciona el tipo de campo de texto</label>
                                        </div>

                                        
                                        <div class="col-12 col-sm-9"><ng-select [items]="tipo_de_campo_texto"
                                                class="js-example-basic-multiple" placeholder="Seleccione"
                                                bindLabel="name" bindValue="id"
                                                [(ngModel)]="preguntas[i].subtipo">
                                            </ng-select></div>
                                    </div>
                                </div>

                                <div class="row m-t-20" *ngIf="preguntas[i].tipo === 4 || preguntas[i].tipo === 5">
                                    <label class="col-sm-12 col-form-label">Generar varias Opciones</label>
                                   
                                    <div class="col-sm-12">
                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" [ngClass]="{'active': activeTab === 'text'}" id="text-tab" (click)="setActiveTab('text')" role="tab" aria-controls="text" aria-selected="{{ activeTab === 'text' }}">Texto</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" [ngClass]="{'active': activeTab === 'file'}" id="file-tab" (click)="setActiveTab('file')" role="tab" aria-controls="file" aria-selected="{{ activeTab === 'file' }}">Archivo Excel</button>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="myTabContent">

                                           
                                            <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'text'}" id="text" role="tabpanel" aria-labelledby="text-tab">
                                                <p id="description">
                                                    Introduce las opciones en el área de texto. Puedes separar cada opción usando comas, saltos de línea o barras verticales (|).
                                                </p>
                                                
                                                <textarea 
                                                    class="form-control mt-2" 
                                                    [(ngModel)]="inputText" 
                                                    rows="10" cols="20" style="height: 127px;"
                                                    placeholder="Por favor, separa las opciones utilizando comas (,), saltos de línea o el símbolo de barra vertical (|).">
                                                </textarea>
                                                <button class="mt-2 btn btn-primary" (click)="generateArray(i, preguntas[i].uniqid)">Generar</button>
                                            </div>
                                            <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'file'}" id="file" role="tabpanel" aria-labelledby="file-tab">
                                                <p id="description">
                                                    Introduce las opciones de un archivo Excel. Procura seguir la estructura sugerida
                                                </p>
                                                
                                                <label for="fileInput" class="col-form-label mt-2">Importar opciones desde un archivo Excel</label>
                                                <div class="custom-file">
                                                    <ngx-dropzone (change)="onSelect(i, preguntas[i].uniqid, $event)" [multiple]="false" *ngIf="!file">
                                                        <ngx-dropzone-label>
                                                            <div class="dz-message needsclick">
                                                                <i class="icon-cloud-up"></i>
                                                                <h6>Arrastre aquí su archivo</h6>
                                                            </div>
                                                        </ngx-dropzone-label>
                                                    </ngx-dropzone>
                    
                                                    
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                                
                             

                                <div class="row m-t-20" *ngIf="preguntas[i].tipo === 4  || preguntas[i].tipo === 5 ">


                                    <label class="col-sm-12 col-form-label">Opciones</label>
                                    <div class="col-sm-12">
                                        <div class="row">

                                          
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-7">
                                                        <input class="form-control digits" type="text"
                                                            [(ngModel)]="preguntas[i].nuevaOpcion"
                                                            placeholder="Ingrese opción">
                                                    </div>
                                                    <div class="col-sm-5">
                                                        <button class="btn btn-success"
                                                            (click)="agregarOpcion(i, preguntas[i].uniqid)">+</button>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-12 m-t-10">
                                        <div class="row">

                                            <div class="col-12 d-flex mt-20 mb-10" *ngIf="preguntas[i].opciones.length > 0">
                                                <button class="btn btn-primary btn-sm" (click)="ordenarOpciones(i,'asc')">
                                                    <i class="fa fa-sort-alpha-asc"></i> Ordenar Ascendente
                                                </button>
                                                <button class="btn btn-secondary btn-sm m-l-10" (click)="ordenarOpciones(i,'desc')">
                                                    <i class="fa fa-sort-alpha-desc"></i> Ordenar Descendente
                                                </button>
                                            </div>

                                            <div class="col-12 m-t-10">


                                                <table *ngIf="preguntas[i].opciones.length > 0"
                                                    class="table table-concesionarios">
                                                    <thead>
                                                        <tr>
                                                            <th>Opción</th>
                                                            <th>Opciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let opcion of preguntas[i].opciones; let j = index">
                                                            <td><span class="opcion_name">{{ opcion.name
                                                                    }}</span></td>
                                                            <td>
                                                                <button class="btn btn-danger btn-sm"
                                                                    (click)="eliminarOpcion(i, j, opcion.id)">Eliminar</button>
                                                                <button class="m-l-25 btn btn-success btn-sm d-inline-block"
                                                                    (click)="agregarSubPregunta(i, j, opcion.id)">Agregar
                                                                    subpregunta</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    


                    <div class="col-12 botones">
                        <button class="btn btn-primary" (click)="agregarPregunta()">Agregar
                            Pregunta +</button>
                    </div>
                    <hr>
                    <div class="col-12 botones m-t-20">
                        <button class="btn btn-secondary" (click)="validarEncuesta()">Grabar encuesta</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-4" id="preview">
            <div class="card">
                <div class="card-body">

                    <button class="btn btn-danger" (click)="logPreview()"> ↻ Actualizar Preview </button>
                    <iframe [src]="iframeUrl" width="100%" height="900px"></iframe>


                </div>
            </div>
        </div>

    </div>
</div>