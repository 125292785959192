<h3>Leads por modelos</h3>
<ngx-charts-bar-vertical
  [view]="view"
  [scheme]="colorScheme"
  [results]="single"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendTitle] = "'Modelos'"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  (select)="onSelect($event)">
</ngx-charts-bar-vertical>