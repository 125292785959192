import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from '../shared/services/template.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-template-add-modal-whatsapp',
  templateUrl: './template-add-modal-whatsapp.component.html',
  styleUrls: ['./template-add-modal-whatsapp.component.scss']
})
export class TemplateAddModalWhatsappComponent implements OnInit {

  public htmlContent: string | null = null;
  mergeVariables: string[] = [];
  is_loading = false


  template = {
    name: '',
    es_dinamica: true,
    html: ''
  }


  constructor(
    private templateService: TemplateService,
    private activeModal: NgbActiveModal
  ) { 
    
  }

  ngOnInit(): void {
  }

  highlightedText: string = '';

  highlightWords(text: string) {
    this.htmlContent = text; // Almacenar el contenido HTML sin procesar
    this.mergeVariables = this.extractMergeVariables(text); // Extraer y almacenar las variables
  
    console.log(this.mergeVariables)
  }

  async onSubmit() {
    if(this.template.name){
      this.template.html = this.htmlContent
      
      if(this.template.html){
        const res = <any>await this.templateService.insertTemplateWhatsapp(
          this.template
        )
    
        if (res.success) {
          Swal.fire(res.message, '', 'success')
          this.activeModal.close({
            success: true
          })
        } else {
          Swal.fire(res.message, '', 'error')
        }
      } else {
        Swal.fire("Ingrese un texto para la Plantilla", '', 'error')
      }
    } else {
      Swal.fire("Ingrese un nombre para la Plantilla", '', 'error')
    }

  }

  onCancel() {
    this.activeModal.dismiss('cancel'); // Rechaza la promesa con un motivo de cancelación
  }


  extractMergeVariables(htmlContent: string): string[] {
    const mergeRegex = /#MERGE_\w+/g;
    const matches = htmlContent.match(mergeRegex);
    return matches ? Array.from(new Set(matches)) : [];
  }


}
