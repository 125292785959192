

import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs'
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';


@Component({
  selector: 'app-leads-semi-nuevos',
  templateUrl: './leads-semi-nuevos.component.html',
  styleUrls: ['./leads-semi-nuevos.component.scss']
})
export class LeadsSemiNuevosComponent implements OnInit {

  tiendas: []
  tienda_selected

  constructor(private http: HttpClient, public router: Router) {
  }

  pickTienda(id) {
    console.log(id);
    const ir = '/modelos-semi-nuevos/' + id;
    this.router.navigate([ir]); 
  }
  
  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('user_type_id', JSON.parse(localStorage.getItem("user")).user_type_id )

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_campus_assigned_by_user_id`, data))
    if(response.success){
      this.tiendas = response.campus
    }
  }

}

