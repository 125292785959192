<app-breadcrumb [title]="''" [items]="['Asesor']" [active_item]="'Seleccione tienda'">
</app-breadcrumb>
<div class="col-12">
    <div class="card">
        <div class="card-header">
            <h5>Seleccione su tienda</h5>
            <span>Debe seleccionar una tienda</span>
        </div>
        <div class="card-body megaoptions-border-space-sm">
            <form class="mega-inline">
                <div class="row">
                    <div class="col-sm-12" *ngFor="let tienda of tiendas">
                        <label class="card select-tienda" for="tienda{{tienda.id}}">
                            <div class="media p-20">
                                <div class="radio radio-primary me-3 ms-2">
                                    <input id="tienda{{tienda.id}}" type="radio" name="tienda[]" value="{{tienda.id}}"
                                        [(ngModel)]="tienda_selected" (change)="pickTienda()">
                                    <label for="tienda{{tienda.id}}"></label>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mega-title-badge">{{tienda.name}}<span
                                            class="badge badge-primary pull-right digits">{{tienda.cantidad_leads}}
                                            leads</span></h6>
                                    <p>{{tienda.provincia_id}}</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>