import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

const Swal = require('sweetalert2')
@Component({
  selector: 'app-modal-reasignarmasivo-lead',
  templateUrl: './modal-reasignarmasivo-lead.component.html',
  styleUrls: ['./modal-reasignarmasivo-lead.component.scss']
})
export class ModalReasignarmasivoLeadComponent implements OnInit {

  is_loading = false
  cantidad_leads = 0
  vendedores_disponibles = []

  data = {
    seller_id: 0
  }


  @Input() private leads_selected
  @Input() private tienda_seleccionada
  @Input() private jwt

  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) { }

  async ngOnInit() {
    this.cantidad_leads = this.leads_selected.length

    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('lead_id', this.leads_selected[0])
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_sellers_dispo_by_lead_id`, data))

    if (response.success) {
      this.vendedores_disponibles = response.sellers
    }
  }

  async onSubmit() {
    this.is_loading = true
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('seller_id', this.data.seller_id + '')
    data.append('leads_selected', this.leads_selected)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/update_lead_reasignar_masive`, data))
    this.is_loading = false
    if (response.success) {
      Swal.fire(response.message, '', 'success')
      this.activeModalService.close({
        'success': true
      });
    } else {
      Swal.fire(response.message, '', 'error')
    }


    // console.log(this.data)
    // console.log(this.leads_selected)

  }
  onCancel() {
    this.activeModalService.close({
      'success': false
    });
  }

}
