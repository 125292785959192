import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';
import { RegistrosService } from '../../../../src/app/registros.service';

const Swal = require('sweetalert2')
@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html',
  styleUrls: ['./registros.component.scss']
})
export class RegistrosComponent implements OnInit {

  registros
  cabeceras
  nombre_lead

  private nombre_form


  filtros
  buttonFiltrarTexto
  private routeSub: Subscription
  constructor(
    public registroService: RegistrosService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.filtros = []
    this.buttonFiltrarTexto = `Filtrar`
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        let url_array = event.url.split("/")
        if (Array.isArray(url_array)) {
          let nombre_form = url_array[url_array.length - 1]
          this.checkData(nombre_form)
        }
      }
    })
  }
  rerender(){
    console.log('filtros => ',this.filtros)
  }

  async ngOnInit() {
    await this.checkData('')
  }

  async checkData(nombre_form: string) {
    if (!nombre_form) {
      this.routeSub = this.route.params.subscribe(params => {
        this.nombre_form = params.nombre
      })
    }else{
      this.nombre_form = nombre_form
    }

  
    const result = <any>await this.registroService.get_registros(this.nombre_form)
    

    if (result) {
      this.registros = result.registros
      this.cabeceras = result.cabeceras
      this.nombre_lead = result.nombre_formulario
    }

    console.log('this.registros', this.registros)
    console.log('this.cabeceras', this.cabeceras)
  }
}
