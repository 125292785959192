import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from '../shared/services/template.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-template-add-modal',
  templateUrl: './template-add-modal.component.html',
  styleUrls: ['./template-add-modal.component.scss']
})
export class TemplateAddModalComponent implements OnInit {

  public htmlContent: string | null = null;
  mergeVariables: string[] = [];
  is_loading = false


  template = {
    name: '',
    es_dinamica: false,
    html: ''
  }


  constructor(
    private templateService: TemplateService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const htmlContent = fileReader.result as string;
      this.htmlContent = htmlContent; // Almacenar el contenido HTML sin procesar
      this.mergeVariables = this.extractMergeVariables(htmlContent); // Extraer y almacenar las variables

      if (this.mergeVariables.length > 0) {
        this.template.es_dinamica = true
      } else {
        this.template.es_dinamica = false
      }

    };
    fileReader.readAsText(file);
  }


  async onSubmit() {
    this.template.html = this.htmlContent
    const res = <any>await this.templateService.insertTemplate(
      this.template
    )

    if (res.success) {
      Swal.fire(res.message, '', 'success')
      this.activeModal.close({
        success: true
      })
    } else {
      Swal.fire(res.message, '', 'error')
    }
  }

  onCancel() {
    this.activeModal.dismiss('cancel'); // Rechaza la promesa con un motivo de cancelación
  }

  extractMergeVariables(htmlContent: string): string[] {
    const mergeRegex = /#MERGE_\w+/g;
    const matches = htmlContent.match(mergeRegex);
    return matches ? Array.from(new Set(matches)) : [];
  }

}
