<app-breadcrumb [title]="'Tipos de campaña'"
    [items]="['Campañas', 'Configuración', 'Tipos de campaña']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>En este módulo, se podrá configurar los tipos de envíos.</small>
                    </div>
                    <div class="media-body text-end">
                        <!-- <a [routerLink]="'add'" class="btn btn-primary me-2">Agregar tipo de envío +</a> -->
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Tipo de envío</th>
                                <th class="text-center" scope="col">Fecha de creación</th>
                                <th class="text-center" scope="col">Creado por</th>
                                <!-- <th class="text-center" scope="col">Ver</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of templates">
                                <td>{{t.name}}</td>
                                <td class="text-center">{{t.created_time}}</td>
                                <td class="text-center">
                                    <span class="badge badge-secondary">{{t.created_by}}</span>
                                </td>
                                <!-- <td class="text-center">
                                    <a [routerLink]="'detail/'+t.id"><i class="fa fa-arrow-right"></i></a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>