import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs'
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-leads-por-concesionario',
  templateUrl: './dashboard-leads-por-concesionario.component.html',
  styleUrls: ['./dashboard-leads-por-concesionario.component.scss']
})
export class DashboardLeadsPorConcesionarioComponent implements OnInit {

  @Input() data: any = []


  protected jwt
  private routeSub: Subscription;
  view: any[] = null
  rol: String

  // options
  showXAxis = true
  showYAxis = true
  gradient = false
  showLegend = true
  showXAxisLabel = true
  xAxisLabel = ''
  showYAxisLabel = true
  yAxisLabel = 'Concesionarios'

  colorScheme = {
    domain: ['#f26171', '#f04255', '#ff102a', '#aa0013', '#67020d']
    // domain : ['#ff102a','#000000','#626e7a']
  };

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute,) {
    this.jwt = JSON.parse(localStorage.getItem('user')).jwt
  }
  // async checkIfIsAsesor() {
  //   if (JSON.parse(localStorage.user).user_type_id == 2) {
  //     if (localStorage.tienda_selected === undefined) {

  //       this.router.navigate(['/asesor-select-tienda']);
  //       return true
  //     }


  //   } else if (JSON.parse(localStorage.user).user_type_id == 3) {
  //     if (localStorage.tienda_selected === undefined) {
  //       this.router.navigate(['/jefe-select-tienda']);
  //       return true
  //     }
  //   }
  // }
  ngOnInit(): void {
    if(this.data === undefined){
      this.data = []
    }
  }
  // async ngOnInit() {
  //   const is_asesor = await this.checkIfIsAsesor()
  //   if (!is_asesor) {
  //     this.routeSub = this.route.params.subscribe(params => {
  //       this.rol = params.rol
  //     })
  //     if (this.rol == 'default') {
  //       this.get_data()
  //     } else {
  //       const data = new FormData()
  //       data.append('jwt', this.jwt + "")

  //       const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_dashboards`, data))
  //       if (response.success) {
  //         console.log(response)
  //         this.single = response.data.leads_por_concesionario
  //       }
  //     }
  //   }
  // }
  // async get_data() {
  //   const data = new FormData()
  //   data.append('jwt', this.jwt + "")

  //   const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_data_for_dashboard`, data))
  //   if (response.success) {
  //     this.router.navigate([response.ruta]);
  //   }
  // }

  onSelect(event) {
    console.log(event);
  }

  receivedData: any;

  receiveData(data: any) {
    this.receivedData = data;
  }
  showData() {
    // console.log(this.data)
  }
}
