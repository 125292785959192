import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})


export class EncuestaService {

    constructor(private http: HttpClient) { }


    async get_tipos_preguntas() {
        const data = new FormData()
        data.append('data', JSON.stringify(JSON.parse(localStorage.getItem("user")).jwt))
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/get_tipos_preguntas`, data))
        return response
    }

    async insertar_preview() {
        const data = new FormData()
        data.append('data', JSON.stringify(JSON.parse(localStorage.getItem("user")).jwt))
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/insert_preview_encuesta`, data))
        return response
    }

    async update_preview(encuesta: any) {
        const data = new FormData()
        encuesta.jwt = JSON.parse(localStorage.getItem("user")).jwt
        data.append('data', JSON.stringify(encuesta))
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/update_preview_encuesta`, data))
        return response
    }

    async insertar(encuesta: any) {
        const data = new FormData()
        encuesta.jwt = JSON.parse(localStorage.getItem("user")).jwt
        data.append('data', JSON.stringify(encuesta))
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/insert_encuesta`, data))
        return response
    }

    async get_encuestas() {
        const data = new FormData()

        data.append('data', JSON.stringify(JSON.parse(localStorage.getItem("user")).jwt))
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/get_encuestas`, data))
        return response
    }

    async get_encuestas_active() {
        const data = new FormData()

        data.append('data', JSON.stringify(JSON.parse(localStorage.getItem("user")).jwt))
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/get_encuestas_active`, data))
        return response
    }

    async get_encuestas_by_filtro(filtro: any) {
        const data = new FormData()
        filtro.jwt = JSON.parse(localStorage.getItem("user")).jwt
        data.append('data', JSON.stringify(filtro))

        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/get_encuestas_by_filtro`, data))
        return response
    }

    async eliminar_encuesta( encuesta_id: any) {
        const data = new FormData()
        data.append('encuesta_id', encuesta_id)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/deshabilitarEncuesta`, data))
        return response
    }

    async get_subtype_encuesta(type_id: any) {
        const data = new FormData()
        data.append('type', type_id)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/recuperarEncuesta`, data))
        return response
    }

    async recuperar_encuesta( encuesta_id: any) {
        const data = new FormData()
        data.append('encuesta_id', encuesta_id)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/recuperarEncuesta`, data))
        return response
    }

    async init() {
        const data = new FormData()
        data.append('data', JSON.stringify(JSON.parse(localStorage.getItem("user")).jwt))
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/init_encuesta`, data))
        return response
    }

    async insertar_subtipoencuesta(name, type_encuesta) {
        const data = new FormData()
        data.append('name', name)
        data.append('type_encuesta', type_encuesta)

        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/insertar_subtipoencuesta`, data))
        return response
    }


    async insert_preguntas_personales(preguntas_personales: any, encuesta_id: any) {
        const data = new FormData()
        data.append('preguntas_personales', preguntas_personales)
        data.append('encuesta_id', encuesta_id)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}EncuestaAPI/insertar_preguntas_personales`, data))
        return response
    }

}
