import { Input, Component, OnInit, ElementRef, HostListener, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-cantidad-lead-por-status',
  templateUrl: './dashboard-cantidad-lead-por-status.component.html',
  styleUrls: ['./dashboard-cantidad-lead-por-status.component.scss']
})
export class DashboardCantidadLeadPorStatusComponent implements OnInit, AfterViewInit {

  @Input() data: any[];
  @Input() designatedTotal: any

  width: number = 500
  height: number = 400
  view: any[]

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;

  colorScheme = {
    domain: ["#007bff", "#ff9f40", "#ff5370", "#1ea6ec"]
  };

  constructor(private elementRef: ElementRef) {


  }
  ngOnInit(): void {
    this.setWidthAndHeight()
  }

  setWidthAndHeight(width = null) {
    if (this.width != width) {
      this.width = width
    }
    if (this.width < 768) {
      this.height = 800
    } else {
      this.height = 400
    }
    this.view = [this.width, this.height]
  }

  onSelect(event) {
    console.log(event);
  }

  ngAfterViewInit() {
    const nativeElement = this.elementRef.nativeElement
    const width = nativeElement.offsetWidth;
    this.setWidthAndHeight(width)
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = this.elementRef.nativeElement.offsetWidth;
    this.setWidthAndHeight(width)
  }

}
